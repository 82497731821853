@import "variables";

.all-estimates {
    position: relative;
    padding-top: 98px;
    background-color: rgba(8, 77, 79, 0.05);

    .MuiPaginationItem-page.Mui-selected {
        background-color: $primary-teal !important;
        color: $white;
    }

    &:before {
        content: '';
        position: absolute;
        background-image: url("../../assets/images/background-new.svg");
        background-size: contain;
        background-repeat: no-repeat;
        bottom: 210px;
        right: -85%;
        height: 100%;
        max-height: 280px;
        width: 100%;
        z-index: -1;
    }

    &:after {
        content: '';
        position: absolute;
        background-image: url('../../assets/images/background-new-small.svg');
        background-size: contain;
        background-repeat: no-repeat;
        bottom: 110px;
        right: -85%;
        height: 100%;
        max-height: 100px;
        width: 100%;
        z-index: -1;
    }

    .container {
        @media only screen and (min-width: 1501px) {
            max-width: 100% !important;
        }
    }

    .filters {
        margin-bottom: 13px;

        .input-field.col {
            width: 300px;
            margin-top: 16px;

            .cross {
                position: absolute;
                right: 15px;
                top: 12px;
                cursor: pointer;
            }
        }
    }

    .outer-box {
        .item-intro {
            .info {
                .MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot {
                    padding-right: 0;
                }

                .MuiPaper-root {
                    box-shadow: none;
                    border: none;
                    justify-content: left;
                    margin-bottom: 0;

                    .MuiAutocomplete-root {
                        .MuiAutocomplete-endAdornment {
                            display: none;
                        }

                        input {
                            box-shadow: none;
                            margin: 0;
                            height: 14px !important;
                            min-height: auto;
                            line-height: 1;
                            font-size: 12px !important;
                            text-align: center;
                            border-radius: 30px;
                            padding: 5px 10px !important;
                            background-color: rgba(8, 77, 79, .12);
                            border: 1px solid rgba(8, 77, 79, .15) !important;
                            width: auto;
                            max-width: 50px;
                            color: #000;
                            background: transparent;
                        }

                        .MuiTextField-root {
                            max-width: 100%;
                            width: 100%;
                        }
                    }

                    li {
                        .MuiChip-root {
                            max-height: 25px;
                            background-color: rgba(8, 77, 79, .10196078431372549);
                            margin-right: 5px;
                            color: #000;

                            svg {
                                fill: rgba(8, 77, 79, .22745098039215686);
                                height: 18px;

                                &:hover {
                                    fill: currentColor;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .arrow_outer {
        line-height: 1;
    }

    .filters {
        .arrow_outer {
            line-height: 1;
            transform: rotate(0deg);
            transition: 0.3s transform ease-in-out;
        }

        .list_open_new {
            .arrow_outer {
                transform: rotate(-180deg);
            }
        }

        .list_open {
            .arrow_outer {
                transform: rotate(-180deg);
            }
        }
    }

    .section-intro {
        //padding-top: 25px;
        padding-top: 0;

        &:after {
            content: "";
            display: block;
            clear: both;
        }

        h5 {
            float: left;
            font-weight: 100;
            margin-bottom: 4px;
        }

        button {
            float: right;
            border-radius: 5px;
            color: $white;
            font-weight: 500;
            // font-weight: 600;
            background-color: $primary-pink;


            .icon-outer {
                line-height: 1;
                display: inline-block;
                margin-right: 5px;
            }
        }
    }

    .filters {
        .input-field {
            margin-bottom: 0;

            &.active {
                ul {
                    display: block;
                }

                span.right-arrow {
                    display: none;
                }
            }

            span.right-arrow {
                position: absolute;
                right: 20px;
                top: 10px;
            }
        }

        .icon {
            color: #000;

            svg {
                height: 28px;
                width: 30px;
                margin-top: -3px;
            }
        }

        button {
            border: 1px solid lightgray;
            margin-top: 15px;
        }
    }

    // ul {
    //   background: $white;
    //   border-radius: 5px;
    //   padding: 8px 10px 0 !important;
    //   position: absolute;
    //   width: calc(100% - 22px);
    //   top: 41px;
    //   border-top: 0;
    //   border: 1px solid lightgray;
    //   border-top: 0;
    //   border-top-left-radius: 0;
    //   border-top-right-radius: 0;
    //   box-shadow: 0 2px 3px rgba(0, 0, 0, 0.2);
    //   span {
    //     font-size: 12px;
    //     font-weight: 600;
    //   }
    //   li {
    //     cursor: pointer;
    //     margin: 7px 0;
    //   }
    // }
    .select-box {
        div[x-placement="bottom"] {
            left: -78px !important;
            z-index: 9;
            min-width: 191px;
        }

        #menu-list-summary {
            li {
                svg {
                    margin-right: 10px;
                }

                img {
                    display: block;
                    margin-right: 10px;

                    &.template_pink {
                        display: none;
                    }
                }

                &:hover {
                    img {
                        display: none;

                        &.template_pink {
                            display: block;
                        }
                    }
                }
            }
        }
    }

    #tags {
        border: 0;
        box-shadow: none;
        border-radius: 50px;
        background: $white;
        padding: 5px 39px 6px 56px;
        box-sizing: border-box;
        font-size: 18px;
        font-weight: 100;
        //color: #0000007d;
        color: rgba(0, 0, 0, 1);
        font-weight: 300;
        width: 300px;

        // &:focus {
        //   box-shadow: 0 2px 3px rgba(0, 0, 0, 0.2);
        //   background: $white;
        // }
        &::-webkit-input-placeholder {
            /* Edge */
            color: rgba(0, 0, 0, 0.4);
            font-weight: 400;
            font-size: 18px;
        }
    }

    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus {
        -webkit-box-shadow: 0 0 0px 1000px $white inset !important;
    }

    #selectBox {
        border: 1px solid lightgray;
        border-radius: 5px;
        padding: 8px 40px 10px 12px;
        // box-shadow: 0 2px 3px rgba(0, 0, 0, 0.2);
        margin-top: 1rem;
        margin-bottom: 1rem;
        height: 38px;
        box-sizing: border-box;
        font-size: 14px;
        min-width: 130px;

        &:focus {
            background-color: transparent;
        }

        svg {
            color: $black;
            right: 10px;
        }
    }

    .MuiPaper-root {
        @include flexbox;
        flex-wrap: wrap;
        align-items: flex-end;
        align-items: center;
        justify-content: space-between;
        border: solid 1px rgba(8, 77, 79, 0.1);
        margin: 0 0 10px 0;
    }

    .item-intro {
        margin-right: auto;

        h5 {
            margin: 5px 0 3px;
            color: #000;
            // font-weight: 500;
            font-weight: 300;

            svg {
                margin-right: 10px;
                // transform: translateY(4px);
            }

        }

        h6 {
            color: $primary-teal;
            font-size: 12px;
            // font-weight: bold;
            font-weight: 500;
        }
    }

    .item-option {
        button {
            padding: 5px 8px;

            svg {
                height: 25px;
                width: 25px;
            }

            &:hover {
                svg {
                    color: $primary-pink;
                }
            }
        }

        position: relative;

        h6 {
            display: inline-block;
            vertical-align: middle;
            font-weight: 300;
        }

        .featured-icon {
            display: inline-block;
            vertical-align: middle;
            margin-left: 24px;

            .featuredIcon {
                display: none;
            }

            .unfeaturedIcon {
                display: block;
            }

            .featured {
                .featuredIcon {
                    display: block;
                }

                .unfeaturedIcon {
                    display: none;
                }
            }
        }

        button {
            display: inline-block;
            vertical-align: middle;
            background: transparent;
        }

        &.activeOption {
            .optionsList {
                display: block;
            }
        }

        .text-wrapper {
            display: inline-block;
            vertical-align: middle;

            span {
                display: block;
                text-align: right;
                color: #000000;
            }

            h6 {
                color: #000000;
            }
        }

        .MuiTouchRipple-root {
            color: transparent;
        }
    }

    .info {
        h6 {
            display: inline-block;
            position: relative;
            margin-right: 20px;
            background-color: rgba(8, 65, 79, 0.13);
            border-radius: 50px;
            padding: 3px 6px 4px 11px;
            margin-top: 7px;
            color: #000;
            font-weight: 100;
        }

        span {
            &.material-icons {
                font-size: 14px;
                background-color: rgba(8, 65, 79, 0.2);
                border-radius: 50%;
                vertical-align: middle;
                margin-left: 6px;
            }
        }
    }

    .optionsList {
        display: none;
        position: absolute;
        top: 50px;
        right: 0;
        width: 250px;
        background-color: $white;
        z-index: 9;
        box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14);

        .MuiListItemIcon-root {
            min-width: 35px;
        }
    }

    .sortPopup {
        display: none;
        position: absolute;
        top: 60px;
        right: 0;
        width: 150px;
        background-color: $white;
        z-index: 9;
        box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14);

        .MuiListItemIcon-root {
            min-width: 35px;
        }
    }

    .activeSort {
        .sortPopup {
            display: block;
        }
    }
}

.pink_text {
    &:hover {
        .text_outer {
            color: $primary-pink !important;
        }
    }
}


.pagination-style {
    nav {
        background-color: transparent;
        box-shadow: none;

        ul {
            justify-content: center;
        }
    }

    .MuiPaginationItem-page.Mui-selected {
        background-color: $primary-teal !important;
        color: $white;

        &.Mui-focusVisible {
            background-color: $primary-teal;
        }

        &:hover {
            background-color: $primary-teal !important;
        }
    }
}

.tempshowButton {
    .filter-temp-est {
        .option-outer {
            display: none;
        }

        .buttonOuter {
            display: block;

            button {
                padding: 5px 8px;
                background-color: $primary-teal !important;
            }
        }
    }
}

.pagination {
    li {
        height: auto;
    }
}

.filter-temp-est {
    .buttonOuter {
        text-align: right;
        display: none;

        button {
            padding: 10px 20px;
            background-color: $primary-teal !important;
        }
    }

    .item-intro {
        h5 {
            display: flex;
            align-items: center;
            margin-top: 7px;
        }

        .filter-list-name {
            font-weight: 500;
            margin-left: 10px;
            color: rgba(0, 0, 0, 0.87);
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
        }

        &.errorState {
            input {
                border-bottom: solid 1px #db263b !important;
            }

            .MuiFormLabel-filled {
                opacity: 1 !important;
                left: 11px;
                color: #db263b !important;
            }
        }

        width: calc(100% - 317px);
        margin-right: 29px;

        .MuiTextField-root {
            max-width: 579px;
            width: 70%;
        }

        .MuiInput-underline:hover:not(.Mui-disabled):before {
            border-bottom: 0px !important;
            box-shadow: none !important;
        }

        input {
            border-bottom: solid 1px transparent !important;
            font-size: 25px !important;
            box-shadow: none !important;
            height: 2.4rem !important;
            font-weight: 500;
            margin-bottom: 0 !important;
            cursor: pointer;
            text-overflow: ellipsis;

            &:hover {
                text-overflow: inherit;
            }
        }

        .MuiInputBase-root {
            margin-top: 1px;
            margin-left: 10px;
        }

        .MuiInput-underline:after {
            opacity: 0 !important;
        }

        .MuiInput-underline:before {
            opacity: 0 !important;
        }

        .MuiInputLabel-root {
            left: 11px;
            opacity: 1;
            font-size: 25px;
            top: -10px;
            font-weight: 500;
            color: rgba(0, 0, 0, 0.87);
        }

        .MuiInputLabel-root.MuiFormLabel-filled {
            opacity: 0 !important;
            color: $primary-teal;
            top: -9px !important;
            font-size: 1rem;
            font-weight: 400;
        }

        .MuiInputLabel-root.Mui-focused {
            // opacity: 0;
            color: $primary-teal;
            top: -9px !important;
            font-size: 1rem;
            font-weight: 400;
        }

        .MuiInputLabel-root.MuiFormLabel-filled.Mui-focused {
            opacity: 1;
        }
    }

    .archive {

        background-color: rgba(8, 77, 79, 0.13);

        .info {
            ul {
                background-color: transparent;
            }
        }

        .select-menu-wrap {
            ul {
                width: 100%;
            }
        }

        #menu-list-summary {
            li {
                &.deactivate {
                    display: none;
                }

                &.edit {
                    display: none;
                }

                &.revoke {
                    display: none !important;
                }

                &.admin {
                    display: none;
                }

                &.reactivate {
                    display: block !important;
                }
            }
        }

        h4 {
            svg {
                opacity: 0.45;
            }
        }

        .box-title {
            span {
                opacity: 0.65;
            }
        }

        span {
            opacity: 0.65;
        }

        button {
            span {
                opacity: 1;
            }
        }
    }
}