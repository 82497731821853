.company-settings {
    .title {
        .MuiInputBase-root {
            &:hover {
                ~label {
                    display: block;
                }

                ~label.MuiFormLabel-filled {
                    display: block;
                }

                legend {
                    max-width: 0;
                }
            }

            &.Mui-focused {
                fieldset {
                    border: 1px solid rgba(8, 77, 79, 0.1) !important;
                }

                legend {
                    max-width: 100%;
                }
            }
        }

        label {
            font-size: 25px;
            line-height: 0.45;
            color: #000;
            font-weight: 100;

            &.MuiFormLabel-filled {
                display: none;
            }

            &.Mui-focused {
                display: block;
                font-size: 15px;
                line-height: 1;
            }
        }

        input {
            font-size: 25px !important;
            font-weight: 100;
        }

        fieldset {
            border: 0 !important;
        }
    }

    .default-wrap {
        .default-block {
            display: flex;
            flex-wrap: wrap;
            align-items: stretch;

            .MuiGrid-root {
                display: flex;
                flex-direction: column;

                #hourly-cost,
                #hourly-rate,
                #vendor-upsell {
                    flex: 1;
                }

                &:last-child {
                    padding-right: 20px;
                }

                p {
                    flex: 1 0 auto;
                }

                // .MuiFormControl-root.MuiTextField-root{
                //     flex: 1 0 auto;
                // }
            }
        }
    }

    .calculation-wrap {
        .drop-wrap {
            .stat-block {
                &:first-child {
                    padding: 0 40px 20px 0;
                }

                &:nth-child(2n) {
                    padding: 0 28px 20px 15px;
                }

                &:nth-child(3n + 1) {
                    padding: 0 40px 20px 0;
                }

                &:nth-child(3n - 1) {
                    padding: 0 28px 20px 15px;
                }

                &:nth-child(3n) {
                    padding: 0 20px 20px 35px;
                }
            }
        }
    }
}