@import "variables";

.user-management {
    .MuiAlert-root {
        top: 15px;
        max-width: 760px;
        margin: 0 auto;
        right: 0;
        left: 80px;
        width: 100%;
        position: absolute;
        z-index: 9999;
        background-color: #3066BE;
    }

    .filter-menu {
        label.disabled {
            opacity: 0.5;
            cursor: default;
            pointer-events: none;
            margin-right: 30px;

            .MuiCheckbox-root {
                svg {
                    path {
                        color: rgba(8, 77, 79, 0.5);
                    }
                }
            }

            .MuiIconButton-label {
                &::after {
                    content: '';
                    background: #fff;
                    position: absolute;
                    top: 14px;
                    left: 14px;
                    height: 13px;
                    width: 13px;
                }
            }
        }

        label.enabled {
            margin-right: 30px;

            .MuiCheckbox-root {
                padding: 9px 7px;

                svg {
                    path {
                        color: rgba(8, 77, 79, 0.3);
                    }
                }

                &.Mui-checked {
                    svg {
                        path {
                            color: rgba(8, 77, 79, 1);
                        }
                    }

                    .MuiIconButton-label {
                        &::after {
                            opacity: 0;
                        }
                    }
                }

                .MuiIconButton-label {
                    &::after {
                        content: '';
                        background: #fff;
                        position: absolute;
                        top: 14px;
                        left: 12px;
                        height: 12px;
                        width: 13px;
                    }
                }
            }
        }
    }

    .panel-list {
        .MuiPaper-root {
            border: 1px solid rgba(8, 77, 79, 0.1);

            .MuiBox-root {
                border: 0;

                //   padding: 20px 1px 20px 28px;
                small {
                    color: $primary-teal;
                }

                &.archiveShow {
                    border: solid 1px rgba(8, 77, 79, 0.05);
                    background-color: rgba(8, 77, 79, 0.13);

                    #menu-list-summary {
                        li {
                            &.deactivate {
                                display: none;
                            }

                            &.edit {
                                display: none;
                            }

                            &.revoke {
                                display: none !important;
                            }

                            &.admin {
                                display: none;
                            }

                            &.reactivate {
                                display: block !important;
                            }
                        }
                    }

                    h4 {
                        svg {
                            opacity: 0.45;
                        }
                    }

                    .box-title {
                        span {
                            opacity: 0.65;
                        }
                    }

                    span {
                        opacity: 0.65;
                    }

                    button {
                        span {
                            opacity: 1;
                        }
                    }
                }

                #menu-list-summary {
                    li {
                        svg {
                            vertical-align: middle;
                        }

                        &.revoke {
                            display: none;
                        }

                        &.reactivate {
                            display: none;
                        }

                        &.remove {
                            display: none;
                        }

                        &.resend {
                            display: none;
                        }
                    }
                }

                &.admin {
                    #menu-list-summary {
                        li {
                            &.admin {
                                display: none;
                            }

                            &.revoke {
                                display: block;
                            }
                        }
                    }
                }

                &.unconfirmed {
                    #menu-list-summary {
                        li {
                            &.edit {
                                display: none;
                            }

                            &.admin {
                                display: none;
                            }

                            &.deactivate {
                                display: none;
                            }

                            &.revoke {
                                display: none;
                            }

                            &.reactivate {
                                display: none;
                            }

                            &.remove {
                                display: block;
                            }

                            &.resend {
                                display: block;
                            }
                        }
                    }

                }

                button {
                    background-color: transparent;

                    .MuiTouchRipple-root {
                        color: transparent;
                    }

                    &:hover {
                        svg {
                            fill: $primary-pink;
                        }
                    }
                }

                h4 {
                    .box-title {
                        span {
                            //font-weight: 600;
                            font-weight: 500;
                        }
                    }
                }
            }
        }
    }
}