.gantt-container {
	.tooltip {
		&::after {
			content: "";
			position: absolute;
			opacity: 0;
			visibility: hidden;
			display: flex;
			justify-content: center;
			align-items: center;
			top: -37px;
			padding-left: 10px;
			padding-right: 10px;
			border-radius: 5px;
			height: 32px;
			font-family: NotoSans;
			font-size: 12px;
			color: white;
			background-color: #000000;
			transition: all 0.1s ;
			transform: scale(0.7);
			z-index: 95000;
		}

		&:hover {
			&::after {
				visibility: visible;
				opacity: 1;
				transform: scale(1);
				transition-delay: none;
			}
		}
	}
}

