@import "variables";

.dashboard {
    background-color: rgba(8, 77, 79, 0.05);
    //padding-left: 20px;
    margin-top: 20px;
    overflow: visible;

    //height: calc(100vh - 81px);
    .MuiAlert-icon {
        margin-right: 23px;
    }

    &:after {
        content: '';
        background: url(../../assets/images/dashboard-bg.png);
        position: absolute;
        background-repeat: no-repeat;
        background-size: cover;
        height: calc(100% - 145px);
        width: 100%;
        bottom: 0;
        left: 0;
        z-index: -1;
    }

    .MuiAlert-filledSuccess {
        display: none;
    }

    .apexcharts-toolbar {
        display: none;
    }

    .container {
        margin: 0;

        @media screen and (min-width: 1501px) {
            max-width: 100% !important;
            margin: 0 auto;
        }
    }

    .wrapper {
        .symbol-title-wrap {
            display: inline-block;
            vertical-align: middle;
            padding-left: 20px;
            max-width: 480px;
            font-size: 20px;
            letter-spacing: -0.012em;
            font-weight: 500;
            line-height: 1.23;

            span {
                font-size: 14px;
                letter-spacing: 0em;
                font-weight: 300;
                display: block;
                margin-top: 3px;
            }
        }
    }

    .inner-wrap {
        margin-bottom: 30px;

        .inner-item {
            @media screen and (max-width: 1200px) {
                flex-basis: auto;
            }

            .number-wrap {
                @media screen and (max-width: 767px) {
                    flex-basis: 100%;
                    max-width: 100%;
                    flex-grow: 100%;
                    margin: 20px 0 0;
                }

                .MuiPaper-root {
                    @media screen and (max-width: 767px) {
                        margin: 0 !important;
                    }
                }
            }
        }
    }

    .number-wrap {
        img {
            width: 100%;
            object-fit: contain;
        }
    }

    .number-item-wrap {
        position: absolute;
        text-align: center;
        background: transparent;
        box-shadow: none;
        top: 50%;
        left: 0;
        right: 0;
        transform: translateY(-65%);

        img {
            height: 20px;
            width: 20px;
        }

        h2 {
            font-size: 80px;
            font-weight: bold;
            line-height: 1.1;
            margin: 6px 0;

            span {
                font-size: 80px;
                // font-weight: bold;
                font-weight: 500;
                line-height: 1.1;
            }
        }

        span {
            font-size: 14px;
            color: $black;
            // font-weight: 600;
            font-weight: 500;
        }
    }

    .calender-wrap-dashboard {
        position: absolute;
        bottom: 15px;
        box-shadow: none;
        max-width: 125px;
        margin: 0 auto;
        background: transparent;

        input {
            border: 0 !important;
            margin-bottom: 0 !important;

            &:focus {
                box-shadow: none !important;
                border: 0 !important;
            }
        }

        button {
            padding-right: 0;
            padding-left: 9px;
        }

        svg {
            fill: $black;
        }
    }

    .select-dash-wrap {
        box-shadow: none;

        label {
            margin-top: -10px;
            color: $black;

            &.MuiInputLabel-shrink {
                margin-top: 0;
                color: $black;
                opacity: 0;
            }
        }

        fieldset {
            legend {
                max-width: 0;
            }
        }

        .MuiInputBase-root {
            max-height: 35px;
            min-width: 125px;
        }

        .MuiSelect-select {
            font-size: 13px;

            &:focus {
                background: transparent;
            }
        }

        .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
            border-color: rgba(0, 0, 0, 0.23);
            border-width: 1px;
        }
    }

    .title-wrap {
        margin-bottom: 50px;
        display: flex;
        justify-content: space-between;
    }

    .estimates-wrap {
        div[role='tabpanel'] {
            border-bottom-left-radius: 5px;
            border-bottom-right-radius: 5px;
            min-height: 319px;

            // display: flex;
            // justify-content: center;
            // align-items: center;
            .listitem {
                padding: 18px 42px;
                box-shadow: none;
                display: flex;
                justify-content: space-between;
            }

            .MuiBox-root {
                padding: 0;
                width: 100%;
            }
        }

        div[aria-label='simple tabs'] {
            justify-content: space-evenly;
            text-align: center;
        }

        div[role='tabpanel'][hidden] {
            display: none;
        }

        header {
            background: $white;
            color: $black;
            border-top-left-radius: 5px;
            border-top-right-radius: 5px;

            button {
                opacity: 1;
                border-bottom: 2px solid transparent;

                &.Mui-selected {
                    .MuiTab-wrapper {
                        color: $primary-pink;
                    }

                    border-bottom: 2px solid $primary-pink;
                }

                &:hover {
                    .MuiTab-wrapper {
                        color: $primary-pink;
                    }
                }

                .MuiTab-wrapper {
                    color: $black;
                    font-weight: 400;
                    text-transform: capitalize;
                }
            }

            .MuiTabs-indicator {
                background-color: transparent;
            }
        }

        .listitem {
            .info {
                h6 {
                    font-size: 14px;
                    font-weight: 300;
                    line-height: 1.45;
                    -webkit-line-clamp: 3;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                }
            }

            &:hover {
                cursor: pointer;
                background-color: rgba(8, 77, 79, 0.05);

                svg {
                    fill: $primary-pink;
                }

                .info {
                    img {
                        &:first-child {
                            display: none;
                        }

                        &:last-child {
                            display: inline-block;
                        }
                    }
                }
            }

            svg {
                &.featuredIcon {
                    display: none;
                }
            }

            &.featured,
            &.not-featured {
                svg {
                    display: none;

                    &.featuredIcon {
                        display: inline-block;
                    }
                }
            }

            .info {
                img {
                    margin: 0 10px 0 0;
                    display: inline-block;
                    vertical-align: bottom;
                    object-fit: contain;

                    &:last-child {
                        display: none;
                    }
                }
            }

            &:last-child {
                border-bottom-left-radius: 5px;
                border-bottom-right-radius: 5px;
            }
        }
    }

    .testimonial-wrapper {
        @media screen and (max-width: 767px) {
            flex-grow: 1;
            max-width: 100%;
            flex-basis: 100%;
        }
    }

    .graph-wrapper {
        @media screen and (max-width: 767px) {
            flex-grow: 1;
            max-width: 100%;
            flex-basis: 100%;
        }
    }

    .graph {
        background: $white;
        padding: 18px 0 5px;
        border-radius: 5px;
        min-height: 410px;
        position: relative;
        overflow: hidden;

        @media screen and (min-width: 1601px) {
            min-height: 420px;
        }

        .apexcharts-title-text {
            color: $primary-teal;
            fill: $primary-teal;
            font-size: 12px;
            font-weight: 700;
            text-transform: uppercase;
            padding-left: 14px;
            letter-spacing: .6px;
        }

        .calender-wrap-graph {
            background: transparent;
            max-width: 125px;
            margin: 0 auto;
            box-shadow: none;
            position: absolute;
            right: 45px;
            top: -11px;
            z-index: 99;

            input {
                border: 0 !important;
                margin-bottom: 0 !important;

                &:focus {
                    box-shadow: none !important;
                    border: 0 !important;
                }
            }

            button {
                padding-right: 0;
                padding-left: 9px;
            }

            svg {
                fill: $black;
            }
        }

        #chart {
            padding: 0 15px;

            svg {
                text#SvgjsText1005 {
                    font-size: 12px;
                    // font-weight: bold;
                    font-weight: 500;
                    line-height: 1.42;
                    letter-spacing: 0.6px;
                    text-align: left;
                    fill: $primary-teal;
                    text-transform: uppercase;
                }
            }
        }
    }
}

.MuiPickersBasePicker-pickerView {
    .MuiPickersCalendar-week {
        button.MuiPickersDay-daySelected {
            background-color: $secondary-teal !important;
        }
    }
}