.addingNewTaskButton-container {
	display: flex;
	position: absolute;
	align-items: center;
	width: 30px;
	height: 30px;
	margin-left: 5px;

	span {
		width: 30px;
		height: 30px;
		border-radius: 50%;
		background-size: 55%;
		background-repeat: no-repeat;
		background-position: center;
		background-position-x: 7px;
		cursor: pointer;
		box-shadow: 0px 3px 5px -1px #bdb4b4be, 0px 2px 7px -1px #726969;
	}

	.opening-button {
		position: absolute;
		background-image: url("../../img/icons/btn_add_project.svg");
		background-position: center;
		background-repeat: no-repeat;
		background-size: cover;
		box-shadow: none;
		// z-index: 998;

		&.tooltip::after {
			content: "Add Resource";
			left: -30px;
		}
	}

	.role-resourceBtn,
	.vendor-resourceBtn {
		visibility: hidden;
		position: absolute;
		left: 0;
		background-color: #fff;
		opacity: 0;
		transition: all .2s ease-out;
	}

	.role-resourceBtn {
		background-image: url("../../img/icons/internal.png");

		&.tooltip::after {
			content: "Role";
			top: -37px;
			left: -8px;
		}
	}

	.vendor-resourceBtn {
		background-image: url("../../img/icons/external.png");

		&.tooltip::after {
			content: "Vendor";
			top: -37px;
			left: -15px;
		}
	}

	&.active {

		.role-resourceBtn,
		.vendor-resourceBtn {
			background-color: #fff;
			opacity: 1;
			visibility: visible;
		}

		.role-resourceBtn {
			left: 40px;
		}

		.vendor-resourceBtn {
			left: 80px;
		}
	}
}