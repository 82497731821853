@import "variables";

.summary-details {
    background-color: rgba(8, 77, 79, 0.05);

    select {
        display: block;
    }

    .card {
        padding: 20px 20px;
        box-shadow: none;
        transition: transform 0.4s ease, background-color 0.4s ease;

        .icon-box {
            cursor: grab;

            &.touched {
                cursor: -webkit-grabbing;
                cursor: grabbing;
            }
        }

        &.touched {
            background-color: #e6e6e6 !important;
            cursor: grabbing;
            z-index: -1;
        }
    }

    .tabs-cover {
        padding: 0;
    }

    .rdw-editor-wrapper {
        background: #fefbef;
        border: solid 1px rgba(8, 77, 79, 0.1);
        border-radius: 5px;
    }

    .rdw-editor-main {
        height: 480px;
        border-radius: 0 0 5px;
        border-top: 0;
        background-color: rgba(255, 227, 160, 0.003) !important;
        overflow-x: auto;
        margin: 20px 30px;

    }

    .rdw-editor-toolbar {
        border-radius: 5px 5px 0 0;
        border: solid 1px rgba(8, 77, 79, 0.1);
        padding: 6px 20px 0;
        margin-bottom: 0;
        border-top: 0;
        border-left: 0;
        border-right: 0;
    }

    .rdw-option-wrapper {
        border: 0;
        margin: 0;

        img {
            max-width: 15px;
            width: 100%;
            height: auto;
        }
    }

    .rdw-option-wrapper[title="Bold"],
    [title="Ordered"] {
        img {
            max-width: 12px;
        }
    }

    .rdw-option-wrapper:hover {
        border-radius: 5px;
        background-color: rgba(8, 77, 79, 0.1);
        box-shadow: none;
    }

    .rdw-option-active {
        box-shadow: none;
        border-radius: 5px;
        background-color: rgba(8, 77, 79, 0.1);
    }

    .rdw-link-decorator-wrapper {
        position: relative;
    }

    .rdw-link-decorator-icon {
        position: absolute;
        left: 0;
        top: 4px;
        height: 10px;
        width: 100%;
        opacity: 0;
    }

    .small-col {
        border: solid 1px rgba(8, 77, 79, 0.1);
        border-radius: 7px;
        font-size: 14px;
        line-height: 1.36;
        color: #000;
        background: $white;
        font-weight: 500;

        @media screen and (max-width: 1300px) {
            display: none;
        }

        .inner-title {
            padding: 20px 15px 15px;
            border-bottom: solid 1px rgba(8, 77, 79, 0.3);

            img {
                margin-right: 10px;
            }

            i {
                vertical-align: middle;
                padding: 5px 0;
            }
        }

        .outer-content {
            padding-bottom: 10px;
            padding-top: 12px;
        }

        .inner-content {
            padding: 2px 15px 0;

            @media screen and (max-width: 1220px) {
                padding: 5px 12px;
            }

            span {
                font-size: 14px;
                font-weight: 300;
                display: block;
                margin: 0;
                cursor: pointer;
                // word-break: break-all;

                svg {
                    float: right;
                    position: relative;
                }
            }

            .phase-text {
                margin: 0;
                display: flex;
                align-items: flex-start;
            }

            ul {
                max-height: 0px;
                overflow: hidden;
                transition: all 0.3s ease-in-out;

                li {
                    padding: 5px 0 0;

                    span {
                        font-size: 12px;
                        padding: 0 7px 0 4px;
                    }
                }
            }

            &.active {
                .arrow-icon {
                    transform: rotate(180deg);
                }

                ul {
                    max-height: 210px;
                    margin-left: 10px;
                    overflow-y: auto;

                    li {

                        span:before {
                            content: "-";
                            position: absolute;
                            left: 10%;
                        }
                    }

                }
            }
        }
    }

    .tabs {
        height: auto;
        padding-left: 10px;

        .tab {
            height: auto;
            text-transform: capitalize;
            text-align: left;

            a {
                display: flex;
                flex-direction: column;
                justify-content: center;
                padding: 0;

                i {
                    padding-top: 10px;
                }

                &:focus {
                    background-color: transparent !important;
                }
            }
        }

        a.active {
            color: #000000;
            background-color: transparent !important;
        }

        a {
            color: #000000;
            display: inline-block;
        }

        .indicator {
            background: #000000;
        }
    }

    h1 {
        text-align: center;
    }

    #scope {
        .container {
            @media screen and (min-width: 1300px) {
                padding: 0 10px;
            }
        }
    }

    .scope-tab {
        margin-top: 13px;

        label+.MuiInput-formControl {
            // margin-top: 8px;
        }

        .col-inner {
            &:hover {
                .icon-box {
                    opacity: 1;
                }

                .content-outer {
                    opacity: 1;
                }
            }
        }

        .scope-inner {
            display: flex;
            justify-content: space-between;

            .row {
                margin: 0;
            }

            .col {
                &.big-col {
                    flex: 0 0 80%;

                    .col-inner {
                        .input-field {
                            margin-left: 0;
                            padding: 0;

                            i.left-icon {
                                left: 13px;
                            }

                            input[type="text"]:focus~ul {
                                display: block;
                                z-index: 99;
                            }

                            input[type="text"]:focus~span {
                                i {
                                    transform: rotate(180deg);
                                }
                            }
                        }

                        .drag-handle {
                            position: absolute;
                            left: -28px;
                            top: 10px;
                            display: none;
                        }
                    }

                    .inner-wrap {
                        .input-field {
                            margin-left: 0;
                            padding: 0;

                            i.left-icon {
                                left: 13px;
                            }

                            input[type="text"]:focus~ul {
                                display: block;
                                z-index: 99;
                            }

                            input[type="text"]:focus~span {
                                i {
                                    transform: rotate(180deg);
                                }
                            }
                        }
                    }

                    .row {
                        margin: 0;
                    }
                }

                .input-field {
                    label {
                        font-size: 25px;
                        color: #000;
                        //font-weight: 600;
                        font-weight: 500;

                        &.MuiInputLabel-shrink {
                            font-size: 14px;
                            color: $primary-teal;
                        }

                        &.MuiFormLabel-filled.Mui-focused {
                            display: block;
                        }

                        &.MuiFormLabel-filled {
                            display: none;
                        }
                    }

                    .MuiFormControl-root {
                        max-width: 500px;

                        @media screen and (max-width: 1300px) {
                            max-width: 450px;
                        }
                    }

                    .MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot {
                        padding-right: 10px;
                    }

                    .MuiInputBase-root {
                        // padding-bottom: 10px;
                    }
                }
            }

            .row {
                &:hover {
                    .hover-menu {
                        opacity: 1;
                    }

                    .drag-handle {
                        display: block !important;
                    }
                }
            }

            .MuiButtonBase-root {
                text-transform: capitalize;
                // font-weight: bold;
            }

            &.openTask {
                .content-outer {
                    span.top-arrow {
                        transform: rotate(0);
                        top: -48px;
                    }
                }
            }
        }

        .icon-box {
            position: absolute;
            left: 20px;
            top: 43px;
            margin: 0;
            opacity: 0;
            transition: opacity 0.3s ease-in-out;

            @media screen and (max-width: 1300px) {
                left: 10px;
                top: 56px;
            }
        }

        .content-outer {
            position: absolute;
            right: 15px;
            z-index: 2;
            width: 1%;
            display: inline-block;
            vertical-align: top;
            margin-top: 35px;
            opacity: 0;
            cursor: pointer;

            @include desktop {
                right: 15px;
            }

            &.active,
            &:hover {
                opacity: 1;
                z-index: 3;
            }

            &.active {
                .open-close {
                    fill: #e70989;
                }
            }

            .open-close {
                &:hover {
                    fill: #e70989;
                }
            }

            &:hover {
                ul {
                    opacity: 1;
                    visibility: visible;
                }
            }

            li {
                padding: 8px 15px;
                font-size: 14px;

                &:hover {
                    background-color: rgba(8, 77, 79, 0.05);

                    svg {
                        fill: #e70989;
                    }

                    .clear_icon {
                        path {
                            stroke: #e70989 !important;

                        }
                    }
                }

                svg {
                    vertical-align: top;
                    height: 25px;
                    width: 25px;
                    margin-right: 8px;
                }
            }

            .img-box {
                margin: 0;
                vertical-align: top;
                line-height: 1;
            }

            ul {
                border-radius: 6px;
                box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
                background-color: $white;
                width: 200px;
                position: absolute;
                left: -160px;
                top: 40px;
                transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;
                border: solid 1px rgba(8, 77, 79, 0.1);
            }

            span.top-arrow {
                position: absolute;
                top: -41px;
                transform: rotate(180deg);

                &.active {
                    top: -50px;
                    transform: rotate(0deg);
                }
            }
        }

        .card {
            padding: 0;
            margin: 0 0 13px;
            border-radius: 5px;

            >div {
                padding: 5px 50px 10px;
                position: relative;

                @media screen and (max-width:1300px) {
                    padding: 20px 30px;
                }

                &:last-child {
                    border-bottom: 0;
                }

                form {
                    display: inline-block;
                    vertical-align: top;
                    width: 100%;
                }
            }

            .task-outer {
                padding: 0;
                border-top: 2px solid rgba(8, 77, 79, 0.2);

                &.collapse {
                    border-top: 2px solid transparent;
                }

                .input-field {
                    margin-top: 0;
                    margin-left: 0;
                    margin-right: auto;
                    padding: 0;

                    label {
                        font-size: 20px;
                        font-weight: 300;
                        top: -4px;
                    }

                    input[type="text"] {
                        font-size: 20px;
                        font-weight: 300;
                    }
                }

                .counting-phase {
                    .counting-phase-qty {
                        margin-right: -48px;

                        &:before {
                            position: absolute;
                            content: "";
                            color: rgba(8, 77, 79, 0.6);
                            background-image: url("../../assets/images/cross-green.svg");
                            top: 36px;
                            right: -32px;
                            font-weight: 500;
                            font-size: 20px;
                            height: 13px;
                            width: 20px;
                            background-repeat: no-repeat;
                            background-size: contain;
                            opacity: 0.6;
                        }

                        h4 {
                            &:before {
                                right: -76px;

                                @media screen and (max-width: 1320px) {
                                    right: -70px;
                                }
                            }
                        }

                        span {
                            text-align: center;
                        }

                        .gray-circle {
                            position: relative;
                            display: flex;
                            justify-content: center;
                            margin: 0 auto;
                            text-align: center;
                            background: rgba(8, 77, 79, 0.1);
                            height: 35px;
                            width: 35px;
                            border-radius: 50px;
                            min-width: auto;
                            transition: width 0.15s ease-in;

                            input {
                                border: 0;
                                line-height: 2.2;
                                position: relative;
                                text-align: center;
                                margin-bottom: 0;
                                font-size: 14px;

                                &::-webkit-inner-spin-button {
                                    appearance: none;
                                    margin: 0;
                                }

                                &:focus {
                                    box-shadow: none;
                                    border-bottom: 0;
                                }
                            }

                            svg {
                                font-size: 34px;
                                border: 1px solid rgba(8, 77, 79, 0.1);
                                border-radius: 50%;
                                padding: 5px;
                                position: absolute;
                                background: $white;
                                display: none;
                                top: 0;
                                transition: all 0.3s linear;
                                animation: scale-up-center 0.3s ease-in;

                                &.add {
                                    right: 0;
                                }

                                &.delete {
                                    left: 0;
                                }
                            }

                            &:focus,
                            &:hover {
                                width: 100px;

                                input {
                                    box-shadow: none;
                                    border-bottom: 0;
                                }

                                svg {
                                    display: block;

                                    &:hover {
                                        cursor: pointer;
                                    }

                                    transition: all 0.3s linear;
                                    animation: scale-up-center 0.3s ease-in;
                                }
                            }

                            &.task_qty {
                                min-width: auto;
                                margin-top: 1px;
                                z-index: 2;

                                &:before {
                                    right: -43px;
                                }
                            }
                        }
                    }

                    .counting-phase-total {
                        min-width: 111px;
                    }
                }

                .content-outer {
                    position: absolute;
                    right: 10px;
                    top: 10px;
                    left: auto;
                }

                span.top-arrow {
                    position: absolute;
                    top: -45px;
                }

                .icon-box {
                    top: 47px;
                    left: 20px;

                    @media screen and (max-width: 1300px) {
                        left: 10px;
                    }
                }

                .row {
                    position: relative;
                    padding: 15px 50px 5px;
                    // margin: 0 0 18px 0;

                    @media screen and (max-width: 1300px) {
                        padding: 20px 30px;
                    }

                    &:after {
                        content: "";
                        position: absolute;
                        bottom: 0;
                        left: 20px;
                        width: calc(100% - 40px);
                        height: 2px;
                        background-color: rgba(8, 77, 79, 0.2);

                        @include desktop {
                            left: 35px;
                            width: calc(100% - 70px);
                        }
                    }

                    .content-outer {
                        .top-arrow {
                            transform: rotate(180deg);
                            top: -45px;
                        }
                    }

                    &.expandResources {
                        .content-outer {
                            .top-arrow {
                                transform: rotate(0deg);
                                top: -41px;
                            }
                        }
                    }
                }

                .new_task_btn {
                    z-index: 20;
                    margin: 15px 0 5px 50px;
                    box-shadow: none;

                    @media screen and (max-width: 1300px) {
                        margin: 30px 0 20px 30px;
                    }

                    &:focus {
                        background-color: $primary-teal;
                        color: $white;
                    }

                    &:hover {
                        box-shadow: 0 2px 10px -2px rgba(0, 0, 0, 0.25);
                    }
                }

                .resource {
                    cursor: default;
                    padding: 0;

                    .excludeResource {

                        .list {
                            opacity: .3;
                            pointer-events: none;
                        }


                    }
                }

                .task-count {
                    cursor: default;

                    .input-field {
                        cursor: grab;

                        &:hover {
                            ~.icon-box {
                                opacity: 1;
                            }

                            ~.content-outer {
                                opacity: 1;
                            }
                        }
                    }

                    .counting-phase {
                        cursor: default;

                        @media screen and (max-width: 1340px) {
                            padding-right: 0;
                        }

                        &:hover {
                            ~.icon-box {
                                opacity: 1;
                            }

                            ~.content-outer {
                                opacity: 1;
                            }
                        }
                    }
                }

                .exclude {

                    .taskName,
                    .task_qty,
                    .taskSubtotal {
                        opacity: .3;
                        pointer-events: none;
                    }

                    .excludeTask {
                        display: flex;
                        justify-content: flex-start;

                        .img-box {
                            position: absolute;
                            top: 45%;
                            left: 0px;
                        }

                    }
                }
            }

            .input-field {
                span {
                    font-size: 12px;
                    font-weight: 500;
                    /// font-weight: bold;
                    color: $primary-teal;
                    text-transform: uppercase;
                    vertical-align: top;
                }
            }

            .phaseName {
                .excludePhase {
                    opacity: .3;
                    pointer-events: none;
                    display: flex;
                    justify-content: flex-start;

                    .img-box {
                        position: absolute;
                        top: 45%;
                        left: 0px;
                        margin-top: 0;
                    }


                }
            }

            .counting-phase {
                //padding-left: 95px;
                max-width: 715px;
                margin-left: auto;
                padding-right: 0;

                span {
                    font-size: 12px;
                    //font-weight: 500;
                    font-weight: bold;
                    color: $primary-teal;
                    text-transform: uppercase;
                    margin-bottom: 5px;
                    text-align: right;
                    // margin-right: 3px;
                }
            }

            .col-inner {
                .input-field {
                    font-size: 25px;
                    color: #000;
                    margin-top: 0;
                    margin-right: auto;
                    margin-left: 0;
                    padding: 0;

                    &.l4 {
                        .MuiAutocomplete-root {
                            margin-top: -13px;
                        }
                    }

                    &.phaseName {
                        label+.MuiInput-formControl {
                            margin-top: 0;
                        }
                    }
                }

                label {
                    font-size: 25px;
                    color: #000;

                    &.MuiInputLabel-shrink {
                        font-size: 14px;
                        color: $primary-teal;
                        display: none !important;
                    }

                    &.MuiFormLabel-filled.Mui-focused {
                        display: none;
                    }

                    &.MuiFormLabel-filled {
                        display: none !important;
                    }
                }

                .bottom-text {
                    h6 {
                        margin-top: 5px;
                    }
                }

                .content-outer {
                    z-index: 3;
                }
            }
        }

        .counting-phase {
            display: flex;
            flex-direction: row;
            justify-content: flex-end;

            div {
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                position: relative;
                text-align: right;
                //margin-left: 130px;
                margin-top: 0;
                min-width: 111px;
                max-width: 170px;

                @media screen and (max-width: 1220px) {
                    max-width: 155px;
                }

                &.input-inner {
                    min-width: 140px;

                    h6 {
                        text-align: center;
                    }
                }

                &.arrow-dropdown {
                    margin-left: 10px;
                    margin-top: 30px;

                    a {
                        color: lightgray;
                    }
                }

                &.hover-menu {
                    margin-left: 10px;
                    margin-top: 30px;
                    opacity: 0;

                    a {
                        position: relative;
                        cursor: default;
                    }

                    i {
                        color: lightgray;
                        cursor: pointer;
                        vertical-align: middle;
                        padding-right: 9px;
                    }

                    ul {
                        position: absolute;
                        width: 220px;
                        right: 0;
                        top: 25px;
                        background-color: $white !important;
                        padding: 15px !important;
                        box-shadow: -1px 3px 12px 0px rgba(0, 0, 0, 0.2);
                        display: none;
                        text-align: left !important;

                        li {
                            cursor: pointer;
                            color: $black;
                            margin: 5px 0;

                            &.top-border {
                                border-top: 2px solid lightgray;
                                padding-top: 10px;
                            }
                        }
                    }

                    a:hover ul {
                        display: block;
                        z-index: 9;
                    }
                }

                h4 {
                    &.gray-circle {
                        //  font-weight: 500;
                        font-weight: 300;
                        font-size: 14px;
                        background: rgba(8, 77, 79, 0.1);
                        border-radius: 50%;
                        text-align: center;
                        line-height: 2.4;
                        height: 35px;
                        width: 35px;
                        margin-top: 10px;
                        margin-left: auto;
                        margin-right: -5px;

                        &:before {
                            position: absolute;
                            content: "";
                            color: rgba(8, 77, 79, 0.6);
                            background-image: url("../../assets/images/cross-green.svg");
                            top: 53px;
                            right: -140px;
                            font-weight: 500;
                            //font-weight: bold;
                            font-size: 20px;
                            height: 15px;
                            width: 20px;
                            background-repeat: no-repeat;
                            background-size: contain;
                            opacity: 0.6;
                        }
                    }

                    &.subtotal {
                        margin-top: 4px;
                        font-size: 22px;
                        font-weight: 300;
                        margin-right: 3px;
                    }
                }

            }

            span {
                .quantity-input {
                    margin: 0;
                    height: 24px;
                    text-align: right;
                }
            }

            span {
                text-align: center;

                input:focus~i,
                input:hover~i {
                    display: block;
                }

                &:focus i,
                &:hover i {
                    display: block;
                }

                &:focus input,
                &:hover input {
                    box-shadow: none;
                    width: 123px;
                    border-radius: 86px;
                    height: 36px;
                    background: #a8a8a8;
                    border: lightgray;
                }

                input {
                    border: 1px solid lightgray;
                    border-radius: 50%;
                    height: 36px;
                    width: 36px;
                    outline: none;
                    text-align: center;

                    &:focus,
                    &:hover {
                        box-shadow: none;
                        width: 123px;
                        border-radius: 86px;
                        height: 36px;
                        background: #a8a8a8;
                        border: lightgray;
                    }

                    &::-webkit-inner-spin-button {
                        appearance: none;
                        margin: 0;
                    }
                }

                input[type="number"] {
                    -moz-appearance: textfield;
                }

                i {
                    display: none;

                    &.increment {
                        position: absolute;
                        right: 8px;
                        top: calc(50% - 11px);
                        font-size: 33px;
                        border: 1px solid lightgray;
                        border-radius: 50%;
                        background: white;
                    }

                    &.delete {
                        position: absolute;
                        left: 8px;
                        top: calc(50% - 11px);
                        font-size: 33px;
                        border: 1px solid lightgray;
                        border-radius: 50%;
                        background: white;
                    }
                }
            }
        }

        .scope-tab-table {
            th {
                text-align: right;
            }

            tr {
                &:not(:last-child) {
                    td {
                        width: 12%;
                        text-align: right;

                        &:first-child {
                            width: 35%;
                        }

                        input {
                            text-align: right;
                        }

                        input[type="text"] {
                            text-align: left;
                        }
                    }
                }

                &:last-child {
                    td {
                        .plus-inner-icon {
                            color: #000000;
                            font-size: 17px;
                            margin: 0 auto;
                        }
                    }
                }

                td {
                    padding: 8px 5px;

                    &.qty-title {
                        text-align: center;
                    }

                    &.quantity-td {
                        text-align: center;
                        position: relative;

                        input:hover~i.multiply,
                        input:focus~i.multiply {
                            display: none;
                        }

                        input:focus~i,
                        input:hover~i {
                            display: block;
                        }

                        &:focus,
                        &:hover {
                            i {
                                display: block;
                            }

                            input {
                                box-shadow: none;
                                width: 123px;
                                border-radius: 86px;
                                height: 33px;
                                background: #a8a8a8;
                            }
                        }

                        input {
                            border: 1px solid lightgray;
                            border-radius: 50%;
                            height: 36px;
                            width: 36px;
                            outline: none;
                            text-align: center;

                            &:focus,
                            &:hover {
                                box-shadow: none;
                                width: 123px;
                                border-radius: 86px;
                                height: 33px;
                                background: #a8a8a8;
                            }

                            &::-webkit-inner-spin-button {
                                appearance: none;
                                margin: 0;
                            }
                        }

                        input[type="number"] {
                            -moz-appearance: textfield;
                        }

                        i {
                            display: none;

                            &.increment {
                                position: absolute;
                                right: 17px;
                                top: calc(50% - 22px);
                                font-size: 32px;
                                border: 1px solid lightgray;
                                border-radius: 50%;
                                background: white;
                            }

                            &.delete {
                                position: absolute;
                                left: 17px;
                                top: calc(50% - 22px);
                                font-size: 32px;
                                border: 1px solid lightgray;
                                border-radius: 50%;
                                background: white;
                            }

                            &.multiply {
                                position: absolute;
                                display: block;
                                right: 0;
                                align-items: center;
                                top: 32%;
                                color: lightgray;
                            }
                        }
                    }

                    &.hour-td,
                    &.price-input {
                        input {
                            border: 1px solid lightgray;
                            width: 65%;
                            height: 40px;
                            padding: 2px 8px;
                            height: 40px;

                            &:focus {
                                box-shadow: none;
                                outline: none;
                            }

                            &::-webkit-inner-spin-button {
                                appearance: none;
                                margin: 0;
                            }
                        }
                    }
                }

                &.plus-inner-row {
                    height: 55px;
                    border: 0;
                    width: 100%;
                    padding: 10px 0;
                    position: relative;

                    .inner-add {
                        position: absolute;
                        left: 50%;
                        padding-top: 13px;

                        i {
                            color: lightgray;
                        }
                    }

                    &:after {
                        content: "";
                        position: absolute;
                        right: 22px;
                        bottom: 30px;
                        width: calc(50% - 55px);
                        height: 1px;
                        background-color: lightgray;
                    }

                    &:before {
                        content: "";
                        position: absolute;
                        left: 30px;
                        bottom: 30px;
                        width: calc(50% - 40px);
                        height: 1px;
                        background-color: lightgray;
                    }
                }

                &.title-row {
                    border-bottom: 0;
                    border-top: 1px solid rgba(0, 0, 0, 0.12);
                }

                &.calculation-wrap {
                    border: 0;

                    td {
                        text-align: left;

                        &:first-child {
                            width: 15%;
                        }

                        h6 {
                            padding-left: 60px;
                            display: inline-block;
                            vertical-align: middle;
                        }
                    }

                    &:not(:last-child) {
                        td {
                            width: 18%;

                            &:first-child {
                                width: 10%;
                            }

                            input {
                                text-align: right;
                            }

                            input[type="text"] {
                                text-align: left;
                            }
                        }
                    }

                    &:last-child {
                        td {
                            .plus-inner-icon {
                                color: #000000;
                                font-size: 17px;
                                margin: 0 auto;
                            }
                        }
                    }

                    .material-switch {
                        display: inline-block;
                        vertical-align: middle;
                    }

                    .material-switch>label {
                        cursor: pointer;
                        height: 0px;
                        position: relative;
                        width: 40px;
                    }

                    .material-switch>label::before {
                        background: lightgray;
                        border-radius: 8px;
                        content: "";
                        height: 16px;
                        margin-top: -8px;
                        position: absolute;
                        transition: all 0.4s ease-in-out;
                        width: 40px;
                    }

                    .material-switch>label::after {
                        background: $dim-gray;
                        border-radius: 16px;
                        content: "";
                        height: 24px;
                        left: -4px;
                        margin-top: -8px;
                        position: absolute;
                        top: -4px;
                        transition: all 0.3s ease-in-out;
                        width: 24px;
                    }

                    .material-switch>input[type="checkbox"]:checked+label::before {
                        background: rgb(0, 0, 0);
                        opacity: 0.4;
                    }

                    .material-switch>input[type="checkbox"]:checked+label::after {
                        background: $black;
                        left: 20px;
                        opacity: 1;
                    }
                }
            }

            .input-field {
                text-align: left;
                padding: 0;

                i {
                    &.left-icon {
                        position: absolute;
                        top: 10px;
                        left: 11px;
                    }

                    &.right-icon {
                        position: absolute;
                        top: 10px;
                        right: 20px;
                    }
                }
            }
        }

        .plus-row {
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);
            z-index: 999;
            // background: $white;
            border-radius: 50%;
            height: 36px;
            width: 36px;

            &:after {
                content: "";
                position: absolute;
                background: $white;
                height: 17px;
                width: 19px;
                top: 13px;
                left: 13px;
                z-index: -1;
            }

            .col {
                display: flex;
                justify-content: center;

                .plus-icon {
                    color: $white;
                    font-size: 20px;
                    padding: 20px 0;
                }
            }

            button {
                right: 5px;
            }
        }

        .input-field {
            position: relative;
            margin-bottom: 0;

            input[type="text"] {
                border: 0;
                padding-top: 0;
                box-sizing: inherit;
                height: auto;
                color: #000000;
                font-size: 25px;
                // font-weight: 600;
                font-weight: 500;
                padding-bottom: 0;
                margin-bottom: 0;

                &:hover {
                    text-overflow: inherit;
                }

                &:focus {
                    box-shadow: none;
                    border-bottom: 0;
                    color: #000000;
                }
            }

            i {
                &.left-icon {
                    position: absolute;
                    top: 10px;
                    left: 25px;
                }

                &.right-icon {
                    position: absolute;
                    top: 10px;
                    right: 20px;
                }
            }

            ul {
                display: none;
                background: $white;
                border-radius: 5px;
                padding: 8px 10px 0;
                position: absolute;
                width: calc(100%);
                top: 41px;
                border-top: 0;
                border: 1px solid lightgray;
                border-top: 0;
                border-top-left-radius: 0;
                border-top-right-radius: 0;
                box-shadow: 0 2px 3px rgba(0, 0, 0, 0.2);

                span {
                    font-size: 12px;
                    //  font-weight: 600;
                    font-weight: 500;
                }

                li {
                    cursor: pointer;
                    margin: 7px 0;
                }
            }

            .bottom-text {
                p {
                    margin-bottom: 0;
                    font-size: 14px;
                }
            }

            &.col {
                label {
                    left: 0;
                    top: -56%;
                }
            }
        }
    }

    #summary {
        margin-top: 25px;

        .apexcharts-canvas {
            margin: 0 auto;
        }

        .card {
            padding: 0 0;
            margin-left: auto;
            margin-right: auto;

            .col {
                padding-left: 0;

                .summary-table {
                    margin-top: 0;
                    border: 0;

                    thead {
                        tr {
                            border: 0;
                            border-top: 1px solid lightgray;

                            &:first-child {
                                border: 0;
                                padding: 15px 0;

                                th {
                                    font-size: 20px;
                                    color: #000;
                                    font-weight: 300;
                                    // padding: 15px 5px 15px 30px;
                                }
                            }

                            th {
                                color: $primary-teal;
                                font-family: $font-family;
                                text-transform: uppercase;
                                font-size: 12px;
                                font-weight: 500;
                                //font-weight: bold;
                                letter-spacing: 0.6px;
                                padding-top: 25px;
                                padding-bottom: 3px;

                                &:first-child {
                                    padding: 25px 5px 3px 30px;
                                }
                            }

                            &:first-child {
                                th {
                                    text-transform: capitalize;
                                    padding-bottom: 15px;
                                }
                            }
                        }
                    }

                    tbody {
                        tr {
                            border: 0;

                            td {
                                padding-bottom: 3px;

                                &:first-child {
                                    width: 30%;
                                    padding: 10px 5px 3px 30px;
                                    color: #000;
                                    //   font-weight: 500;
                                    font-weight: 300;
                                    font-size: 14px;
                                }

                                input {
                                    border: 0;
                                    height: auto;
                                    font-size: 14px;
                                    color: #000;
                                    opacity: 0.7;
                                    padding-top: 6px;

                                    &:focus {
                                        box-shadow: none;
                                        border: 0;
                                    }
                                }

                                input[type="number"]::-webkit-inner-spin-button,
                                input[type="number"]::-webkit-outer-spin-button {
                                    -webkit-appearance: none;
                                    -moz-appearance: none;
                                    appearance: none;
                                    margin: 0;
                                }

                                .material-icons {
                                    vertical-align: middle;
                                    font-size: 12px;
                                    margin-right: 8px;

                                    &.blue_dot {
                                        color: #3066be;
                                    }

                                    &.green_dot {
                                        color: #65a838;
                                    }

                                    &.orange_dot {
                                        color: #f69136;
                                    }

                                    &.yellow_dot {
                                        color: #fed766;
                                    }
                                }

                                svg {
                                    margin-right: 5px;
                                    width: 9px;
                                    height: 9px;
                                }
                            }
                        }
                    }
                }

                .canvasjs-chart-container {
                    button {
                        img {
                            width: 100%;
                        }
                    }
                }

                canvas {
                    width: 100%;
                }
            }

            .col {
                &.l9 {
                    @include desktop {
                        width: calc(60% - 20px);
                    }
                }

                &.l3 {
                    @include desktop {
                        width: calc(40% - 20px);
                    }
                }
            }
        }
    }

    #notes {

        .rdw-image-modal-header-option {
            cursor: default;
        }

        .public-DraftEditorPlaceholder-hasFocus {
            color: transparent;
        }

        .public-DraftStyleDefault-ol,
        .public-DraftStyleDefault-ul {
            margin: 0;
        }

        .public-DraftStyleDefault-block {
            margin: 0;
        }

        .input-field {
            textarea {
                min-height: 350px;
                padding: 10px;
                border: 1px solid lightgray;
                border-radius: 5px;
                padding-top: 65px;
            }

            ul:not(.public-DraftStyleDefault-ul) {
                display: block;
                position: absolute;
                top: 15px;
                left: 15px;

                li:not(.public-DraftStyleDefault-unorderedListItem) {
                    display: inline-block;
                    border: 1px solid #ccc;
                    padding: 0 10px;
                    cursor: pointer;

                    &:first-child {
                        border-top-left-radius: 4px;
                        border-bottom-left-radius: 4px;
                    }

                    &:last-child {
                        border-top-right-radius: 4px;
                        border-bottom-right-radius: 4px;
                    }

                    i {
                        vertical-align: middle;
                    }
                }
            }
        }
    }

    #note-details {
        .small-col {
            display: block;
        }

        .inner-title {
            padding: 24px 20px;
        }

        .outer-title {
            padding: 20px 25px 0;

            .outer-items {
                margin-bottom: 20px;
            }

            p {
                margin: 0;
                font-size: 12px;
            }
        }
    }

    // NEW TABS CSS
    .tabs-wrap {
        margin: 20px 0;

        li {
            display: inline-block;
            border-bottom: 2px solid transparent;

            p {
                font-size: 14px;
                font-weight: 500;
                //  font-weight: 600;
                text-transform: capitalize;
                transition: all 0.2s linear;
                margin: 0;
                padding: 5px 25px;
            }

            &.active {
                border-bottom: 2px solid $primary-pink;

                p {
                    color: $primary-pink;
                }


            }

            &.notActive {
                p {
                    color: $dim-gray;
                }

                &:hover {
                    cursor: default;

                    p {
                        color: $dim-gray;
                    }
                }
            }

            &:hover {
                cursor: pointer;

                p {
                    color: $primary-pink;
                }

                Button {
                    background-color: transparent;
                }
            }

            &.deactivate {
                pointer-events: none;

                button {
                    opacity: 0.5;
                }
            }
        }
    }

    .content-wrapper {
        .scope-wrap {
            padding-bottom: 400px;
            position: relative;

            &:after {
                content: '';
                position: absolute;
                bottom: 0;
                left: 0;
                background-image: url('../images/modify-template.svg');
                background-repeat: no-repeat;
                background-position: 50% 100%;
                min-height: 100vh;
                background-size: auto;
                // height: 100%;
                width: 100%;
                z-index: -1;
            }
        }

        .summary-wrap {
            &.active {
                padding-bottom: 400px;

                &:after {
                    content: '';
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    background-image: url('../images/modify-template.svg');
                    background-repeat: no-repeat;
                    background-position: 50% 100%;
                    min-height: 100vh;
                    background-size: auto;
                    width: 100%;
                    z-index: -1;
                }
            }
        }

        .expenses-wrap {

            .expenses-card {
                position: relative;
                margin: 0.5rem 0 1rem 0;
                background-color: #fff;
                transition: box-shadow .25s;
                border-radius: 5px;

                .col-inner,
                .col-outer {
                    .row {
                        padding: 0 38px;
                    }
                }

                .col-inner {
                    .row {
                        .exp-title {
                            margin-top: 52px;

                            h4 {
                                margin-bottom: 5px;
                            }
                        }

                        .exp-total-cost {
                            text-align: right;

                            span {
                                margin: 20px 0 15px;
                            }
                        }

                    }
                }

                .col-outer {
                    border-top: 2px solid rgba(8, 77, 79, 0.2);

                    h6 {
                        font-size: 20px;
                        font-weight: 300;
                        -webkit-box-orient: vertical;
                        text-overflow: ellipsis;
                        max-width: 700px;
                        -webkit-line-clamp: 1;
                        max-height: 32px;
                        overflow: hidden;
                        display: -webkit-box;
                    }

                    span {
                        font-size: 12px;
                        font-weight: 500;
                        color: #084d4F;
                        text-transform: capitalize;
                        vertical-align: top;
                    }

                    .one-line-title {
                        margin-bottom: 12px;

                        .input-field,
                        .input-cost,
                        .input-cost .cost {
                            margin-bottom: 0;
                        }
                    }

                    .expense-values {
                        position: relative;

                        .excludeExpense {
                            opacity: .3;
                        }

                        .input-cost {
                            &:hover {
                                .expense-hover {
                                    display: block;
                                }
                            }
                        }

                        .expense-hover {
                            position: absolute;
                            top: 12%;
                            right: -12px;
                            display: none;
                            cursor: pointer;

                            &:hover,
                            &.active {

                                fill: #e70a89;
                            }

                        }

                        .expenseMenu {
                            li {
                                &:hover {
                                    color: #e70a89;

                                    svg {
                                        fill: #e70a89;
                                    }
                                }

                                &.expenseEclude {
                                    &:hover {
                                        path {
                                            stroke: #e70a89 !important;
                                        }
                                    }
                                }
                            }
                        }

                        .input-field,
                        .input-cost {
                            margin-top: 0;
                        }

                        .input-cost .cost {
                            margin: 0;
                        }

                        .counting-expense-qty {
                            &::before {
                                position: absolute;
                                content: "";
                                color: rgba(8, 77, 79, 0.6);
                                background-image: url("../../assets/images/cross-green.svg");
                                top: 12px;
                                right: -32px;
                                font-weight: 500;
                                font-size: 20px;
                                height: 13px;
                                width: 20px;
                                background-repeat: no-repeat;
                                background-size: contain;
                                opacity: 0.6;
                            }
                        }

                        ul {
                            position: absolute;
                            border-radius: 6px;
                            box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .1);
                            background-color: #fff;
                            width: 200px;
                            transition: opacity .3s ease-in-out, visibility .3s ease-in-out;
                            border: 1px solid rgba(8, 77, 79, .1);
                            top: 60%;
                            right: -2%;
                            z-index: 3;
                            animation: scale-in-top .1s cubic-bezier(.25, .46, .45, .94) both;
                            cursor: pointer;

                            li {
                                padding: 8px 15px;
                                font-size: 14px;

                                svg {
                                    vertical-align: top;
                                    height: 25px;
                                    width: 25px;
                                    margin-right: 8px;
                                }
                            }
                        }
                    }

                    .input-field {
                        padding-left: 0;
                    }

                    .input-cost {
                        margin: 15px 0;
                        text-align: right;
                        display: flex;
                        justify-content: flex-end;
                        padding-right: 0;



                        .counting-expense-qty {
                            display: flex;
                            flex-direction: column;
                            justify-content: flex-start;
                            position: relative;
                            text-align: center;
                            margin-top: 0;
                            min-width: 111px;
                            max-width: 140px;

                            &:focus,
                            &:hover {
                                .expense-qty {
                                    margin: 0;

                                    input {
                                        box-shadow: none;
                                        width: 100px;
                                        border-radius: 50px;
                                        border-bottom: 0;
                                    }

                                    svg {
                                        display: block;

                                        &:hover {
                                            cursor: pointer;
                                        }

                                        transition: all 0.3s linear;
                                        animation: scale-up-center 0.3s ease-in;
                                    }
                                }
                            }


                            .expense-qty {
                                position: relative;
                                // display: flex;
                                // justify-content: center;
                                margin: 0 auto;
                                // text-align: center;
                                // background: rgba(8, 77, 79, .1);
                                // height: 38px;
                                // width: 38px;
                                border-radius: 50px;
                                transition: width .15s ease-in;
                                margin-top: 1px;
                                z-index: 2;
                                flex-direction: column;

                                position: relative;



                                input {
                                    border: 0;
                                    line-height: 2.2;
                                    height: 35px;
                                    width: 35px;
                                    position: relative;
                                    text-align: center;
                                    background: rgba(8, 77, 79, 0.1);
                                    border-radius: 50px;
                                    transition: width 0.15s ease-in;
                                    font-size: 14px;
                                    margin-bottom: 2px;

                                    &:focus {
                                        box-shadow: none;
                                        border-bottom: 0;
                                    }

                                }

                                input::-webkit-outer-spin-button,
                                input::-webkit-inner-spin-button {
                                    -webkit-appearance: none;
                                    margin: 0;
                                }


                                svg {
                                    font-size: 34px;
                                    border: 1px solid rgba(8, 77, 79, 0.1);
                                    border-radius: 50%;
                                    padding: 5px;
                                    position: absolute;
                                    background: $white;
                                    display: none;
                                    top: 0;

                                    &.add {
                                        right: 5px;
                                    }

                                    &.delete {
                                        left: 5px;
                                    }
                                }


                            }

                        }

                        .expense-cost {
                            width: 100%;
                            max-width: 120px;

                            input {
                                margin-top: 12px;
                                font-size: 20px !important;
                                font-weight: 300 !important;
                            }
                        }
                    }

                    .expense-submit {
                        margin: 15px 0 0;

                        &::before {
                            content: "";
                            position: absolute;
                            bottom: 65px;
                            left: 47px;
                            width: calc(100% - 93px);
                            height: 2px;
                            background-color: rgba(8, 77, 79, .2);
                        }

                        &.empty_expense {
                            &::before {
                                content: none;

                            }

                            margin: 0;
                        }

                        button {
                            padding: 5px 15px;
                            border-radius: 5px;
                            background-color: #084d4f;

                            span {
                                color: #fff !important;
                            }

                            &:hover {
                                background-color: transparent;

                                span {
                                    color: #084d4f !important;
                                }
                            }
                        }
                    }



                }


            }

            &.active {
                padding: 0 .75rem;
                padding-bottom: 400px;

                &:after {
                    content: '';
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    background-image: url('../images/modify-template.svg');
                    background-repeat: no-repeat;
                    background-position: 50% 100%;
                    min-height: 100vh;
                    background-size: auto;
                    width: 100%;
                    z-index: -1;
                }
            }



        }

        .notes-wrap {
            position: relative;
            padding-bottom: 400px;

            .MuiPaper-rounded {
                padding: 10px;

                p {
                    font-size: 0.875rem;
                    line-height: 1.43;
                    margin: 0;
                }

            }

            .note-button {
                color: #fff;
                height: 40px;
                font-size: 14px;
                box-shadow: none;
                font-weight: 300;
                margin-top: 20px;
                border-radius: 5px;
                text-transform: Capitalize;
                background-color: rgba(231, 10, 137, 1);

                &:hover {
                    box-shadow: none;
                    background-color: #d00a7b;
                }
            }

            &:after {
                content: '';
                position: absolute;
                bottom: 0;
                left: 0;
                background-image: url('../images/modify-template.svg');
                background-repeat: no-repeat;
                background-position: 50% 100%;
                min-height: 100vh;
                background-size: auto;
                width: 100%;
                z-index: -1;
            }
        }

    }
}

.summary-details {

    // position: relative;
    // &:before {
    //     content: '';
    //     position: absolute;
    //     background-image: url("../../assets/images/background-new.svg");
    //     background-size: contain;
    //     background-repeat: no-repeat;
    //     bottom: 210px;
    //     right: -85%;
    //     height: 100%;
    //     max-height: 300px;
    //     width: 100%;
    //     z-index: -1;
    //  }
    .col-inner {
        span:not(.MuiButton-label) {
            font-size: 12px;
            font-weight: 500;
            //font-weight: bold;
            line-height: 1.42;
            letter-spacing: 0.6px;
            color: $primary-teal;
            display: inline-block;
            margin-top: 9px;
        }

        h4 {
            font-size: 25px;
            // font-weight: 600;
            font-weight: 500;
            line-height: 1.36;
            color: #000000;
            margin-bottom: 10px;
        }

        h6 {
            font-size: 12px;
            line-height: 1.42;
            color: #000000;
        }
    }

    .scope-table {
        .MuiFab-primary[aria-expanded="true"] {
            svg {
                transform: rotate(45deg);
            }
        }
    }

    .resource {
        .MuiFab-primary {
            height: 35px;
            width: 35px;
            background-color: $primary-teal;
            box-shadow: none;
        }

        .MuiFab-primary[aria-expanded="true"] {
            svg {
                transform: rotate(45deg);
            }
        }

        #AddResource-actions {
            padding-left: 36px;

            button {
                box-shadow: 0px 2px 7px -1px rgba(0, 0, 0, 0.2);
                margin-right: 3px;

                svg {
                    stroke: none;
                    fill: #2ab7ca;
                }

                &:hover {
                    background-color: rgba(0, 0, 0, 0.09);

                    svg {
                        fill: $primary-pink;
                    }
                }
            }
        }

        .one-line-elment {
            position: relative;
            display: flex;
            flex-wrap: wrap;
            align-items: flex-end;

            input[type="text"] {
                box-shadow: none;
                border: 0;
                height: 1.2rem;
                padding-left: 40px;
                font-size: 14px;
                font-weight: 300;

                &:hover {
                    text-overflow: inherit;
                }

                //font-weight: 500;
                &:focus {
                    box-shadow: none;
                    border: 0;
                }
            }

            label {
                padding-left: 40px;

                &.Mui-focused {
                    color: $primary-teal;
                    padding-left: 0;
                }

                &.MuiFormLabel-filled.Mui-focused {
                    display: block;
                }

                &.MuiFormLabel-filled {
                    color: $primary-teal;
                    padding-left: 0;
                    display: none;
                }
            }

            .hover-wrap {
                display: none;

                button {
                    .MuiTouchRipple-root {
                        color: transparent;
                    }

                    &:hover {
                        background-color: transparent;

                        svg {
                            fill: $primary-pink;
                        }
                    }
                }
            }

            &:hover {
                .hover-wrap {
                    display: block;
                }
            }

            .resource_icon {
                position: absolute;
                top: 19px;
                left: 0px;
            }

            .gray-circle {
                position: relative;

                input {
                    border: 0;
                    line-height: 2.2;
                    height: 35px;
                    width: 35px;
                    position: relative;
                    text-align: center;
                    background: rgba(8, 77, 79, 0.1);
                    border-radius: 50px;
                    transition: width 0.15s ease-in;
                    font-size: 14px;
                    margin-bottom: 2px;

                    &:focus {
                        box-shadow: none;
                        border-bottom: 0;
                    }
                }

                svg {
                    font-size: 34px;
                    border: 1px solid rgba(8, 77, 79, 0.1);
                    border-radius: 50%;
                    padding: 5px;
                    position: absolute;
                    background: $white;
                    display: none;
                    top: 0;

                    &.add {
                        right: 20px;
                    }

                    &.delete {
                        left: 20px;
                    }
                }
            }

            .list {
                // font-weight: 600;
                font-weight: 500;
                max-width: 400px;
                display: inline-block;
                width: calc(22% - 10px);

                &.title {
                    font-size: 12px;
                    font-weight: 500;
                    //  font-weight: bold;
                    color: $primary-teal !important;
                    text-transform: uppercase;
                }

                h5 {
                    font-size: 12px;
                    font-weight: 500;
                    color: $primary-teal;
                    text-transform: uppercase;
                    letter-spacing: 0.6px;
                }

                &.hrs {
                    position: relative;
                    margin-right: 4px;

                    @media screen and (max-width: 1220px) {
                        margin-right: 6px;
                    }

                    input {
                        border: 1px solid lightgray;
                        text-align: center;
                        width: 34%;
                        height: 38px;
                        border-radius: 5px;
                        font-family: "nota";

                        &:focus {
                            box-shadow: none;
                        }
                    }
                }

                &.cost {}
            }

            &.one-line-title {
                margin: 4px 0 10px;
                display: flex;
                flex-wrap: wrap;
                width: 100%;

                .list {
                    display: flex;
                    flex-wrap: wrap;
                    text-align: center;
                    justify-content: flex-start;
                    min-width: 166px;

                    @media screen and (max-width: 1220px) {
                        min-width: 150px;
                    }

                    &.title {
                        font-size: 12px;
                        font-weight: 500;
                        color: $primary-teal !important;
                        text-transform: uppercase;
                    }

                    h5 {
                        font-size: 12px;
                        font-weight: 500;
                        color: $primary-teal;
                        text-transform: uppercase;
                        letter-spacing: 0.6px;
                    }

                    &.qty {}

                    &.hrs {
                        input {
                            border: 1px solid lightgray;
                            text-align: center;
                            width: 34%;
                            height: 38px;
                            border-radius: 5px;

                            &:focus {
                                box-shadow: none;
                            }
                        }

                        &:before {
                            display: none;
                        }
                    }

                    &.cost {
                        margin-right: 4px;
                    }
                }

                .title-resource-wrap {
                    display: flex;
                    // flex-wrap: wrap;
                    justify-content: flex-end;
                    // width: calc(100% - 220px);
                    max-width: 830px;
                    margin-right: 0;
                    margin-left: auto;

                    // @media screen and (max-width:1200px) {
                    //     flex-wrap: initial;
                    //     justify-content: end;
                    // }
                    .list {
                        justify-content: flex-end;
                    }
                }
            }

            .MuiFormControl-root {
                max-width: 310px;

                @media screen and (min-width: 1500px) {
                    max-width: 440px;
                }

                @media screen and (max-width: 1100px) {
                    max-width: 280px;
                }
            }

            .MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot {
                padding-right: 10px;
            }

            .resource-wrap {
                display: flex;
                //width: calc(100% - 220px);
                justify-content: flex-end;
                align-items: center;
                padding-right: 0;
                max-width: 830px;
                margin-right: 0;
                margin-left: auto;

                >div {
                    text-align: right;
                    margin: 0;
                    min-width: 148px;

                    &.list {
                        &.qty {
                            margin-left: auto;
                            margin-right: -10px;
                            text-align: center;
                            max-width: 100px;
                            outline: 0;

                            @media screen and (max-width: 1220px) {
                                margin-right: -58px;
                            }

                            &:focus,
                            &:hover {
                                input {
                                    box-shadow: none;
                                    width: 100px;
                                    border-radius: 50px;
                                    border-bottom: 0;
                                }

                                svg {
                                    display: block;

                                    &:hover {
                                        cursor: pointer;
                                    }

                                    transition: all 0.3s linear;
                                    animation: scale-up-center 0.3s ease-in;
                                }
                            }
                        }

                        &.hrs {
                            &:before {
                                position: absolute;
                                content: "";
                                color: rgba(8, 77, 79, 0.6);
                                background-image: url("../../assets/images/cross-green.svg");
                                top: 15px;
                                left: 0;
                                font-weight: 500;
                                font-size: 20px;
                                height: 13px;
                                width: 20px;
                                background-repeat: no-repeat;
                                background-size: contain;
                                opacity: 0.6;

                                @media screen and (max-width: 1220px) {
                                    left: 44px;
                                }

                            }

                            input {
                                border: solid 1px rgba(8, 77, 79, 0.1) !important;
                                text-align: center !important;
                                height: 38px;
                                width: 38px;
                                margin-right: 33px;
                                font-size: 14px;
                                right: 33px !important;
                                padding-left: 0px !important;
                                top: 5px !important;
                                min-width: auto;

                                @media screen and (max-width: 1220px) {
                                    margin-right: 0;
                                    right: 0 !important;
                                }

                            }
                        }

                        input[type="number"]::-webkit-inner-spin-button,
                        input[type="number"]::-webkit-outer-spin-button {
                            -webkit-appearance: none;
                            margin: 0;
                        }

                        &.cost {
                            padding-right: 0;
                            margin-bottom: 2px;

                            span {
                                p {
                                    display: inline-block;
                                    margin-right: -15px;
                                }
                            }

                            input {
                                font-size: 14px;
                                max-width: 68px;
                                border-radius: 5px;
                                margin-bottom: 0;
                                right: 22px !important;
                                top: 3px !important;

                                @media screen and (max-width: 1220px) {
                                    right: 7px !important;
                                }

                                &:hover {
                                    box-shadow: none;
                                }

                                &:focus {
                                    box-shadow: none;
                                }
                            }
                        }
                    }

                }

                .resource_input {
                    min-width: 148px;
                }
            }
        }
    }

    .MuiAutocomplete-endAdornment {
        display: none;
    }

    .MuiInput-underline:before {
        display: none;
    }
}

.auto-fill {
    color: #000;
    padding-bottom: 20px;
    position: relative;
    padding: 0 50px 20px;
    display: block;

    @media screen and (max-width: 1300px) {
        padding: 0 30px 20px;
    }

    .switch-wrap {
        padding-right: 0 !important;
        max-width: 42px;
        padding-left: 15px !important;

    }

    .switch-input-wrap {
        padding-bottom: 5px !important;
        // padding-right: 0 !important;
        max-width: 173px;

        #myPercent[disabled] {
            ~span {
                color: gray;
            }
        }

        #meeting[disabled] {
            ~span {
                color: gray;
            }
        }

        .MuiPaper-root {
            width: 87%;
        }
    }

    .switch-title-wrap {
        padding-left: 0 !important;
        margin-right: 3px;

        .MuiFormControl-root {
            @media screen and (max-width: 1420px) {
                margin-top: 7px;
                margin-left: 0 !important;
                padding-left: 20px !important;
            }
        }

        span {
            @media screen and (max-width: 1420px) {
                left: 80px !important;
            }
        }
    }

    >div {
        color: rgba(0, 0, 0, 1) !important;
    }

    p {
        font-size: 12px;
        // font-weight: 500;
        font-weight: bold;
        line-height: 1.42;
        letter-spacing: 0.6px;
        color: $primary-teal;
        text-transform: uppercase;
    }

    .switch {
        position: relative;
        display: inline-block;
        width: 32px;
        height: 14px;
        padding-top: 19px;
    }

    .switch input {
        display: none;
    }

    .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(8, 77, 79, 0.1);
        -webkit-transition: 0.4s;
        transition: 0.4s;
        height: 14px;
        margin-top: 5px;
    }

    .slider:before {
        position: absolute;
        content: "";
        height: 20px;
        width: 20px;
        left: -3px;
        bottom: -3px;
        background-color: white;
        -webkit-transition: 0.4s;
        transition: 0.4s;
        border: solid 1px rgba(8, 77, 79, 0.2);
    }

    input:checked+.slider:before {
        background-color: $primary-teal;
        -webkit-transform: translateX(16px);
        -ms-transform: translateX(16px);
        transform: translateX(16px);
    }

    input:focus+.slider {
        box-shadow: 0 0 1px #2196f3;
    }

    /*------ ADDED CSS ---------*/
    .on {
        display: none;
    }

    .on,
    .off {
        color: white;
        position: absolute;
        transform: translate(-50%, -50%);
        top: 50%;
        left: 50%;
        font-size: 10px;
        font-family: Verdana, sans-serif;
    }

    input:checked+.slider .on {
        display: block;
    }

    input:checked+.slider .off {
        display: none;
    }

    input:checked+.MuiPaper-elevation1 input[type="number"] {
        color: #000;
    }

    /*--------- END --------*/

    /* Rounded sliders */
    .slider.round {
        border-radius: 34px;
    }

    .MuiPaper-elevation1 {
        box-shadow: none;
        background-color: transparent;
        position: relative;

        &::first-letter {
            text-transform: capitalize;
        }

        span {
            position: absolute;
            right: 6px;
            top: 30%;
            transform: translateY(-50%);
            font-size: 14px;
            //font-weight: 500;
            font-weight: 300;
        }
    }

    .slider.round:before {
        border-radius: 50%;
    }

    .MuiPaper-elevation1 input[type="number"] {
        //InputFields Border
        padding: 2px 8px 10px;
        border-radius: 5px;
        //border: solid 1px rgba(8, 77, 79, 0.1);
        box-sizing: border-box;
        font-size: 14px;
        // padding-right: 18px;
        font-weight: 300;
        //font-weight: 500;
        color: #000000;

        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
            margin: 0;
        }

        &:focus {
            box-shadow: none !important;
            border-bottom-color: rgba(8, 77, 79, 0.1) !important;
        }

        &:disabled {
            //border: solid 1px rgba(8, 77, 79, 0.1);
            color: #00000080;
        }
    }

    h6 {
        font-size: 14px;
        // font-weight: 500;
        font-weight: 300;
        line-height: 1.36;
        color: #000000;
    }

    .values-wrap {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-end;
        max-width: 720px;
        margin-left: auto;
        margin-right: 0;

        .cost-wrap {
            text-align: right;
            padding-right: 50px;
            min-width: 120px;

            @media screen and (max-width: 1220px) {
                padding-right: 35px;
            }
        }

        .wrap-count {
            text-align: left;
            position: relative;
            padding-right: 144px;
            min-width: 120px;

            @media screen and (max-width: 1220px) {
                padding-right: 0px;
                padding-left: 5px;
            }
        }

        .price-wrap {
            text-align: right;
            min-width: 120px;
        }
    }

    .calc-values-wrap {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-end;

        .wrap-count {
            text-align: right;
            max-width: 140px;
            padding-right: 61px;

            @media screen and (max-width: 1220px) {
                padding-right: 46px;
            }

        }

        .cost-wrap {
            text-align: right;
            max-width: 140px;
            padding-right: 31px;

            @media screen and (max-width: 1220px) {
                padding-right: 15px;
                max-width: 125px;
            }
        }

        .price-wrap {
            text-align: right;
            max-width: 140px;
        }
    }

    .title-wrap {
        text-align: right;
    }
}

@-webkit-keyframes scale-up-center {
    0% {
        transform: scale(0.5);
    }

    100% {
        transform: scale(1);
    }
}

@keyframes scale-up-center {
    0% {
        transform: scale(0.5);
    }

    100% {
        transform: scale(1);
    }
}