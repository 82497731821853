.gantt-component {
	position: relative;
	padding: 0px;
	margin: 10px 0;
	height: 100%;
	overflow: hidden;
	font-family: notosans;
}

.gantt-container,
#gantt_here {
	width: 100%;
	height: 100%;
}