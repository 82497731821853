// @import "~normalize.css";
@import "./scss/calendar";
@import "./scss/fonts";
@import "./scss/tooltip";
@import "./scss/grid";
@import "./scss/controlPanel";
@import "./scss/resourceMenu";
@import "./scss/lightbox";
@import "./scss/phase";
@import "./scss/animation.scss";
@import "./scss/timelines.scss";
@import "./scss/summary.scss";
@import "./scss/addNewPhaseBtn.scss";
@import "./scss/addingNewTaskButton";
@import "./scss/confirmWindow";

// html,
// body {
// 	height: 100%;
// }

#app {
	position: relative;
	height: 100%;
	font-family: notosans;
}

.dhx_calendar {
	font-family: notosans;
}

.gantt_tree_content {
	display: flex;
	align-items: center;
}

.gantt_grid_data .gantt_row,
.gantt_grid_data .gantt_row.odd,
.gantt_task_row {
	&.gantt_selected {
		background: #fff;
	}

	&:hover {
		background: #fff;
	}
}

div.gantt_grid_scale:after,
div.gantt_scale_line:last-child:after {
	display: none;
}

.gantt_scale_cell {
	border: none;
}

.gantt_scale_line {
	border: none;
	background-color: rgba(8, 77, 79, 0.05);
}

.gantt_task_line {
	border: none;
	border-radius: 5px;
	background-color: rgba(48, 102, 190, .6);
}

.gantt_data_area .gantt_task_row:last-child,
.gantt_grid_data .gantt_row:last-child {
	border-bottom: 0px;
}

.gantt_task_vscroll {
	background-color: rgba(8, 77, 79, 0.05);
}

//line after grid

.gantt_layout_cell.grid_cell {
	border-right: 1px solid rgba(8, 77, 79, 0.2);
}

.gantt_grid .gantt_row {
	border: none;
}

.gantt_grid .gantt_grid_scale {
	border-right: none;
}

.gantt_grid_data {
	border-right: 0px solid rgba(8, 77, 79, 0.2);
}

.gantt_grid_head_resource {
	overflow: unset;
}

.dhx_calendar-day--muffled {
	opacity: 1;
}

.dhx_calendar-day--weekend-disabled {
	color: rgba(0, 0, 0, 0.3);
}

.edit_estimates {
	position: sticky;
	top: -10px;
}