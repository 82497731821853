@import "variables";

@font-face {
    font-family: "NotoSans";
    src: url("../fonts/Noto_Sans/NotoSans-Regular.ttf");
}

@font-face {
    font-family: "Noto Sa-Bold";
    src: url("../fonts/Noto_Sans/NotoSans-Bold.ttf");
}

@font-face {
    font-family: "Noto Sa-I";
    src: url("../fonts/Noto_Sans/NotoSans-Italic.ttf");
}

body {
    font-family: $font-family !important;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    // padding-top: 64px;
    transition: margin-left 0.3s ease-in-out;
    overflow-x: hidden;

    &.drawer-open {
        margin-left: 194px;
    }
}

* {
    box-sizing: border-box;
    font-family: $font-family !important;
}

// button:focus{
//     background-color: transparent;
// }
.loader-container {
    display: flex;
    height: 100vh;
    justify-content: center;
    align-items: center;
    overflow: hidden;

    svg {
        opacity: 1;
        visibility: visible;
        height: 500px;
        width: 500px;
    }

    object {
        height: 150px;
        width: 150px;
    }
}

section {
    position: relative;
    padding: 80px 0;
    margin-left: 80px;
}

.bg-img {
    position: absolute;
    height: 100%;
    width: 100%;
}

img {
    object-fit: cover;
    width: inherit;
    height: inherit;
}

h1 {
    font-family: $font-family;
    font-size: 45px;
    font-weight: 300;
    line-height: 1.2;
    letter-spacing: -0.026em;
    color: $black;
    margin: 0;

    @include desktop {
        font-size: 40px;
    }

    @include tablet {
        font-size: 35px;
    }

    @include phablet {
        font-size: 32px;
    }

    @include phablet-down {
        font-size: 30px;
    }
}

h2 {
    font-family: $font-family;
    font-size: 30px;
    font-weight: 300;
    line-height: 1.35;
    letter-spacing: -0.015em;
    color: $black;
    margin: 0;

    @include desktop {
        font-size: 28px;
    }

    @include tablet {
        font-size: 26px;
    }

    @include phablet {
        font-size: 24px;
    }

    @include phablet-down {
        font-size: 20px;
    }
}

h3 {
    font-family: $font-family;
    font-size: 25px;
    font-weight: 300;
    line-height: 1.27;
    color: $black;
    margin: 0;
    letter-spacing: 0.01em;

    @include phablet {
        font-size: 24px;
    }

    @include phablet-down {
        font-size: 20px;
    }
}

h4 {
    font-family: $font-family;
    font-size: 22px;
    font-weight: 300;
    line-height: 1.45;
    color: $black;
    margin: 0;

    @include phablet {
        font-size: 20px;
    }

    @include phablet-down {
        font-size: 18px;
    }
}

h5 {
    font-family: $font-family;
    font-size: 18px;
    font-weight: 300;
    line-height: 1.36;
    color: $black;
    margin: 0;

    @include phablet-down {
        font-size: 16px;
    }
}

h6 {
    font-family: $font-family;
    font-size: 16px;
    font-weight: 300;
    line-height: 1.33;
    letter-spacing: 0.011em;
    color: $black;
    margin: 0;
}

p {
    font-family: $font-family;
    font-size: 16px;
    font-weight: 300;
    line-height: 1.63;
    letter-spacing: 0.01em;
    color: $black;
    margin: 10px 0;

    @include tablet-down {
        font-size: 14px;
    }
}

.big-paragraph {
    font-size: 20px;
}

span {
    font-family: $font-family;
}

a {
    font-family: $font-family;
}

.container {
    position: relative;
    margin: 0 auto;
    max-width: 1410px !important;
    width: 100% !important;
    padding: 0 30px;

    @media screen and (min-width: 1401px) {
        max-width: 100% !important;
    }
}

.MuiAutocomplete-popper {
    z-index: 99 !important;
    min-width: 175px;
}

.estimateAdditon .MuiAutocomplete-popper {
    z-index: 99999 !important;
}

ul,
li {
    margin: 0;
    padding: 0;
    font-family: $font-family;
}

.row {
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;

    >div {
        padding: 0 15px;
    }

    .col-3 {
        flex: 0 0 33.33%;
        -webkit-box-flex: 0;
    }
}

.error {
    color: #750b0b;
    text-align: center;
    margin: 20px 0 10px;
}

.suggestions {
    background: #fff;
    border-radius: 5px;
    padding: 8px 10px 0;
    position: absolute;
    width: calc(100% - 22px);
    top: 41px;
    border: 1px solid #d3d3d3;
    border-top: 0;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    box-shadow: 0 2px 3px rgba(0, 0, 0, .2);
    font-family: $font-family;
}

input {
    font-family: $font-family !important;
}

label {
    font-family: $font-family !important;
}

.no-height {
    height: auto !important;
    margin: 25px 0 !important;
}

button {
    &:focus {
        background-color: transparent;
    }
}

//global
.errorState {
    label {
        &.MuiFormLabel-filled {
            color: #db263b !important;

            ~div {
                fieldset {
                    border-color: #db263b !important;
                    border-width: 1px !important;
                }
            }
        }
    }

    .MuiFormControl-root .Mui-focused fieldset {
        border: solid 1px #db263b !important;
    }

    .MuiOutlinedInput-notchedOutline {
        border: solid 1px #db263b !important;
    }

    .MuiFormControl-root label.MuiInputLabel-shrink {
        color: #db263b !important;
    }
}

.errorInput {
    .MuiInputBase-root.MuiOutlinedInput-root:hover {
        fieldset {
            border-color: #db263b !important;
        }
    }

    label {
        &.MuiFormLabel-filled {
            ~div {
                fieldset {
                    border-color: #db263b !important;
                    border-width: 1px !important;
                }
            }
        }
    }

    .MuiFormControl-root .Mui-focused fieldset {
        border: solid 1px #db263b !important;
    }

    .MuiOutlinedInput-notchedOutline {
        border: solid 1px #db263b !important;
    }
}

.MuiTextField-root.errorState .MuiOutlinedInput-root:hover fieldset {
    border-color: #db263b !important;
}

.errorState .MuiOutlinedInput-root.MuiInputBase-marginDense:hover fieldset {
    border-color: #db263b !important;
}


.contact-page {
    .MuiAutocomplete-popper {
        z-index: 1300 !important;
    }
}