.form-wrap {
  min-height: 700px;
  position: relative;

  &:after {
    content: '';
    position: absolute;
    background-image: url(../../../assets/images/form-block.png);
    background-size: cover;
    background-repeat: no-repeat;
    bottom: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: -1;
  }

  .inner-wrap {
    max-width: 1250px;
    margin: 0 auto;
    min-height: 580px;

    .large-block {
      position: relative;

      &:after {
        content: '';
        position: absolute;
        background-image: url(../../../assets/images/new-svgs/new_login_in.svg);
        background-size: cover;
        background-repeat: no-repeat;
        bottom: 0;
        left: 0;
        height: 100%;
        width: 100%;

        @media screen and (min-width: 1200px) {
          bottom: -50px;
          background-size: contain;
        }
      }

      p {
        font-size: 20px;
        font-weight: 100;
        padding-top: 15px;
        margin-bottom: 60px;
      }
    }

    form {
      margin-top: 0;

      h5 {
        margin-bottom: 30px;
        letter-spacing: 1px;
        font-size: 20px;
        font-weight: 500;
      }

      label {
        line-height: 0.5;
        font-weight: 300;

        &.Mui-focused {
          line-height: 1;
          color: #084d4f;
          opacity: 0.5;
        }

        &.MuiFormLabel-filled {
          line-height: 1;
          color: #084d4f;
          opacity: 0.5;
        }

        &.MuiFormLabel-filled {
          ~div {
            fieldset {
              border-color: #000 !important;
            }
          }
        }
      }

      input:-webkit-autofill {
        -webkit-box-shadow: 0 0 0px 1000px #ffffff inset !important;
      }

      input {
        border: 0;
        box-shadow: none !important;
        height: 45px;
        padding: 0 15px;
        margin-bottom: 0;
        font-weight: 300;
        letter-spacing: 0.01em;
        appearance: none;

        &:focus {
          border-color: #000;
          outline: 0;
          border-bottom: 0 !important;
          appearance: none;
        }
      }

      #email {
        padding: 0 15px;
        font-size: 13px;
        letter-spacing: 0.01em;
        appearance: none;
      }

      input[type=password] {
        padding-left: 14px;
        margin-bottom: 0;
        font-size: 13px;
        letter-spacing: 0.01em;
        appearance: none;

        &:focus {
          border: 0;
          box-shadow: none;
          appearance: none;
        }
      }

      .MuiInputAdornment-root {
        width: auto;
        margin-top: 10px;
        appearance: none;
      }

      .link-wrap {
        margin-top: 10px;

        label {
          .MuiTypography-root {
            color: #000;
            font-weight: 300;
            font-size: 14px;
          }

          &.Mui-focused {
            color: #000;
          }
        }

        p {
          font-size: 14px;

          a {
            color: #084d4f;
            text-decoration: underline;

            &:hover {
              text-decoration: none;
            }
          }
        }
      }

      .MuiCheckbox-colorSecondary.Mui-checked {
        color: #e70a89;
      }

      .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
        border-color: #000;
        border-width: 1px;
      }
    }

    .bottom-wrap {
      .forget-pass-alert {
        background-color: #3066be;

        .MuiAlert-standardInfo {
          background-color: #3066be;
        }

        div {
          color: #fff;

          .MuiTypography-gutterBottom {
            margin-bottom: 0;
          }

          svg {
            fill: #fff;
          }
        }
      }

      p {
        font-size: 14px;

        a {
          color: #084d4f;
          text-decoration: underline;

          &:hover {
            text-decoration: none;
          }
        }
      }
    }
  }

  .MuiAlert-standardInfo {
    background-color: #3066be;
    color: #fff;

    svg {
      fill: #fff;
    }
  }

  .MuiAlert-standardWarning {
    background-color: #F69136;
    color: #fff;

    svg {
      fill: #fff;
    }
  }
}

.forget-pass-alert {
  position: absolute;
  left: 0;
  right: 0;
  margin: auto !important;
  max-width: 450px !important;
  top: 18px;
  background: #3066be;
  border-radius: 5px;

  .MuiAlert-standardInfo {
    background: #3066be;

    .MuiAlert-icon,
    .MuiAlert-message,
    .MuiAlert-action {
      color: #fff;
    }
  }

  .MuiAlert-icon {
    padding: 10px 0 0;
  }

  .MuiAlert-message {
    top: 3px;
    position: relative;
  }

  .MuiAlert-action {
    button {
      cursor: pointer;
      z-index: 99;
      position: relative;
    }
  }
}

.error {
  position: absolute;
  left: 0;
  right: 0;
  margin: auto !important;
  max-width: 450px !important;
  top: 18px;
  border-radius: 5px;

  .MuiAlert-standardError {
    background-color: #db263b;

    .MuiAlert-icon,
    .MuiAlert-message,
    .MuiAlert-action {
      color: #fff;
    }
  }

  .MuiAlert-icon {
    padding: 10px 0 0;
  }

  .MuiAlert-message {
    top: 3px;
    position: relative;
  }

  .MuiAlert-action {
    button {
      cursor: pointer;
      z-index: 99;
      position: relative;
    }
  }
}

h5.error {
  background: #3066be;
  color: white;
  min-height: 50px;
  padding: 13px 20px;
  position: absolute;
  right: 0;
  left: 0;
  top: 80px;
  margin: auto;
  max-width: 450px;
  border-radius: 4px;
  font-size: 16px;

  &:before,
  &:after {
    position: absolute;
    top: 15px;
    right: 20px;
    content: ' ';
    height: 15px;
    width: 2px;
    background-color: #fff;
  }

  &:before {
    transform: rotate(45deg);
  }

  &:after {
    transform: rotate(-45deg);
  }
}