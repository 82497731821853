$radioSize: 20px;
$radioBorder: #D1D7E3;
$radioActive: #5D9BFB;

.lightbox-time-config {

	input[type="radio"] {
		-webkit-appearance: none;
		-moz-appearance: none;
		appearance: none;

		display: none !important; //making it hide to remove space, for Time phase.
		width: 20px;
		height: 20px;
		border-radius: 50%;
		cursor: pointer;
		border: 2px solid #084d4f;
		transition: 1s all linear;
		margin-right: 5px;
		position: relative;

		&:checked {
			display: flex;
			justify-content: center;
			align-items: center;

			&::after {
				content: "";
				display: block;
				width: 10px;
				height: 10px;
				border-radius: 50%;
				background-color: #084d4f;
			}
		}
	}
}