.new-templates {
    // padding-left: 27px; for side section
    padding: 78px 0 0 0;
    margin-top: 20px;

    .container {
        @media only screen and (min-width: 1501px) {
            max-width: 100% !important;
        }
    }

    background-color: rgba(8, 77, 79, 0.05);

    .content {
        margin-bottom: 30px;
    }

    .recent-view {
        .row {
            background: #FFFFFF;
            padding: 20px;
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        }

        .s2 {
            text-align: end;
        }
    }

    .time-based-table {
        .button-wrap {
            text-align: right;
        }

        .tab-row {
            background: #FFFFFF;
            padding: 20px;
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

            .col {
                a {
                    color: gray;
                    position: relative;

                    ul {
                        display: none;
                        position: absolute;
                        top: 20px;
                        left: 0;
                        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
                        border-radius: 5px;
                        min-width: 150px;
                        padding: 12px;
                        background: white;
                        z-index: 9;

                        li {
                            color: black;
                            padding: 4px 0;
                        }
                    }

                    &:hover ul {
                        display: block;
                    }
                }

                .icon {
                    vertical-align: middle;
                    margin-right: 4px;
                }

                h6 {
                    display: flex;
                    align-items: center;
                }
            }

            .menu-options {
                height: 100%;
                align-items: center;
                display: flex;
            }
        }

        .col {
            .input-field {
                ul {
                    display: none;
                }

                i.right-arrow {
                    position: absolute;
                    top: 20px;
                    right: -25px;
                }
            }

            &.tags {
                margin: 0 20px 0 0;

                input {
                    padding-left: 26px;
                }
            }

            .cross {
                position: absolute;
                right: 15px;
                top: 12px;
                cursor: pointer;
            }

            #tags {
                border: 0;
                box-shadow: none;
                border-radius: 50px;
                background: #fff;
                padding: 5px 39px 6px 56px;
                box-sizing: border-box;
                font-size: 18px;
                font-weight: 100;
                color: #0000007d;
                width: 300px;

                // &:focus {
                //   box-shadow: 0 2px 3px rgba(0, 0, 0, 0.2);
                //   background: $white;
                // }
                &::-webkit-input-placeholder {
                    /* Edge */
                    color: #0000007d;
                    font-weight: 100;
                    font-size: 18px;
                }

                &::placeholder {
                    font-weight: 400;
                    color: rgba(0, 0, 0, 0.4);
                }
            }
        }

        .pagination {
            nav {
                background-color: transparent;
                box-shadow: none;
            }

            ul {
                justify-content: center;

                li {
                    height: 100%;
                }
            }
        }

        .item-option {
            button {
                background: transparent;
            }

            .MuiTouchRipple-root {
                color: transparent;
            }

            .select-box {
                div[x-placement="bottom"] {
                    left: -55px !important;
                    z-index: 9;
                }

                #menu-list-summary {
                    li {
                        margin: 0;

                        svg {
                            margin-right: 10px;
                        }

                        img {
                            display: block;
                            margin-right: 10px;

                            &.template_pink {
                                display: none;
                            }
                        }

                        &:hover {
                            img {
                                display: none;

                                &.template_pink {
                                    display: block;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    &.edit-template {
        padding: 78px 0 0 0;

        //margin-top: -20px;
        .summary {
            width: calc(100% + 60px);
            margin-left: -30px;
            margin-top: -20px;

            .top-content {
                .title {
                    display: none;
                }

                .violator-ribbon {
                    display: block;
                    left: -35px;
                }
            }
        }

        .summary-details {
            width: calc(100% + 30px);
            margin-left: -15px;
            background-color: transparent;

            &.template-modify {
                border: 3px solid rgb(42, 183, 202);
                background-color: rgba(8, 77, 79, 0.05);

                .container {
                    padding: 0 30px;
                }
            }

            .violator-bar {
                display: flex;

                .btn-wrap {
                    button {
                        box-shadow: none;
                    }
                }
            }
        }
    }
}