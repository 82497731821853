.control-panel {
	display: flex;
	align-items: center;
	justify-content: space-between;
	background-color: rgba(8, 77, 79, 0.05);

	// .btn_add-phase {
	// 	margin-left: auto;
	// 	border-radius: 50%;
	// 	color: #840ab1;
	// 	cursor: pointer;
	// }

	.gantt-select,
	.grid_toggle {
		margin-right: 6px;
	}

	.grid_toggle {
		display: flex;
		justify-content: center;
		align-items: center;
		cursor: pointer;
		height: 30px;
		width: 38px;

		&:hover {
			opacity: .8;
		}

		&:active {
			opacity: .6;
		}

		&:after {
			content: "";
			height: 15px;
			width: 20px;
			background-image: url("../../img/icons/icon_close_grid.png");
			background-position: center;
			background-repeat: no-repeat;
			background-size: cover;
		}
	}


	// "<div class='gantt-select'>",
	// "<input id='change-zooming' class='change-zooming'>",
	// "<ul class='period-container'>",


	.gantt-select {
		position: relative;
		margin-left: 50px;

		.closed-container {
			position: absolute;
			display: flex;
			align-items: center;
			top: 12px;
			width: 112px;
			height: 32px;
			z-index: 10;
			cursor: pointer;

			&::before {
				content: "";
				position: absolute;
				left: 10px;
				height: 17px;
				width: 15px;
				background-image: url("../../img/icons/icon_zoom_selector.png");
				background-position: center;
				background-repeat: no-repeat;
				background-size: cover;
			}

			&::after {
				content: "";
				position: absolute;
				right: 10px;
				width: 12px;
				height: 7.4px;
				background-image: url("../../img/icons/controlPanel_open.svg");
				background-position: center;
				background-repeat: no-repeat;
				background-size: cover;
			}

			&.active {
				&::after {
					background-image: url("../../img/icons/controlPanel_close.svg");
				}
			}
		}

		#change-zooming {
			position: relative;
			appearance: none;
			width: 74.5px;
			height: 30px;
			padding-left: 35.5px;
			border-radius: 5px;
			border: solid 1px rgba(8, 77, 79, 0.2);
			background-color: transparent;
			font-size: 14px;
			text-transform: capitalize;
		}

		.period-container {
			display: block;
			position: absolute;
			visibility: hidden;
			top: 41px;
			z-index: 1000;
			list-style: none;
			border-radius: 5px;
			box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
			border: solid 1px rgba(8, 77, 79, 0.1);
			background-color: #ffffff;
			padding: 0;
			text-transform: none;
			font-size: 14px;
			color: black;
			opacity: 0;
			transition: all .2s linear;
			z-index: 3000;

			li {
				display: flex;
				justify-content: flex-start;
				align-items: center;
				padding-left: 21px;
				width: 99px;
				height: 45px;
				cursor: pointer;

				&.active {
					background-color: rgba(8, 77, 79, 0.05);
				}

				&:hover {
					background-color: rgba(8, 77, 79, 0.11);
				}
			}

			&.active {
				visibility: visible;
				opacity: 1;
			}
		}
	}
}

.grid_toggle--closed .grid_toggle:after {
	background-image: url("../../img/icons/icon_open_grid.png");
}