@import "variables";

.manage-tags,
.manage-tasks,
.manage-phases {
    .panel-list {
        button {
            background-color: transparent;

            .MuiTouchRipple-root {
                color: transparent;
            }

            &:hover {
                background-color: transparent;

                svg {
                    fill: $primary-pink;
                }
            }
        }
    }
}