.resource-menu {
	position: absolute;
	display: flex;
	width: 210px;
	height: 35px;
	z-index: 900;

	&.phase-menu {
		position: relative;
		width: auto;
		margin-top: 15px;
	}

	&:hover {
		.line {
			width: 100%;
			left: 0;
		}
	}

	.resource-input {
		position: relative;
		display: flex;
		align-items: center;

		.enter-resource {
			width: 100%;
			margin-left: 10px;
			margin-bottom: 0px;
			font-size: 14px;
			line-height: 1.36;
			border: none;
			background: transparent;
			cursor: pointer;
			outline: none;
			overflow: hidden;
			text-overflow: ellipsis;
			border-bottom: none !important;
			box-shadow: none !important;

			&:focus {
				border-bottom: none !important;
				box-shadow: none !important;
			}

			&.enter-phase {
				max-width: 255px;
				margin-left: 0;
				padding-left: 0;
				font-size: 25px;
				font-weight: 500;
				font-stretch: normal;
				font-style: normal;
				letter-spacing: normal;
				color: #000000;
			}

			&.cursore-active {
				cursor: text;
				border-bottom: none !important;
				box-shadow: none !important;
			}

			&:focus~.resource-label,
			&:valid~.resource-label {
				top: -25px;
				left: -20px;
				color: #084d4f;
				font-size: 10px;
				transition: 0.2s all;
			}

			&:focus~.phase-label,
			&:valid~.phase-label {
				top: -10px;
				left: 0;
				color: #084d4f;
				font-size: 10px;
			}
		}

		.resource-label {
			position: absolute;
			left: 0;
			left: 10px;
			font-size: 14px;
			color: rgb(0, 0, 0);
			pointer-events: none;
			opacity: 1;
			z-index: 5000;

			&.phase-label {
				left: 0;
				font-size: 25px;
				color: black;
			}

			&.disable {
				opacity: 0;
			}
		}
	}
}

.line {
	position: absolute;
	content: "";
	width: 0%;
	height: 2px;
	background-color: rgb(17, 17, 17);
	bottom: 0;
	left: 50%;
	transition: all 0.2s ease-in-out;

	&.static {
		width: 100%;
		left: 0;
	}
}

.names-container {
	display: none;
	flex-direction: column;
	position: absolute;
	width: 205px;
	top: 0;
	left: 0;
	z-index: 999;
	border-radius: 5px;
	box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
	border: solid 1px rgba(8, 77, 79, 0.1);
	background-color: #ffffff;

	&.active {
		display: flex;
	}

	.list-names {
		max-height: 160px;
		overflow-y: scroll;
	}

	.list-names {
		.resource-name {
			display: flex;
			align-items: center;
			padding-left: 19px;
			padding-top: 10px;
			padding-right: 19px;
			padding-bottom: 10px;
			background-color: #ffffff;
			text-decoration: none;
			cursor: pointer;
			font-size: 15px;
			color: #000000;
			line-height: 1.36;

			&:hover {
				background-color: rgba(8, 77, 79, 0.05);
			}

			.name {
				width: 190px;
				pointer-events: none;

				b {
					color: red;
				}
			}

		}
	}

	.resource-new_name {
		display: none;
		align-items: center;
		padding-top: 15px;
		padding-bottom: 13px;
		padding-left: 19px;
		border-top: 2px solid rgba(8, 77, 79, 0.05);
		cursor: pointer;

		&:hover {
			background-color: rgba(8, 77, 79, 0.05);
		}

		.container {
			display: flex;
			align-items: center;
			width: 170px;
			max-height: 180px;
			font-size: 14px;
			line-height: 1.36;
			color: #3066be;
			pointer-events: none;
			overflow: hidden;
		}

		.resource-text {
			width: 145px;
			overflow-wrap: break-word;
		}

		.new-resource-icon {
			width: 12px;
			height: 12px;
			margin-right: 10px;
			background-image: url("../../img/icons/resourceMenu_plus.svg");
			background-size: contain;
			background-repeat: no-repeat;
			background-position: center;
		}
	}
}