.gantt-closeLightbox {
	width: 350px;
	height: 255px;
	margin: 0;
	padding: 0;
	border-radius: 5px;
	background-color: #ffffff;

	div {
		display: flex;
		justify-content: center;
		margin: 0;
		padding: 0;
		color: #000000;
	}

	.dhtmlx_popup_title {
		font-size: 20px;
		margin-top: 60px;
		padding: 0;
		line-height: 1.35;

	}

	.dhtmlx_popup_text {
		min-height: auto;
		margin-top: 10px;
		padding: 0;


		span {
			width: 218px;
			text-align: center;
			font-size: 14px;
			line-height: 1.36;
		}
	}

	.gantt_popup_controls {
		margin-top: 30px;
	}

	.gantt_popup_button {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 86px;
		height: 40px;
		border-radius: 5px;

		div {
			font-family: NotoSans;
			font-size: 14px;
			font-weight: bold;
			line-height: 1.36;
			letter-spacing: 0.14px;
			color: #ffffff;
			text-transform: initial;
		}
	}

	.gantt_cal_light .gantt_btn_set.gantt_save_btn_set,
	.gantt_popup_button.gantt_ok_button,
	.gantt_qi_big_icon.icon_edit {
		background-color: #e70a89;
	}

	.gantt_ok_button {
		background-color: #e70a89;
		margin-right: 10px;

		&:hover {
			background-color: #e70a89;
		}
	}

	.gantt_cancel_button {
		width: 84px;
		border: solid 1px #000000;

		div {
			font-weight: 500;
			color: #000000;
		}

		&:hover {
			background-color: #ffffff;
		}
	}

	.close-confirmWindow {
		position: absolute;
		top: 30px;
		right: 30px;
		width: 20px;
		height: 20px;

		background-color: rgba(255, 255, 255, 0);
		background-image: url("../../img/icons/closeConfirmWindowBtn.svg");
		background-size: contain;
		background-repeat: no-repeat;
		background-position: center;
		cursor: pointer;
	}
}