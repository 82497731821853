.gantt_task_cell {
	border: none;
}

.timeline-project-row-with-border {
	border-bottom: 1px solid rgba(8, 77, 79, 0.2) !important;
	// border-top: 1px solid rgba(8, 77, 79, 0.2);

	&.closed {
		border-bottom: 2px solid rgba(8, 77, 79, 0.2) !important;
	}

	&:last-child {
		border-bottom: 2px solid rgba(8, 77, 79, 0.2) !important;
	}
}

.timeline-button-with-border {
	border-bottom: 2px solid rgba(8, 77, 79, 0.2) !important;
}

.timeline-row-with-border {
	border-bottom: 1px solid rgba(8, 77, 79, 0.2);
}

.timeline-cell-with-bg {
	background-color: rgba(8, 77, 79, 0.03);
}

.timeline-cell-with-bg-shadow {
	&::after {
		content: "";
		display: block;
		width: 100%;
		height: 100%;
		background: linear-gradient(-45deg, rgba(0, 0, 0, 0) 49.9%, rgba(160, 158, 158, 0.3) 49.9%, rgba(160, 158, 158, 0.3) 60%, rgba(0, 0, 0, 0) 60%) fixed,
			linear-gradient(-45deg, rgba(160, 158, 158, 0.3) 10%, rgba(0, 0, 0, 0) 10%) fixed;
		background-size: 0.5em 0.5em;
	}
}

.histogram-resource-cell {
	color: #000000;
}

.gantt_task_content {
	padding: 0;
}

.gantt_bar_project .gantt_task_content {
	cursor: default;
}

.task-cell-time {
	display: flex;
}

.task-line--hidden {
	display: none;
}

.gantt_task_line .gantt_task_drag {
	background: url("../../img/icons/icon_resize_task.png") center no-repeat;
	width: 5px;
	opacity: 1;
}

.cell-container {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 35px;

	.cell-time {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 35px;
		height: 20px;
		color: white;
	}

	//input in timeline

	// input {
	// 	width: 35px;
	// 	height: 20px;
	// 	text-align: center;
	// 	background-color: rgba(255, 255, 255, 0);
	// 	border: none;
	// 	color: white;
	// 	outline: 0;
	// 	pointer-events: auto;

	// 	&:focus {
	// 		border: 2px solid white;
	// 	}
	// }

}