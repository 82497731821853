@import "variables";

#login-form{
  max-width: 600px;
  margin: 0 auto;
  .btn-row{
    text-align: center;
  }
}
.login-form{
  .forget{
    color: #3d3da0;
    font-size: 12px;
    cursor: pointer;
  }
}