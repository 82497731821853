@import "variables";

.scope-time {
    >.container {
        @media screen and (min-width: 1500px) {
            max-width: 100% !important;
        }
    }

    .tabs-wrap {
        margin: 30px 0;

        li {
            display: inline-block;
            border-bottom: 2px solid transparent;

            p {
                font-size: 14px;
                //  font-weight: 600;
                font-weight: 500;
                text-transform: capitalize;
                transition: all 0.2s linear;
                margin: 0;
                padding: 5px 25px;
            }

            &.active {
                border-bottom: 2px solid $primary-pink;

                p {
                    color: $primary-pink;
                }
            }

            &:hover {
                cursor: pointer;

                p {
                    color: $primary-pink;
                }

                Button {
                    background-color: transparent;
                }
            }

            &.deactivate {
                pointer-events: none;

                button {
                    opacity: 0.5;
                }
            }
        }
    }

    .content-wrapper {
        .time-based-wrap {
            padding-bottom: 400px;
            position: relative;

            &:after {
                content: '';
                position: absolute;
                bottom: 0;
                left: 0;
                background-image: url('../images/time-based-empty.svg');
                background-repeat: no-repeat;
                background-position: 38% 100%;
                min-height: calc(90vh - 435px);
                background-size: auto;
                // height: 100%;
                width: 100%;
                // z-index: 1;
            }
        }

        .scope-wrap {
            padding-bottom: 400px;
            position: relative;

            &:after {
                content: '';
                position: absolute;
                bottom: -30px;
                left: 0;
                background-image: url('../images/time-based-bg.svg');
                background-repeat: no-repeat;
                background-position: 38% 100%;
                min-height: calc(100vh - 435px);
                background-size: auto;
                height: 100%;
                width: 90%;
                z-index: -1;

                @media screen and (min-width: 1921px) {
                    background-size: auto;
                    width: 100%;
                    height: auto;
                }
            }
        }

        .summary-wrap {
            padding-bottom: 100px;
        }

        .notes-wrap {
            position: relative;
            padding-bottom: 400px;

            // .note-button {
            //     color: #fff;
            //     height: 40px;
            //     font-size: 14px;
            //     box-shadow: none;
            //     font-weight: 300;
            //     margin-left: 10px;
            //     border-radius: 5px;
            //     text-transform: Capitalize;
            //     background-color: rgba(231, 10, 137, 1);
            // }

            &:after {
                content: '';
                position: absolute;
                bottom: -30px;
                left: 0;
                background-image: url('../images/time-based-bg.svg');
                background-repeat: no-repeat;
                background-position: 38% 100%;
                min-height: calc(100vh - 435px);
                background-size: auto;
                height: 100%;
                width: 90%;
                z-index: -1;

                @media screen and (min-width: 1921px) {
                    background-size: auto;
                    width: 100%;
                    height: auto;
                }
            }
        }
    }

    .calendar-header {
        .MuiSelect-icon {
            color: #000000;
        }

        .calendar-interval {
            display: flex;
            flex-flow: row wrap;
            align-items: center;
            max-width: 110px;
            height: 30px;
            border: 1px solid rgba(8, 77, 79, 0.2);
            border-radius: 5px;
            background-color: rgba(8, 77, 79, 0.05);
            position: relative;
            z-index: 3;
            cursor: pointer;

            svg {
                position: absolute;
                top: 50%;
                transform: translateY(-50%);

                &:first-of-type {
                    width: 20px;
                    height: 20px;
                    left: 7px;
                }

                &:last-of-type {
                    width: 22px;
                    height: 22px;
                    right: 4px;
                    transition: transform 0.3s ease-in-out;
                }
            }

            span {
                display: inline-block;
                padding-left: 35px;
                font-size: 14px;
                //  font-weight: 600;
                font-weight: 500;
                line-height: 1.36;
                letter-spacing: normal;
            }

            ul {
                margin-top: 10px;
                max-width: 120px;
                border-radius: 5px;
                box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
                border: solid 1px rgba(8, 77, 79, 0.1);
                background-color: $white;
                opacity: 0;
                visibility: hidden;
                position: absolute;
                z-index: 5;
                top: 30px;
                transition: opacity 0.3s ease-in-out;

                li {
                    padding: 13px 56px 13px 21px;
                    font-size: 14px;
                    font-weight: normal;
                    line-height: 1.36;
                    letter-spacing: normal;
                    cursor: pointer;

                    &:not(:first-child) {
                        margin: 1px 0;
                        padding: 12px 56px 12px 21px;
                    }

                    &:last-child {
                        margin: 0;
                    }

                    &:hover {
                        background-color: rgba(8, 77, 79, 0.05);
                    }

                    &.active {
                        background-color: rgba(8, 77, 79, 0.05);
                    }
                }
            }

            &:hover {
                svg {
                    &:last-of-type {
                        transform: rotate(180deg) translateY(50%);
                    }
                }

                ul {
                    opacity: 1;
                    visibility: visible;
                }
            }
        }

        .calendar-labels {
            display: flex;
            flex-flow: row wrap;
            justify-content: space-evenly;
            align-items: center;
            width: 100%;
            position: relative;

            svg {
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                cursor: pointer;

                &:first-of-type {
                    left: 0;
                }

                &:last-of-type {
                    right: 0;
                }
            }

            ul {
                display: inline-flex;
                flex-wrap: row wrap;
                justify-content: space-evenly;
                align-items: center;
                width: 100%;
            }

            li {
                // flex: 0 0 10%;
                // max-width: 10%;
                display: inline-block;
                font-size: 12px;
                //  font-weight: bold;
                font-weight: 500;
                line-height: 1.42;
                letter-spacing: 0.6px;
                text-align: center;
                position: relative;
                cursor: pointer;

                span {
                    display: block;
                    width: auto;
                }
            }
        }
    }

    .week {
        max-width: 150px;
        position: relative;

        i {
            position: absolute;
            top: 10px;
            left: 11px;
        }
    }

    select {
        display: block;
        background: $white;
        border-radius: 4px;
        padding-left: 40px;
    }

    .phase {
        .input-field {
            max-width: 380px;
            position: relative;
        }

        span {
            display: inline-block;
            width: 100%;
        }

        input {
            background: $white;
            border-radius: 4px;
            border: 1px solid #000 !important;
            padding-left: 40px !important;
            box-sizing: border-box !important;
        }

        .assignment {
            position: absolute;
            top: 11px;
            left: 10px;
        }

        .right-arrow {
            position: absolute;
            top: 12px;
            right: 10px;
        }

        ul {
            display: none;
            background: $white;
            border-radius: 5px;
            padding: 8px 10px 0;
            position: absolute;
            width: calc(100%);
            top: 44px;
            border-top: 0;
            border: 1px solid lightgray;
            border-top: 0;
            border-top-left-radius: 0;
            border-top-right-radius: 0;
            box-shadow: 0 2px 3px rgba(0, 0, 0, 0.2);
            z-index: 3;

            span {
                font-size: 12px;
                font-weight: 500;
                // font-weight: 600;
            }

            li {
                cursor: pointer;
                margin: 7px 0;
            }
        }

        input[type=text]:focus~ul {
            display: block;
        }

        ul:visited,
        ul:active,
        ul:focus-within,
        ul:focus-visible {
            display: block;
        }
    }

    .back-link {
        margin-bottom: 25px;

        a {
            float: right;
            color: #000;

            i {
                color: #000;
                margin-top: -3px;
                vertical-align: middle;
            }
        }
    }

    .input-field {
        #resource {
            padding: 10px 12px !important;
        }
    }

    .resource {
        i {
            opacity: 0.5;
        }

        p {
            margin: 0;
            padding-left: 10px;
            opacity: 0.5;
        }
    }
}

.calendar-list {
    max-width: 120px !important;
    border-radius: 5px !important;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
    border: solid 1px rgba(8, 77, 79, 0.1);
    background-color: $white !important;

    ul {
        padding: 0;

        li {
            padding: 13px 56px 13px 21px;
            font-size: 14px;
            font-weight: normal;
            line-height: 1.36;
            letter-spacing: normal;
            cursor: pointer;

            &:not(:first-child) {
                margin: 1px 0;
                padding: 12px 56px 12px 21px;
            }

            &:last-child {
                margin: 0;
            }

            &:hover {
                background-color: rgba(8, 77, 79, 0.05);
            }

            &.Mui-selected {
                background-color: rgba(8, 77, 79, 0.05);
            }
        }
    }
}

.scope-table {
    margin-top: 9px;
    border-radius: 5px;
    border: solid 1px rgba(8, 77, 79, 0.1);
    background-color: $white;
    position: relative;

    .scope-table-header {
        padding: 18px 50px 21px;
        position: relative;

        &::after {
            content: "";
            width: 100%;
            height: 2px;
            background-color: rgba(8, 77, 79, 0.2);
            position: absolute;
            bottom: 0;
            left: 0;
        }

        h6 {
            font-size: 12px;
            //font-weight: bold;
            font-weight: 500;
            line-height: 1.42;
            letter-spacing: 0.6px;
            color: $primary-teal;
        }

        .scope-autocomplete {
            .row {
                margin-bottom: 0;
            }

            .icon-box {
                position: absolute;
                left: 20px;
                top: 47%;
                margin: 0;
                opacity: 0;
                transform: translateY(-50%);
                transition: opacity 0.3s ease-in-out;
            }

            .input-field {
                font-size: 25px;
                color: #000;
                margin-top: 0;
                position: relative;
                margin-bottom: 0;

                input[type="text"] {
                    margin-bottom: 0;
                    border: 0;
                    padding-top: 0;
                    box-sizing: inherit;
                    height: auto;
                    color: #000000;
                    font-size: 25px;
                    //font-weight: 600;
                    font-weight: 500;

                    &:focus {
                        box-shadow: none;
                        border-bottom: 0;
                        color: #000000;
                    }
                }

                i {
                    &.left-icon {
                        position: absolute;
                        top: 10px;
                        left: 25px;
                    }

                    &.right-icon {
                        position: absolute;
                        top: 10px;
                        right: 20px;
                    }
                }

                ul {
                    display: none;
                    background: $white;
                    border-radius: 5px;
                    padding: 8px 10px 0;
                    position: absolute;
                    width: calc(100%);
                    top: 41px;
                    border-top: 0;
                    border: 1px solid lightgray;
                    border-top: 0;
                    border-top-left-radius: 0;
                    border-top-right-radius: 0;
                    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.2);

                    span {
                        font-size: 12px;
                        // font-weight: 600;
                        font-weight: 500;
                    }

                    li {
                        cursor: pointer;
                        margin: 7px 0;
                    }
                }

                .bottom-text {
                    margin-top: 10px;

                    p {
                        margin-bottom: 0;
                        font-size: 14px;
                    }
                }

                span {
                    font-size: 12px;
                    //   font-weight: bold;
                    font-weight: 500;
                    color: $primary-teal;
                    text-transform: uppercase;
                    vertical-align: top;
                }

                .MuiAutocomplete-endAdornment {
                    display: none;
                }

                .MuiInput-underline:before {
                    border: 0;
                }

                label {
                    font-size: 25px;
                    color: #000;
                    //  font-weight: 600;
                    font-weight: 500;

                    &.MuiInputLabel-shrink {
                        font-size: 14px;
                        color: $primary-teal;
                    }

                    &.MuiFormLabel-filled {
                        display: none;
                    }
                }

                .MuiFormControl-root {
                    max-width: 260px;
                }

                .MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot {
                    padding-right: 10px;

                    .MuiAutocomplete-endAdornment {
                        display: none;
                    }

                    &:before,
                    &:after {
                        display: none;
                    }
                }

                .MuiAutocomplete-root {
                    margin-top: -13px;
                }
            }

            &:hover {
                .icon-box {
                    opacity: 1;
                }
            }
        }

        h4 {
            margin-top: 15px;
            font-size: 25px;
            //font-weight: 600;
            font-weight: 500;
            line-height: 1.36;
            letter-spacing: normal;
            color: #000000;
        }

        small {
            display: inline-block;
            margin-top: 7.5px;
            font-size: 12px;
            line-height: 1.42;
            letter-spacing: normal;
            color: #000000;
        }

        .expand-more-carrot {
            position: absolute;
            top: 17px;
            right: 15px;
            cursor: pointer;
            transition: all 0.3s ease-in;
            z-index: 3;
        }

        &.collapsed {
            .expand-more-carrot {
                transform: rotate(180deg);
            }
        }
    }

    .scope-table-body {
        background: $white;

        .table-content {
            padding: 46px 0 40px;
            position: relative;

            .menu-toggle {
                position: absolute;
                top: 13px;
                right: 60px;
                cursor: pointer;
                color: #000;
            }

            .table-heading {
                margin-bottom: 5px;
                padding: 0 50px 0;

                .MuiGrid-item {
                    &:not(:first-child) {
                        text-align: center;
                    }

                    &:last-child {
                        text-align: right;
                        padding-right: 12px;
                    }

                    h6 {
                        font-size: 12px;
                        //font-weight: bold;
                        font-weight: 500;
                        line-height: 1.42;
                        letter-spacing: 0.6px;
                        color: $primary-teal;
                        text-transform: uppercase;
                    }
                }
            }

            .table-body {
                .item {
                    padding: 0 50px 0 60px;
                    position: relative;

                    &::after {
                        content: "";
                        width: calc(100% - 50px);
                        height: 1px;
                        background-color: rgba(8, 77, 79, 0.2);
                        position: absolute;
                        bottom: 0;
                        left: 50px;
                    }

                    .input-box {
                        //   font-weight: 600;
                        font-weight: 500;
                        display: inline-block;
                        width: calc(22% - 10px);
                        position: relative;

                        .icon-box {
                            display: inline-block;
                            width: 13px;
                            height: 8px;
                            opacity: 0;
                            visibility: visible;
                            cursor: pointer;
                            position: absolute;
                            top: 50%;
                            left: -33px;
                            transform: translateY(-50%);
                            transition: opacity 0.3s ease-in-out;

                            img {
                                width: 100%;
                                height: 100%;
                                vertical-align: top;
                            }
                        }

                        .resource_icon {
                            position: absolute;
                            top: 19px;
                            left: 10px;
                        }

                        .label.MuiFormLabel-filled {
                            color: $primary-teal;
                            padding-left: 0;
                            display: none;
                        }

                        .MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot {
                            padding-right: 10px;

                            &::before {
                                display: none;
                            }
                        }

                        .MuiFormControl-fullWidth {
                            max-width: 260px;
                        }

                        label {
                            padding-left: 40px;

                            &.Mui-focused {
                                color: $primary-teal;
                                padding-left: 0;
                            }

                            &.MuiFormLabel-filled {
                                color: $primary-teal;
                                padding-left: 0;
                                display: none;
                            }
                        }

                        .MuiInputLabel-formControl {
                            top: -7px;
                        }

                        input[type="text"] {
                            box-shadow: none;
                            border: 0;
                            height: 1.2rem;
                            margin-bottom: 0;
                            padding-top: 0;
                            padding-left: 40px;
                            font-size: 14px;
                            //   font-weight: 500;
                            font-weight: 300;
                        }

                        .MuiAutocomplete-endAdornment {
                            display: none;
                        }
                    }

                    .MuiGrid-item {
                        padding: 7px 0;

                        &:not(:first-child) {
                            text-align: center;
                        }

                        &:last-child {
                            text-align: right;
                            padding-right: 4px;
                        }

                        p,
                        span {
                            margin: 0;
                            font-size: 14px;
                            //  font-weight: 500;
                            font-weight: 300;
                            line-height: 35px;
                            letter-spacing: normal;
                            color: #000000;
                        }

                        p {
                            padding-left: 39px;
                            position: relative;

                            .title-icon {
                                position: absolute;
                                top: 50%;
                                left: 0;
                                transform: translateY(-50%);
                            }
                        }

                        &:nth-child(2) {
                            position: relative;

                            .input-wrap {
                                position: relative;
                                display: flex;
                                justify-content: center;
                                margin: 0 auto;
                                margin-top: 6px;
                                text-align: center;
                                background: rgba(8, 77, 79, 0.1);
                                height: 35px;
                                width: 35px;
                                border-radius: 50px;
                                min-width: auto;
                                transition: width 0.15s ease-in;

                                input {
                                    border: 0;
                                    line-height: 2.2;
                                    height: 35px;
                                    position: relative;
                                    text-align: center;
                                    margin-bottom: 0;
                                    font-size: 14px;

                                    &::-webkit-inner-spin-button {
                                        appearance: none;
                                        margin: 0;
                                    }

                                    &:focus {
                                        box-shadow: none;
                                        border-bottom: 0;
                                    }
                                }

                                svg {
                                    font-size: 34px;
                                    border: 1px solid rgba(8, 77, 79, 0.1);
                                    border-radius: 50%;
                                    padding: 5px;
                                    position: absolute;
                                    background: #fff;
                                    display: none;
                                    top: 50% !important;
                                    transform: translateY(-50%);
                                    transition: all 0.3s linear;

                                    &.add {
                                        right: 0;
                                    }

                                    &.delete {
                                        left: 0;
                                    }
                                }

                                &:focus,
                                &:hover {
                                    width: 100px;

                                    input {
                                        box-shadow: none;
                                        border-bottom: 0;
                                    }

                                    svg {
                                        display: block;
                                        transition: all 0.3s linear;

                                        &:hover {
                                            cursor: pointer;
                                        }
                                    }
                                }
                            }
                        }

                        &:nth-child(3) {
                            line-height: 35px;

                            .MuiSvgIcon-root {
                                margin-top: 8px;
                                vertical-align: middle;
                            }
                        }

                        &:last-child {
                            input {
                                border: 0;
                                text-align: center;
                                max-width: 40px;
                                height: 38px;
                                border-radius: 5px;
                                font-family: "nota";
                                text-align: right;
                                padding-right: 10px;

                                &:focus {
                                    box-shadow: none;
                                }
                            }

                            .MuiIconButton-root {
                                padding: 0;
                                margin: 6px 0 7px !important;
                            }

                            .MuiSvgIcon-root {
                                width: 35px;
                                height: 35px;
                            }

                            .menu-icon {
                                display: inline-block;
                                height: 29px;
                                opacity: 0;
                                visibility: hidden;
                                position: absolute;
                                top: 50%;
                                right: 30px;
                                transform: translateY(-50%);

                                svg {
                                    width: 20px;
                                    height: 28px;
                                }

                                &:hover {
                                    svg {
                                        fill: rgb(231, 10, 137);
                                    }
                                }
                            }
                        }
                    }

                    .hover-wrap {
                        display: none;

                        svg {
                            height: 26px !important;
                            width: 26px !important;
                        }

                        button {
                            background-color: transparent !important;

                            .MuiTouchRipple-root {
                                color: transparent !important;
                            }

                            span {
                                color: transparent;
                            }
                        }
                    }

                    &:hover {
                        .input-box {
                            .icon-box {
                                opacity: 1;
                                visibility: visible;
                            }
                        }

                        .MuiGrid-item {
                            &:last-child {
                                .menu-icon {
                                    opacity: 1;
                                    visibility: visible;
                                }
                            }
                        }

                        .hover-wrap {
                            display: block;
                        }
                    }

                    &:last-child {
                        .MuiGrid-item {
                            &:nth-child(2) {
                                svg {
                                    top: 55%;
                                }
                            }
                        }
                    }
                }
            }

            .table-footer {
                padding: 0 60px 0 50px;

                .makeStyles-exampleWrapper-60 {
                    margin-top: 12px;
                }

                .MuiFab-primary {
                    height: 35px;
                    width: 35px;
                    background-color: $primary-teal;
                    box-shadow: none;
                }

                #SpeedDialexample-actions {
                    button {
                        svg {
                            stroke: #2ab7ca;
                            fill: transparent;
                        }
                    }
                }

                .MuiSpeedDial-actions {
                    button {
                        box-shadow: 0px 2px 7px -1px rgba(0, 0, 0, 0.2);

                        svg {
                            fill: #2ab7ca;
                        }
                    }
                }
            }
        }

        .duration-content {
            width: 100%;

            .duration-outer {
                width: 100%;
                height: 100%;

                .duration-item {
                    height: 100%;

                    &:nth-child(odd) {
                        background-color: rgba(8, 77, 79, 0.03);
                    }
                }
            }
        }
    }

    .plus-row {
        position: absolute;
        bottom: -32px;
        left: 50%;
        transform: translateX(-50%);

        .col {
            display: flex;
            justify-content: center;

            .plus-icon {
                color: $white;
                font-size: 20px;
                padding: 20px 0;
            }
        }
    }
}

.summary-table {
    margin-top: 49px;
    background-color: $white;
    border: solid 1px rgba(8, 77, 79, 0.1);
    border-radius: 5px;

    &.expandTime {
        .summary-header {
            &:after {
                display: none;
            }

            .icon_click {
                transform: rotate(0);
            }
        }
    }

    .summary-header {
        padding: 20px 50px 21px;
        position: relative;

        &::after {
            content: "";
            width: 100%;
            height: 2px;
            background-color: rgba(8, 77, 79, 0.2);
            position: absolute;
            bottom: 0;
            left: 0;
        }

        h5 {
            font-size: 14px;
            // font-weight: 500;
            font-weight: 300;
            line-height: 1.36;
            letter-spacing: 0.7px;
        }

        .icon_click {
            display: none;
            position: absolute;
            right: 12px;
            top: 16px;
            cursor: pointer;
            width: 25px;
            height: 25px;
            transform: rotate(-180deg);
        }

        &:hover {
            .icon_click {
                display: block;
            }
        }
    }

    .summary-body {
        .col-one {
            padding-left: 51px;
        }

        .col-two {
            display: block;
        }

        .summary-title {
            padding: 18px 0 15px;
            position: relative;

            &:first-child {
                padding-top: 31px;
            }

            &:not(:first-child) {
                &:before {
                    content: "";
                    width: 100%;
                    height: 1px;
                    background: rgba(8, 77, 79, 0.2);
                    position: absolute;
                    top: 0;
                    left: 0;
                }
            }

            &:last-child {
                padding-bottom: 29px;
            }

            h6 {
                font-size: 12px;
                // font-weight: bold;
                font-weight: 500;
                line-height: 1.42;
                letter-spacing: normal;
                text-transform: uppercase;
                color: $primary-teal;
            }
        }

        .summary-content {
            background-color: $white;
            position: relative;

            &:not(:first-child) {
                &:before {
                    content: "";
                    width: 100%;
                    height: 1px;
                    background: rgba(8, 77, 79, 0.2);
                    position: absolute;
                    top: 0;
                    left: 0;
                }
            }

            .summary-data {
                padding: 17px 0 14px;
                text-align: center;

                &:nth-child(odd) {
                    background-color: rgba(8, 77, 79, 0.03);
                }

                span {
                    font-size: 14px;
                    // font-weight: 500;
                    font-weight: 300;
                    line-height: 1.36;
                    letter-spacing: normal;
                    color: #000000;
                }
            }

            &:first-child {
                .summary-data {
                    padding-top: 30px;
                }
            }

            &:last-child {
                .summary-data {
                    padding-bottom: 29px;
                }
            }
        }
    }
}

.banner-second-level {
    padding-top: 40px;

    .container-wrapper {
        max-width: 1061px;
        margin: 0 auto;

        @media screen and (min-width: 1501px) {
            max-width: 100% !important;
        }
    }

    .img-wrap {
        max-width: 225px;
        max-height: 207px;
        overflow: hidden;

        img {
            width: 100%;
            height: auto;
            object-fit: contain;
        }
    }

    .content-wrap {
        background-color: transparent;
        border-top: none;
        padding: none !important;
        padding-left: none !important;
        color: #000;

        h3 {
            font-size: 40px;
            //font-weight: 500;
            font-weight: 300;
            line-height: 1.38;
            letter-spacing: normal;
            color: #000000;
        }

        p {
            margin-top: 10px;
            font-size: 20px;
            font-weight: 300;
            line-height: 1.35;
            letter-spacing: normal;
            color: #000000;
        }

        .btn-wrap {
            margin-top: 30px;

            button {
                max-width: 162px;
                padding: 11px 23px 10px;
                border-radius: 5px;
                background-color: $primary-pink;
                font-size: 14px;
                //   font-weight: bold;
                font-weight: 500;
                line-height: 1.36;
                letter-spacing: 0.14px;
                text-align: center;
                text-transform: capitalize;
                color: $white;
            }
        }
    }
}