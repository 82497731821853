.hover-wrap {
    button {
        background-color: transparent !important;

        &:hover {
            svg {
                fill: rgb(231, 10, 137);
            }
        }

        &:focus {
            background-color: transparent;
        }
    }

    button+div {
        z-index: 9;
    }

    .select-menu-wrap {
        width: 180px;
        right: 80px;
        position: relative;

        ul {
            border: solid 1px rgba(8, 77, 79, 0.1);

            li {
                svg {
                    margin-right: 12px;
                }
            }
        }
    }

    .select-history-menu-wrap {
        top: -15px;
        width: 125px;
        height: 85px;
        right: -80px;
        position: relative;
        z-index: 1000;

        ul {
            border: solid 1px rgba(8, 77, 79, 0.1);

            li {
                svg {
                    margin-right: 12px;
                }
            }
        }
    }

}