@import "../../../assets/scss/variables.scss";

.time-based-table {
    .filters {
        .arrow_outer {
            line-height: 1;
            transform: rotate(0deg);
            transition: 0.3s transform ease-in-out;
        }

        .list_open_new {
            .arrow_outer {
                transform: rotate(-180deg);
            }
        }

        .list_open {
            .arrow_outer {
                transform: rotate(-180deg);
            }
        }

        .icon {
            svg {
                height: 28px;
                width: 30px;
                margin-top: -3px;
            }
        }
    }

    .section-intro {

        // margin-bottom: 3px;
        &:after {
            content: "";
            display: block;
            clear: both;
        }

        h5 {
            float: left;
            font-weight: 100;
            font-size: 1.65rem;
        }

        button {
            float: right;
            border-radius: 5px;
            color: #fff;
            background-color: #e70a89;
            margin-top: 6px;
        }
    }

    .filters {
        .input-field {
            margin-top: 18px;

            &.active {
                ul {
                    display: block;
                }

                span.right-arrow {
                    display: none;
                }
            }

            span.right-arrow {
                position: absolute;
                right: 20px;
                top: 10px;
            }
        }

        .icon {
            color: #000;
        }

        button {
            border: 1px solid lightgray;
            margin-top: 15px;
        }
    }

    ul {
        span {
            font-size: 12px;
            //font-weight: 600;
            font-weight: 500;
        }

        li {
            cursor: pointer;
            margin: 7px 0;
        }
    }

    #tags {
        border: 0;
        box-shadow: none;
        border-radius: 50px;
        background: #fff;
        padding: 6px 40px;
        box-sizing: border-box;
        font-size: 15px;
        //font-weight: 600;
        font-weight: 500;
        color: darkgray;

        &:focus {
            box-shadow: 0 2px 3px rgba(0, 0, 0, 0.2);
            background: $white;
        }
    }

    #selectBox {
        border: 1px solid lightgray;
        border-radius: 5px;
        padding: 8px 31px 9px 12px;
        // box-shadow: 0 2px 3px rgba(0, 0, 0, 0.2);
        margin-top: 1rem;
        // margin-bottom: 1rem;
        height: 38px;
        box-sizing: border-box;
        font-size: 14px;
        min-width: 130px;

        &:focus {
            background-color: transparent;
        }

        svg {
            color: $black;
            right: 10px;
        }
    }

    .MuiPaper-root {
        @include flexbox;
        flex-wrap: wrap;
        align-items: flex-end;
        align-items: center;
        justify-content: space-between;
        border: solid 1px rgba(8, 77, 79, 0.1);
        margin: 0 0 10px 0;
    }

    .item-intro {
        &.errorState {
            input {
                border-bottom: solid 1px #db263b !important;
            }

            .MuiFormLabel-filled {
                opacity: 1;
            }
        }

        width: calc(100% - 317px);
        margin-right: 29px;

        h5 {
            color: #000;
            //font-weight: 500;
            font-weight: 300;
            margin-bottom: 5px;

            svg {
                margin-right: 10px;
                transform: translateY(4px);
            }
        }

        h6 {
            color: #084d4f;
            font-size: 12px;
            // font-weight: bold;
            font-weight: 500;
        }

        .MuiTextField-root {
            max-width: 579px;
            width: 70%;
        }

        input {
            font-size: 25px !important;
        }

        .MuiInput-underline:after {
            opacity: 0 !important;
        }

        .MuiInput-underline:before {
            opacity: 0 !important;
        }

        .MuiFormLabel-filled {
            opacity: 0;
            color: #db263b;
        }

        .info {
            .MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot {
                padding-right: 0;
            }

            .MuiPaper-root {
                box-shadow: none;
                border: none;
                justify-content: left;
                margin-bottom: 0;

                .MuiAutocomplete-root {
                    .MuiAutocomplete-endAdornment {
                        display: none;
                    }

                    input {
                        box-shadow: none;
                        margin: 0;
                        height: 14px !important;
                        min-height: auto;
                        line-height: 1;
                        font-size: 12px !important;
                        text-align: center;
                        border-radius: 30px;
                        padding: 5px 10px !important;
                        background-color: rgba(8, 77, 79, .12);
                        border: 1px solid rgba(8, 77, 79, .15) !important;
                        width: auto;
                        max-width: 50px;
                        color: #000;
                        background: transparent;
                    }

                    .MuiTextField-root {
                        max-width: 100%;
                        width: 100%;
                    }
                }

                li {
                    .MuiChip-root {
                        max-height: 25px;
                        background-color: rgba(8, 77, 79, .10196078431372549);
                        margin-right: 5px;
                        color: #000;

                        svg {
                            fill: rgba(8, 77, 79, .22745098039215686);
                            height: 18px;

                            &:hover {
                                fill: currentColor;
                            }
                        }
                    }
                }
            }
        }
    }

    .item-option {
        // width: 288px;
        position: relative;
        // text-align: right;

        h6 {
            display: inline-block;
            vertical-align: middle;
        }

        .featured-icon {
            display: inline-block;
            vertical-align: middle;
            margin-left: 24px;

            svg {
                height: 25px;
                width: 25px;
            }

            .featuredIcon {
                display: none;
            }

            .unfeaturedIcon {
                display: block;
            }

            .featured {
                .featuredIcon {
                    display: block;
                }

                .unfeaturedIcon {
                    display: none;
                }
            }
        }

        button {
            display: inline-block;
            vertical-align: middle;
            padding: 5px 8px;
        }

        &.activeOption {
            .optionsList {
                display: block;
            }
        }

        .text-wrapper {
            display: inline-block;
            vertical-align: middle;

            span {
                display: block;
                text-align: right;
                color: #000000;
            }

            h6 {
                color: #000000;
                font-weight: 300;
            }
        }
    }

    .info {
        h6 {
            display: inline-block;
            position: relative;
            margin-right: 20px;
            background-color: rgba(8, 65, 79, 0.1);
            border-radius: 50px;
            padding: 3px 11px 5px;
            margin-top: 7px;
            color: #000;
        }

        span {
            &.material-icons {
                font-size: 14px;
                background-color: rgba(8, 65, 79, 0.2);
                border-radius: 50%;
                vertical-align: middle;
                margin-left: 6px;
            }
        }
    }

    .optionsList {
        display: none;
        position: absolute;
        top: 50px;
        right: 0;
        width: 250px;
        background-color: $white;
        z-index: 9;
        box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14);

        .MuiListItemIcon-root {
            min-width: 35px;
        }
    }

    .sortPopup {
        display: none;
        position: absolute;
        top: 70px;
        right: 0;
        width: 150px;
        background-color: $white;
        z-index: 9;
        box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14);

        .MuiListItemIcon-root {
            min-width: 35px;
        }
    }

    .activeSort {
        .sortPopup {
            display: block;
        }
    }
}