.time-based-summary {
  background: #F3F3F3;
  padding-left: 20px;

  .container {
    max-width: 1330px;
    padding: 0 10px;

    .top-content {
      width: 100% !important;
      margin-bottom: 0;

      span {
        display: inline-block;
      }
    }
  }

  .directives {
    float: right;

    a {
      position: relative;
      color: gray;
    }
  }

  .row-wrap-counting {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;

    .row {
      margin-bottom: 0;
    }
  }

  .tags {
    .tag-name {
      color: #000;
      display: inline-block;
      margin-left: inherit;
      width: auto;
      min-height: auto;
      position: relative;
      padding-right: 10px;

      input {
        border: 0;
        box-shadow: none;
        margin: 0;
        min-height: auto;
        height: auto;
        min-height: 20px;
        font-size: 12px;
        text-align: center;
        border-radius: 30px;
        padding: 5px 10px;
        background: #BEBEC0;
        border: 1px solid #BEBEC0;
        width: auto;

        &:focus {
          border: 1px solid #BEBEC0 !important;
          box-shadow: none !important;
          border-bottom: 1px solid #BEBEC0;
          background: transparent;
        }
      }

      i.close {
        display: none;
        color: gray;

        &:hover {
          display: block;
          z-index: 9;
          cursor: pointer;
          position: absolute;
          top: 5px;
          right: 18px;
        }
      }

      ul {
        display: none;
        position: absolute;
        border: 0;
        box-shadow: none;
        background: #fff;
        width: 100%;
        left: 0;
        top: 35px;
        box-shadow: 0 -1px 10px rgba(0, 0, 0, 0.2);
        border-radius: 5px;
        z-index: 9;
        padding: 6px 0;

        li {
          padding: 0 5px;
          font-size: 12px;
          margin: 4px 0;

          &:hover {
            cursor: pointer;
            background-color: #BEBEC0;
          }
        }
      }

      input[type=text]:focus~ul {
        display: block;
      }

      input[type=text]:hover~i.close {
        display: block;
        position: absolute;
        top: 5px;
        right: 18px;
      }

    }


  }

  h5 {
    margin-bottom: 10px;
    padding-bottom: 8px;
    border-bottom: 1px dashed #000;
  }

  .content-wrap {
    margin-bottom: 20px;

    .company-name,
    .client-name {
      display: inline-block;
      position: relative;

      input {
        width: auto;
        border: 0;
        font-size: 14px;
      }

      input[type=text]:focus {
        border: 0;
        box-shadow: none;
      }
    }

    i {
      vertical-align: middle;
      padding-right: 10px;
    }
  }

  .no-suggestions {
    position: absolute;
    top: 45px;
  }

  .suggestions {
    z-index: 12;

    li {
      padding: 5px;
    }
  }

  .countings {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;

    div {
      margin-top: 10px;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      min-height: 100px;
      position: relative;
      text-align: center;
      width: 33.33%;

      &:after {
        content: "";
        position: absolute;
        right: 0;
        height: 30px;
        width: 1px;
        background: #000;
      }
    }

    span {
      font-size: 22px;
    }
  }

  .major-countings {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    align-items: flex-end;

    i {
      vertical-align: bottom;
    }

    div {
      margin-top: 10px;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      min-height: 80px;
      text-align: center;

      &:first-child {
        background-color: #BEBEBE;
        padding: 0 25px;
        width: auto;
        height: 100%;
        border-radius: 10px;
      }

      &:last-child {
        -ms-flex-preferred-size: 60%;
        flex-basis: 60%;
        // background: #1a1616;
        // padding: 0 20px;
        color: #fff;

        tr td:first-child {
          width: 50%;
          text-align: left;
        }

        tr td:last-child {
          width: 50%;
          text-align: right;
        }
      }

      .profit-drop-down {
        padding: 0;
        display: inline-block;
        min-height: auto;
        margin-top: 0;

        ul {
          display: none;
          position: absolute;
          top: 0;
        }
      }

      .apply-discount-wrap {
        background-color: #000;

        &.applied {
          border-radius: 8px;

          .apply-discount {
            display: none;
          }

          .applied-values {
            display: flex;
          }

          .final-discount {
            span {
              display: block;
            }
          }
        }

        .row {
          margin: 0;
          border: 0;
          padding: 0 12px;
        }

        .applied-values {
          display: none;
          position: relative;

          .drop-open {
            color: lightgray;
          }

          td {
            position: relative;

            &:after {
              content: '';
              position: absolute;
              bottom: 0;
              left: 0;
              background-color: #fff;
              height: 1px;
              width: 100%;
            }
          }

        }

        .final-discount {
          td {
            &:nth-child(2) {
              font-size: 30px;
            }
          }

          span {
            text-decoration: line-through;
            display: none;
            font-size: 22px;
          }
        }
      }
    }

    .apply-discount {
      color: #000;
      background-color: #F3F3F3;

      a {
        color: #000;
        border: 1px solid #000;
        display: inline-block;
        text-align: center;
        padding: 5px 18px;
        border-radius: 25px;

      }

      td {
        &:first-child {
          flex-basis: 20%;
          align-self: center;

        }

        &:last-child {
          flex-basis: 69%;

        }
      }

    }
  }

  .open {
    position: absolute;
    width: 220px;
    right: 0;
    top: 57%;
    background-color: #fff !important;
    padding: 15px !important;
    box-shadow: -1px 3px 12px 0px #000;
    display: none !important;
    text-align: left !important;
    z-index: 9;

    li {
      padding-bottom: 10px;
      color: #000 !important;
      cursor: pointer;

      i {
        vertical-align: middle;
      }
    }
  }

  .drop-open {
    position: relative;
    cursor: default;

    &:hover {
      .open {
        display: block !important;
      }
    }
  }
}

.summary-details {
  .tabcontainer {
    width: 100%;
  }

  .canvasjs-chart-credit {
    display: none !important;
  }

  .tabs-cover {
    padding: 0;
    margin-left: 0 !important;
    width: 100% !important;
  }

  .tabs {
    width: 100%;
  }

  .tab {
    width: auto;
  }

  tr {
    display: table-row;
  }

  .table {
    display: none;

    &:first-child {
      display: block;
    }
  }
}

.discount {
  position: fixed;
  display: none;
  background: #3131317d;
  width: 100%;
  height: 100%;
  z-index: 3;
  top: 0;

  .container {
    height: inherit;
  }

  .box {
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%);
    max-width: 600px;
    background: #fff;
    padding: 20px;
  }

  h3,
  h5 {
    margin-bottom: 20px;
    border: none;
  }

  h5 {
    display: inline-block;
  }

  ul {
    display: inline-block;

    li {
      display: inline-block;
      border: 1px solid gray;
      height: 26px;
      width: 28px;
      text-align: center;
      margin: 0 0 0 16px;
      border-radius: 4px;

      &.active {
        background: #000;
        color: #fff;
      }
    }
  }

  input {
    border: 1px solid #cecece !important;
    padding: 20px !important;
    box-sizing: border-box !important;
  }

  .cross {
    position: absolute;
    right: 20px;
    top: 20px;
    cursor: pointer;
  }

  button {
    background: #000;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    border-radius: 6px;
    border: 0;
    color: #fff;
    padding: 10px 15px;
    margin-top: 20px;
  }
}