@import "variables";

.resource-drawer {

  .resource_hourly_cost {
    .MuiInputAdornment-root {
      position: absolute;
      left: 4px;
    }

    .MuiOutlinedInput-root {
      padding: 0 22px !important;
    }

    label {
      font-weight: 300;
      padding-left: 12px;
      transform: translate(14px, 13px) scale(1);

      &.MuiInputLabel-shrink {
        padding-left: 0;
        transform: translate(14px, -6px) scale(.75) !important;

      }
    }
  }

  .percentage_num {
    .MuiInputBase-root {
      padding: 0 13px !important;
    }
  }

  .MuiFormControl-root {
    &.dollar_cost {
      .MuiInputAdornment-root {
        position: absolute;
        left: 4px;
      }

      .MuiOutlinedInput-root {
        padding: 0 22px !important;
      }

      label {
        padding-left: 12px;
        font-weight: 300;
        transform: translate(14px, 13px) scale(1);

        &.Mui-focused,
        &.MuiFormLabel-filled {
          padding-left: 0;
          transform: translate(14px, -6px) scale(.75) !important;
        }
      }

      .MuiInputBase-adornedStart {
        .PrivateNotchedOutline-legendNotched-69 {
          max-width: 0;
        }

        &.Mui-focused {
          .PrivateNotchedOutline-legendNotched-69 {
            max-width: 1000px;
          }

        }
      }


      // input{
      //     padding-left: 8px;
      // }
    }
  }
}

.manage-resources {
  .panel-list {
    button {
      background-color: transparent;

      .MuiTouchRipple-root {
        color: transparent;
      }

      &:hover {
        background-color: transparent;

        svg {
          fill: $primary-pink;
        }
      }

      &.user-btn-disabled {
        background-color: rgba(0, 0, 0, 0.12) !important;
        color: $white !important;
        pointer-events: none;
      }
    }
  }
}