@import "variables";

.panel-list {
    button {
        background-color: transparent;
    }

    .box-title {
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    button+div {
        z-index: 9 !important;
    }

    .select-menu-wrap {
        left: -40px;
        position: relative;
    }

    ul {
        li {
            padding: 6px 15px;
            font-size: 14px;
            line-height: 1.4;

            // svg {
            //     margin-right: 10px;
            // }
        }
    }
}

.manage-disciplines {
    .resource-input {
        &.error {
            input {
                border: solid 2px #ff0028 !important;
            }
        }
    }

    .panel-list {
        button {
            background-color: transparent;

            .MuiTouchRipple-root {
                color: transparent;
            }

            &:hover {
                background-color: transparent;

                svg {
                    fill: $primary-pink;
                }
            }
        }
    }
}

.filter-menu {
    .filter-wrap {
        form {
            .cross {
                //display: none;
                position: relative;
                right: 10px;
                cursor: pointer;
            }

        }
    }
}