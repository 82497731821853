@import "../scss/radioInput.scss";

.lightbox {
	display: flex;
	visibility: hidden;
	opacity: 0;
	justify-content: center;
	width: 350px;
	height: 100%;
	position: fixed;
	bottom: 0;
	right: 0;
	z-index: 20000;
	background-color: white;
	padding-top: 25px;
	transition: all 0.2s linear;

	&.active {
		visibility: visible;
		opacity: 1;
	}

	&-container {
		width: 290px;
	}

	&-title {
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding-bottom: 25px;

		&::after {
			content: "";
			display: block;
			top: 77px;
			left: 0;
			position: absolute;
			height: 1px;
			width: 100%;
			background-color: rgb(130, 165, 166);
			;
		}


		.title-text {
			font-size: 20px;
			line-height: 1.35;
			letter-spacing: normal;
			text-align: left;
			color: #000000;
		}

		.title-icon {
			display: flex;
			justify-content: center;
			align-items: center;
			position: relative;
			align-items: center;
			width: 20px;
			height: 20px;
			cursor: pointer;

			&::before,
			&::after {
				content: "";
				position: absolute;
				background: rgb(130, 165, 166);
			}

			&::after {
				height: 2px;
				width: 30px;
				transform: rotate(45deg);
			}

			&::before {
				width: 2px;
				height: 30px;
				transform: rotate(45deg);
			}
		}
	}

	&-time-config {
		padding-top: 30px;
		padding-bottom: 30px;

		.time_work,
		.time_total {
			display: flex;
			align-items: center;

			&:last-child {
				margin-top: 10px;
			}

			&-time {
				margin-left: 10px;
				width: 45px;
				height: 40px;
				text-align: center;
				font-size: 14px;
				font-weight: 900;
				padding: 0;
				border-radius: 5px;
				border: solid 1px rgba(8, 77, 79, 0.1);
			}

			&-title {
				margin: 0;
				margin-left: 10px;
				font-size: 14px;
				font-weight: bolder;
				width: 90px;
			}

		}

		.time_work {
			.time_work-title {
				position: relative;
			}

			.title-period {
				font-weight: bold;
				text-decoration: underline;
				text-decoration-thickness: 2px;
				cursor: pointer;
			}

			.menu-period {
				display: flex;
				visibility: hidden;
				opacity: 0;
				position: absolute;
				flex-direction: column;
				margin-top: 6px;
				left: 0px;
				background-color: white;
				border-radius: 5px;
				border: 1px solid rgba(8, 77, 79, 0.1);
				box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
				z-index: 50;
				transition: all 0.1s linear;

				span {
					padding-left: 20px;
					padding-right: 20px;
					padding-top: 12px;
					padding-bottom: 12px;
					cursor: pointer;
					text-transform: capitalize;
					color: black;

					&.active {
						background-color: rgba(8, 77, 79, 0.05);

						&:hover {
							background-color: rgba(8, 77, 79, 0.11);
						}
					}

					&:hover {
						background-color: rgba(8, 77, 79, 0.11);
					}
				}

				&.active {
					visibility: visible;
					opacity: 1;
				}
			}
		}
	}

	&-dates {
		margin-top: 30px;

		.dates-start,
		.dates-end {
			display: flex;
			flex-direction: column;

			.start-title,
			.end-title {
				font-size: 12px;
				font-weight: bold;
				text-transform: uppercase;
				color: #084d4f;
			}

			.start-date,
			.end-date {
				font-size: 20px;
				margin-top: 10px;
				margin-bottom: 20px;
			}

		}
	}

	.lightbox-buttons {
		display: flex;
		align-items: center;
		margin-top: 10px;

		button {
			position: relative;
			overflow: hidden;
			font-size: 14px;
			font-weight: bold;
			border: none;
			cursor: pointer;

			&:first-child {
				width: 100px;
				height: 40px;
				border-radius: 5px;
				background-color: #e70a89;
				color: #ffffff;
			}

			&:last-child {
				background: none;
				height: 20px;
				margin-left: 20px;
				padding: 0;
				color: #000000;

			}
		}

	}

	input[type="number"] {
		// outline: none;
		border: 1px solid rgba(8, 77, 79, 0.1);
		border-radius: 5px;

		&::-webkit-outer-spin-button,
		&::-webkit-inner-spin-button {
			-webkit-appearance: none;
			margin: 0;
		}

		&:active,
		&:hover {
			box-shadow: none;
		}

		&:focus {
			box-shadow: none !important;
			border: 1px solid rgba(8, 77, 79, 0.1) !important;
		}

		&.error-validation {
			border-color: red;
		}

		&.disable {
			background-color: #9ea7a70c;
			pointer-events: none;
			color: rgba(158, 167, 167, 1.05);
		}
	}


}

.gantt_message_area {
	z-index: 99999;
}

.gantt-success-message div {
	font-weight: bold;
	color: wheat;
	background-color: rgb(20, 220, 103);
	z-index: 20000;
}

.gantt-error-message div {
	font-weight: bold;
	color: white;
	background-color: rgba(220, 20, 60, 0.836);
}

.dhx_calendar.dhx_widget {
	padding: 0;
}

.dhx_calendar-day {
	font-family: NotoSans;
	font-size: 14px;
	font-weight: 600;
	margin-bottom: 15px;
}

.dhx_calendar-day--weekend {
	color: #2ab7ca;
}

.dhx_calendar-action__show-month {
	font-size: 14px;
	font-weight: 500;
}

.dhx_button--circle {
	color: #777474;
}

.dhx_calendar-day--selected {
	color: white;
}

.dhx_calendar-day--selected::before {
	width: 38px;
	height: 38px;
}

.dhx_calendar-month--selected::before,
.dhx_calendar-year--selected::before,
.dhx_calendar-day--selected::before {
	background-color: #2ab7ca;
}

.dhx_calendar-day--in-range:after {
	background-color: #2ab7ca28;
}

.dhx_calendar__weekdays,
.dhx_calendar__navigation {
	margin-bottom: 15px;
}

.dhx_calendar-weekday {
	font-size: 14px;
	font-weight: 500;
}

.dhx_button--view_link.dhx_button--color_primary {
	color: black;

	&:hover {
		background-color: #2ab7ca28;
	}
}