@import "variables";

.summary {
  background-color: #fff;
  padding: 27px 30px 15px 34px;

  .container {
    @media screen and (min-width: 1501px) {
      max-width: 100% !important;
      padding: 0 10px;
    }
  }

  .estimate-chips {
    display: flex;
    justify-content: space-between;

    .estimate-drops {
      width: 49%;

      &.full-width {
        width: 100%;
      }

      input {
        font-size: 20px !important;
        top: 4px !important;
      }
    }
  }



  .discount-item {
    ul {
      position: absolute;
      background: #fff;
      width: 205px;
      border-radius: 5px;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
      border: solid 1px rgba(8, 77, 79, 0.1);
      //padding: 7px 0;
      top: 100%;
      right: 0;
      animation: scale-in-top 0.1s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
      z-index: 9;

      li {
        font-size: 14px;
        cursor: pointer;
        vertical-align: middle;
        padding: 9px 15px;
        line-height: 1.36;
        //font-weight: 400;
        font-weight: 300;
        background-color: transparent;
        transition: all 0.4s ease;
        color: #000;

        &:hover {
          background-color: rgba(8, 77, 79, 0.05);

          svg {
            fill: #e70a8b;
          }
        }

        svg,
        span {
          display: inline-block;
          vertical-align: middle;
          margin-right: 10px;
          color: #000;
        }

        span {
          font-size: 19px;
          margin-left: 4px;
          color: #074d4f;
        }
      }
    }

    &:hover {
      .profit-dots {
        display: block;
        cursor: pointer;
      }
    }

    &.active {
      .profit-dots {
        display: block;
        cursor: pointer;
        fill: #e70a8b;
      }
    }

    .profit-dots {
      &:hover {
        fill: #e70a8b;
      }
    }

    span {
      font-size: 12px;
      //   font-weight: bold;
      font-weight: 500;
      line-height: 1.42;
      letter-spacing: 0.6px;
      text-align: left;
      color: #283b3b;
      position: relative;
      display: block;
    }

    .discount-added {
      right: 23px;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      font-size: 20px;
      // font-weight: 600;
      font-weight: 500;
      color: #000000;
    }

    .profit-dots {
      display: none;
      position: absolute;
      right: 2px;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  .tag-row {
    .l8 {
      margin-left: 0 !important;

      >div {
        display: inline-block;
        vertical-align: middle;
      }
    }

    .col.l4 {
      max-width: 448px;
      margin-top: 12px;
      padding-right: 0;

      @media screen and (max-width: 1200px) {
        max-width: 300px;
      }
    }
  }

  .top-content {
    width: 100% !important;
    margin: 0;
    padding: 0;

    span {
      display: inline-block;
      font-size: 12px;
      font-weight: 500;
      // font-weight: bold;
      line-height: 1.42;
      letter-spacing: 0.6px;
      text-align: left;
      color: $primary-teal;
      text-transform: uppercase;
      margin-bottom: 0;
      z-index: 99;

      h6 {
        display: inline-block;
        font-size: 12px;
        // font-weight: bold;
        font-weight: 500;
        line-height: 1.42;
        letter-spacing: 0.6px;
        text-align: left;
        color: $primary-teal;
        text-transform: uppercase;
        margin-bottom: 0;
        z-index: 99;
      }
    }

    .top-content {
      padding: 0;
    }

    .violator-ribbon {
      display: inline-block;
      width: 100%;
      height: 22px;
      max-width: 204px;
      padding: 2px 24px 3px 35px;
      background-color: #2ab7ca;
      position: relative;
      left: 0;
      display: none;

      &::after {
        content: "";
        width: 22px;
        height: 22px;
        background: $white;
        position: absolute;
        top: 0;
        right: -11px;
        transform: rotate(45deg);
      }

      h6 {
        font-size: 12px;
        // font-weight: bold;
        font-weight: 500;
        line-height: 1.42;
        letter-spacing: 0.6px;
        color: $white;
      }
    }
  }

  .directives {
    float: right;
    margin-right: -10px;

    a {
      position: relative;
      margin-left: 22px;
      display: inline-block;
    }

    .new-featured {
      top: -4px;
      padding: 0;
      vertical-align: top;
    }

    .setting-wrap {
      padding: 0;
      vertical-align: top;
    }

    .featured {
      height: 25px;
      width: 21px;
    }

    svg {
      height: 25px;
      width: 23px;

      &:hover {
        fill: rgb(231, 10, 137);
      }

      &.mirror-reply {
        transform: scaleX(-1);
      }
    }

    .drawer_menu_button {
      top: -9px;
      right: -9px;
      position: relative;
      margin-left: 5px;
      padding: 0;

      button {
        min-width: 42px;

        &:hover {
          background-color: transparent;
        }

        &:focus {
          background-color: transparent;
        }
      }

      svg {
        fill: #000;

        &:hover {
          fill: rgb(231, 10, 137);
        }
      }

      span {
        width: auto;
      }

      .select-menu-wrap {
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
        border: solid 1px rgba(8, 77, 79, 0.1);
        min-width: 200px;
        left: -85px;
        position: relative;

        ul {
          padding: 0;

          li {
            font-size: 14px;
            padding: 10px 18px;
            text-transform: capitalize;

            svg {
              height: 25px;
              width: 25px;
              margin-right: 15px;

              &.mirror_filter {
                transform: scale3d(1, -1, 1);
              }
            }

            img {
              margin-right: 15px;
              margin-left: 2px;
              height: 24px;
              width: 22px;

              &:first-child {
                display: block;
              }

              &.template_pink {
                display: none;
              }
            }

            &:hover {
              img {
                &:first-child {
                  display: none;
                }

                &.template_pink {
                  display: block;
                }
              }
            }
          }
        }
      }
    }

    span.setting-wrap {
      margin-left: 32px;
      margin-right: -2px;
      cursor: pointer;
      margin-bottom: 4px;

      img {
        &:first-child {
          display: block;
        }

        &:last-child {
          display: none;
        }
      }

      &:hover {
        img {
          &:first-child {
            display: none;
          }

          &:last-child {
            display: block;
          }
        }
      }
    }
  }

  .main-title-wrap {
    >div {
      max-width: 552px;
      width: 70%;
    }

    &.errorState {
      fieldset {
        opacity: 1 !important;
        border-color: #db263b !important;
      }

      label {
        &.MuiFormLabel-filled {
          opacity: 1 !important;
          color: #db263b;
        }
      }
    }

    margin-bottom: 2px;
    margin-left: -10px;
    margin-top: -15px;

    input[type="text"] {
      margin: 0 !important;
      text-overflow: ellipsis;
      padding: 2px 10px !important;
      border-bottom: 0 !important;
      box-shadow: none !important;
      min-width: 418px;
      height: 40px;
      font-size: 25px;
      // font-weight: 500;
      font-weight: 300;
      letter-spacing: 0.62px;

      &:focus {
        outline: 0 !important;
        border: 0 !important;
      }

      &:hover {
        text-overflow: inherit;
      }
    }

    fieldset {
      outline: 0;
      border-color: #e5eded !important;
      border-width: 1px !important;
      opacity: 0;
      transition: all 0.2s linear;
    }

    label {
      line-height: 0.5;
      opacity: 0;
      transition: all 0.2s linear;

      &.Mui-focused {
        line-height: 1;
        color: $primary-teal;
        opacity: 0.5;
      }

      &.MuiFormLabel-filled {
        line-height: 1;
        color: $primary-teal;
        opacity: 0;
      }
    }

    .MuiTextField-root {
      &:hover {
        fieldset {
          opacity: 1;
        }

        label {
          opacity: 1;

          &.MuiFormLabel-filled {
            opacity: 0.5;
          }
        }
      }

      .Mui-focused {
        fieldset {
          opacity: 1;
        }
      }

      label {
        &.Mui-focused {
          opacity: 0.5;
        }
      }
    }
  }

  .row-wrap-counting {
    display: flex;
    align-items: flex-end;
    flex-wrap: wrap;
    margin-bottom: 0;
    padding: 0 4px;

    .row {
      margin-bottom: 0 !important;

      &.tag-row {
        margin-bottom: 10px !important;
        margin-right: 10px;
      }
    }

    div[relative=true] {
      transform: none !important;
      margin-right: 10px;
    }

    .profit-margin {
      ul {
        position: absolute;
        background: #fff;
        width: 200px;
        border-radius: 6px;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
        border: solid 1px rgba(8, 77, 79, 0.1);
        padding: 10px 0;
        top: 82px;
        right: 34px;
        z-index: 99;
        animation: scale-in-top 0.1s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;

        li {
          font-size: 14px;
          vertical-align: middle;
          padding: 5px 10px;
          cursor: pointer;

          &:hover {
            background-color: rgba(8, 77, 79, 0.05);

            svg {
              stroke: transparent;
              fill: $primary-pink;
            }

            span {
              color: $primary-pink;
            }
          }

          svg {
            display: inline-block;
            vertical-align: middle;
            margin: 0 6px 0 5px;
          }

          span {
            padding: 0 8px;
          }
        }
      }

      .profit-dots {
        display: none;
        position: absolute;
        right: 17px;
        top: 55px;

        &:hover {
          fill: $primary-pink;
        }
      }

      &:hover,
      &.active {
        .profit-dots {
          cursor: pointer;
          display: block;
        }
      }

      &.active {
        .profit-dots {
          fill: $primary-pink;
        }

        li {
          &.active {
            background-color: rgba(8, 77, 79, 0.05);

            svg {
              stroke: transparent;
              fill: $primary-pink;
            }

            span {
              color: $primary-pink;
            }
          }
        }
      }
    }

    .tag-name {
      border: 0;
      position: absolute;
      padding-top: 12px;

      @media screen and (min-width: 1180px) and (max-width: 1360px) {
        max-width: calc(100% - 700px);
      }

      @media screen and (min-width: 1151px) and (max-width: 1180px) {
        max-width: calc(100% - 630px);
      }

      @media screen and (max-width: 1150px) {
        display: block;
        padding: 10px 0;
      }

      .MuiChip-root {
        max-height: 25px;
        background-color: #084d4f1a;
        margin-right: 5px;
        color: #000;

        @include desktop-large-down {
          max-width: 100px;
        }

        svg {
          fill: #084d4f3a;
          height: 18px;

          &:hover {
            fill: currentColor;
          }
        }
      }

      ul {
        @media screen and (min-width: 1150px) and (max-width: 1240px) {
          padding: 0;
        }

        li {
          &.errorState {
            .MuiChip-root {
              border: solid 1px #db263b;
              //  font-weight: 600;
              font-weight: 500;
              //background: transparent;
            }
          }

          @media screen and (min-width: 1151px) and (max-width: 1200px) {
            margin-top: 6px !important;
          }

          @media screen and (max-width: 1150px) {
            margin-top: 0 !important;
          }
        }

        .MuiAutocomplete-hasPopupIcon {
          @media screen and (min-width: 1100px) and (max-width: 1200px) {
            margin-top: 10px;
            width: auto !important;
          }
        }
      }

      label {
        display: none;
        color: #000;
      }

      .MuiInput-underline:before {
        display: none;
      }

      .MuiAutocomplete-clearIndicator {
        display: none;
      }

      .MuiAutocomplete-popupIndicator {
        display: none;
      }

      .MuiInput-root {
        padding-right: 0;
        margin-top: -5px;
        margin-left: 5px;
      }

      .MuiFormControl-root {
        &:hover {
          .MuiInput-underline {
            &:after {
              transform: scaleX(0);
            }
          }
        }
      }

      .MuiAutocomplete-listbox li button {
        border: 0 !important;
        font-size: 11px !important;

        svg {
          font-size: 15px;
        }

        .MuiButton-startIcon {
          vertical-align: bottom;
        }
      }

      .MuiInput-underline {
        border: 0;
        box-shadow: none;
      }

      .MuiInput-underline.Mui-focused:after {
        transform: scaleX(0) !important;
      }

      .MuiAutocomplete-root .MuiInput-underline:after {
        border: 0 !important;
        border-color: gray !important;
      }

      .MuiAutocomplete-option[data-focus="true"] {
        background: transparent;
      }

      input {
        box-shadow: none;
        margin: 0;
        height: 14px;
        min-height: auto;
        line-height: 1;
        font-size: 12px;
        text-align: center;
        border-radius: 30px;
        padding: 5px 10px !important;
        background-color: rgba(8, 77, 79, 0.12);
        border: 1px solid rgba(8, 77, 79, 0.15);
        width: auto;
        max-width: 50px;
        color: #000;
        background: transparent;

        &:focus {
          border: 1px solid #bebec0 !important;
          box-shadow: none !important;
          background: transparent;
        }
      }

      input::placeholder {
        color: #000;
        opacity: 1;
        // font-weight: 500;
        font-weight: 300;
      }

      &.error {
        input {
          border-color: red !important;
        }
      }

      .error {
        input {
          border-color: red !important;
        }
      }

      ul {
        border: 0;
        box-shadow: none;
        justify-content: flex-start;

        li {
          margin-top: -6px;
          // padding-top: 4px;
        }

        .MuiFormControl-root {
          top: 0 !important;
        }

        .MuiInput-root {
          padding-right: 0;
          margin-top: -2px;
          margin-left: 4px;
        }
      }
    }
  }

  .tags {
    .tag-name {
      color: #000;
      display: inline-block;
      margin-left: inherit;
      width: auto;
      min-height: auto;
      position: relative;
      padding-right: 10px;

      button {
        span {
          text-transform: capitalize;
        }
      }

      input {
        border: 0;
        box-shadow: none;
        margin: 0;
        min-height: auto;
        height: auto;
        min-height: 20px;
        font-size: 12px;
        text-align: center;
        border-radius: 30px;
        padding: 5px 10px;
        background: #bebec0;
        border: 1px solid #bebec0;
        width: auto;

        &:focus {
          border: 1px solid #bebec0 !important;
          box-shadow: none !important;
          border-bottom: 1px solid #bebec0;
          background: transparent;
        }
      }

      input::placeholder {
        color: #000;
      }

      i.close {
        display: none;
        color: gray;

        &:hover {
          display: block;
          z-index: 9;
          cursor: pointer;
          position: absolute;
          top: 5px;
          right: 18px;
        }
      }

      ul {
        display: none;
        position: absolute;
        border: 0;
        box-shadow: none;
        background: #fff;
        width: 100%;
        left: 0;
        top: 35px;
        box-shadow: 0 -1px 10px rgba(0, 0, 0, 0.2);
        border-radius: 5px;
        z-index: 9;
        padding: 6px 0;

        li {
          padding: 0 5px;
          font-size: 12px;
          margin: 4px 0;

          &:hover {
            cursor: pointer;
            background-color: #bebec0;
          }
        }
      }

      input[type="text"]:focus~ul {
        display: block;
      }

      input[type="text"]:hover~i.close {
        display: block;
        position: absolute;
        top: 5px;
        right: 18px;
      }

      .MuiButton-label {
        font-size: 11px;
      }
    }
  }

  h5 {
    margin-bottom: 10px;
    padding-bottom: 8px;
    font-size: 25px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.36;
    letter-spacing: normal;
    text-align: left;
    color: #000000;
  }

  .content-wrap {
    margin-bottom: 20px;

    .company-name,
    .client-name {
      display: inline-block;
      position: relative;

      input {
        width: auto;
        border: 0;
        font-size: 14px;
      }

      input[type="text"]:focus {
        border: 0;
        box-shadow: none;
      }

      .MuiFormControl-root {
        max-width: 240px;

        .MuiInput-underline:before {
          border-bottom: 0 !important;
          box-shadow: none;
        }

        .MuiInput-underline:hover:not(.Mui-disabled):before {
          border-bottom: 0 !important;
          box-shadow: none;
        }

        .MuiAutocomplete-root .MuiInput-underline:after {
          border-bottom: 0 !important;
          box-shadow: none;
        }
      }
    }

    .tag-name {
      margin-top: -5px;
    }
  }

  .no-suggestions {
    position: absolute;
    top: 45px;
  }

  .suggestions {
    z-index: 12;

    li {
      padding: 5px;
    }
  }

  .tag-row {
    margin-bottom: 10px !important;

    .company-name,
    .client-name {
      margin-right: 15px;
      margin-top: 8px;
      max-height: 60px;

      @media screen and (min-width: 1150px) and (max-width: 1220px) {
        margin-right: 0;
      }

      label {
        padding-left: 27px;
        line-height: 0;

        &.MuiInputLabelShrink {
          display: none;
        }

        &.Mui-focused {
          display: none;
        }

        &.MuiFormLabel-filled {
          display: none;
        }
      }

      fieldset {
        outline: 0;
        border: 0;
      }

      input {
        padding-left: 27px;
        max-width: 230px;
        font-size: 14px;
        //font-weight: 600;
        font-weight: 500;
        box-shadow: none;
        border: 0;
        height: 35px;
        margin: 0;
        padding: 0;
        padding-bottom: 0 !important;
        padding-left: 25px !important;

        &:focus {
          box-shadow: none !important;
          border-bottom: 0 !important;
        }
      }

      svg {
        margin-top: 3px;
        position: absolute;
        left: 0;
        top: 4px;
        height: 20px;
        width: 20px;
      }

      label {
        margin-right: 10px;
      }

      .MuiAutocomplete-root {
        max-width: 230px;
      }

      .MuiAutocomplete-endAdornment {
        .MuiAutocomplete-clearIndicator {
          display: none;
        }
      }

      .MuiFormControl-root {
        max-width: 230px;

        .MuiInput-underline:before {
          border-bottom: 0 !important;
          box-shadow: none;
        }

        .MuiInput-underline:hover:not(.Mui-disabled):before {
          border-bottom: 0 !important;
          box-shadow: none;
        }

        .MuiAutocomplete-root .MuiInput-underline:after {
          border-bottom: 0 !important;
          box-shadow: none;
        }

        .MuiAutocomplete-inputRoot {
          padding-right: 0;
          margin-top: 0;
        }
      }

      .inner-title-wrap {
        position: relative;

        svg.company_icon {
          position: absolute;
          left: 0;
          top: 10px;
        }

        svg.client_icon {
          position: absolute;
          left: 0;
          top: 10px;
        }
      }
    }

    .tag-name {
      input {
        &:hover {
          box-shadow: none;
        }
      }
    }
  }

  .total {
    max-width: 448px;
    padding-right: 0;

    @media screen and (max-width: 1200px) {
      max-width: 300px;
      width: 31% !important;
    }

    .estimate-window {
      padding: 14px 22px 14px 20px;
      border-radius: 5px;
      background-color: #000000;
      display: flex;
      justify-content: center;
      width: 100%;
      flex-wrap: wrap;
      min-height: 80px;

      &::after {
        content: "";
        display: block;
        clear: both;
      }

      .target-budget {
        width: 40%;
        display: flex;
        flex-direction: column;

        h6 {
          margin-bottom: 16px;
        }

        .target-hover {

          .target-bar {
            border-radius: 3px;
            background-color: #fff;
            width: 100px;
            height: 5px;

            span {
              height: 5px;
              border-radius: 3px;
              display: block;
            }

            margin-top: 5px;
          }

          p {
            color: white;
            margin: 0;
            font-size: 14px;
          }
        }

      }


      .estimate-total {
        width: 60%;
      }
    }

    h6 {
      font-size: 12px;
      // font-weight: bold;
      font-weight: 500;
      line-height: 1.42;
      letter-spacing: 0.6px;
      text-align: left;
      color: $white;
      float: left;
      max-width: 100px;
      margin-right: auto;
    }

    h2 {
      font-size: 50px;
      //  font-weight: 600;
      font-weight: 500;
      line-height: 1;
      text-align: right;
      color: $white;

      // float: right;
      @media screen and (max-width: 1200px) {
        font-size: 28px;
      }
    }

    h5 {
      font-size: 14px;
      //  font-weight: bold;
      font-weight: 500;
      line-height: 1.42;
      letter-spacing: 0.6px;
      text-align: right;
      color: $white;
      margin: 10px 0 0 0;
      padding: 0;
      width: 100%;
      display: none;

      strike {
        display: block;
        min-height: 19px;
      }
    }

    &.discountAdded {
      .estimate--window {
        padding: 10px 22px 10px 20px;
      }

      h2 {
        line-height: 0.8;
      }

      h5 {
        display: block;
        line-height: 1;
      }
    }
  }

  .countings {
    z-index: 2;
    display: flex;
    max-width: 896px;
    margin-left: 0 !important;
    margin-right: auto;
    margin-top: 4px;

    @media screen and (max-width: 1200px) {
      width: 68% !important;
    }

    div {
      display: flex;
      flex-direction: column;
      justify-content: center;
      min-height: 100px;
      position: relative;
      text-align: left;
      width: 18%;
      padding: 0 20px;
      min-width: 166px;

      &:first-child {
        padding-left: 0;
      }

      @media screen and (max-width: 1440px) {
        min-width: 144px;
      }

      @media screen and (max-width: 1199px) {
        min-width: auto;
        padding: 0 12px;
        min-width: 125px;
      }

      &:after {
        content: "";
        position: absolute;
        right: 0;
        height: 56px;
        width: 1px;
        background: rgba(8, 77, 79, 0.2);
      }

      &:last-child:after {
        display: none;
      }
    }

    span {
      font-size: 19px;
      //font-weight: bold;
      font-weight: 500;
      margin-top: 15px;

      @include desktop {
        font-size: 16px;
      }
    }

    h6 {
      font-size: 12px;
      color: $primary-teal;
      // font-weight: bold;
      text-transform: uppercase;

      .dropdown {
        vertical-align: bottom;
        cursor: pointer;
      }
    }

    .calender-wrap {
      display: none;
      //display: flex;
      position: absolute;
      top: 62px;
      left: 0;
      background: #fff;
      border-radius: 5px;
      box-shadow: 0px 2px 1px -1px rgb(0, 0, 0.2);
      z-index: 9;
      width: 100%;
      padding: 0 12px;
      min-width: 190px;

      >div {
        width: 100%;
        min-height: auto;

        h6 {
          font-size: 14px;
        }

        &:after {
          display: none;
        }

        &.inner-wrap {
          display: block;
          text-align: left;

          span {
            float: left;
            width: 20%;
          }

          h6 {
            float: left;
            width: 60%;
            font-size: 14px;
          }

          .doneicon {
            float: right;
            width: 20%;
          }
        }

        &.duration-span {
          display: block;
          text-align: left;

          .time-span {
            float: left;
            width: 20%;

            svg {
              transform: rotate(90deg);
            }
          }

          h6 {
            font-size: 14px;
          }
        }

        &.durations {
          display: block;
          margin-bottom: 15px;
          border: 1px solid lightgray;
          border-radius: 5px;

          span {
            font-size: 14px;
            padding: 0 6px;
          }
        }
      }

      &:after {
        display: none;
      }
    }

    .date-wrap {
      padding: 0 14px 0 2px;
      width: 19%;
      position: relative;

      &:hover {
        .open-dots {
          opacity: 1;
        }
      }

      .open-dots {
        cursor: pointer;
        position: absolute;
        right: 0;
        top: 53px;
        margin-top: 0px;
        opacity: 0;
        transition: opacity 0.3s ease-in-out;
      }

      span {
        @media screen and (max-width: 1440px) and (min-width: 1200px) {
          font-size: 16px;
        }

        @media screen and (max-width: 1200px) {
          font-size: 14px;
        }
      }
    }

    .menu-item {
      position: absolute;
      width: 336px;
      background-color: white;
      top: 82%;
      left: 32px;
      border-radius: 5px;
      padding: 0;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
      justify-content: flex-start;

      .active {
        background-color: rgba(8, 77, 79, 0.1);
      }

      div {
        display: block;
        width: 100%;
        min-height: auto;
        padding: 12px 16px;
        font-size: 0;
      }

      .lower-grid {
        padding-top: 6px;
        padding-bottom: 21px;

        .icon-outer {
          transform: rotate(90deg);
        }
      }

      span {
        display: inline-block;
        vertical-align: middle;
        margin: 0;
      }

      .icon-outer {
        width: 20px;
        padding-top: 0;
        line-height: 0.3;
        height: auto;
        min-height: auto;

        svg {
          width: 1.1em;
          height: 1.1em;
        }
      }

      .text-box {
        font-size: 14px;
        font-weight: 300;
        width: calc(100% - 20px);
        padding-left: 13px;
      }
    }

    .nav-outer {
      padding: 0;
      border: solid 1px #000000;
      border-radius: 5px;
      margin-top: 7px;
      background-color: white;
      min-height: auto;
      height: auto;
      line-height: 1;
      text-align: left;
      box-shadow: none;

      .MuiListItemText-root {
        padding: 0;
        margin: 0;
        min-width: auto;
      }

      li {
        display: inline-block;
        vertical-align: top;
        padding: 4px;
        width: 33.33%;
        border-right: 1px solid #000000;
        text-align: center;
        cursor: pointer;

        &.active {
          span {
            color: rgb(231, 10, 137);
          }

          &:after {
            display: none;
          }
        }

        &:last-child {
          border-right: 0;
        }
      }

      span {
        font-size: 14px;
        color: #000000;
        font-weight: 100;
        width: 100%;
        text-align: center;
      }
    }

    .open-dots {
      cursor: pointer;
      position: absolute;
      right: 0;
      top: 56px;
      margin-top: 0px;
      opacity: 0;
      transition: opacity 0.3s ease-in-out;
    }
  }

  .major-countings {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    align-items: flex-end;

    i {
      vertical-align: bottom;
    }

    div {
      margin-top: 10px;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      min-height: 80px;
      text-align: center;

      &:first-child {
        background-color: #bebebe;
        padding: 0 25px;
        width: auto;
        height: 100%;
        border-radius: 10px;
      }

      &:last-child {
        -ms-flex-preferred-size: 60%;
        flex-basis: 60%;
        // background: #1a1616;
        // padding: 0 20px;
        color: #fff;

        tr td:first-child {
          width: 50%;
          text-align: left;
        }

        tr td:last-child {
          width: 50%;
          text-align: right;
        }
      }

      .profit-drop-down {
        padding: 0;
        display: inline-block;
        min-height: auto;
        margin-top: 0;

        ul {
          display: none;
          position: absolute;
          top: 0;
        }
      }

      .apply-discount-wrap {
        background-color: #000;

        &.applied {
          border-radius: 8px;

          .apply-discount {
            display: none;
          }

          .applied-values {
            display: flex;
          }

          .final-discount {
            span {
              display: block;
            }
          }
        }

        .row {
          margin: 0;
          border: 0;
          padding: 0 12px;
        }

        .applied-values {
          display: none;
          position: relative;

          .drop-open {
            color: lightgray;
          }

          td {
            position: relative;

            &:after {
              content: "";
              position: absolute;
              bottom: 0;
              left: 0;
              background-color: #fff;
              height: 1px;
              width: 100%;
            }
          }
        }

        .final-discount {
          td {
            &:nth-child(2) {
              font-size: 30px;
            }
          }

          span {
            text-decoration: line-through;
            display: none;
            font-size: 22px;
          }
        }
      }
    }

    .apply-discount {
      color: #000;
      background-color: #f3f3f3;

      a {
        color: #000;
        border: 1px solid #000;
        display: inline-block;
        text-align: center;
        padding: 5px 18px;
        border-radius: 25px;
      }

      td {
        &:first-child {
          flex-basis: 20%;
          align-self: center;
        }

        &:last-child {
          flex-basis: 69%;
        }
      }
    }
  }

  .open {
    position: absolute;
    width: 220px;
    right: 0;
    top: 70%;
    background-color: #fff !important;
    padding: 15px 0 !important;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
    border: solid 1px rgba(8, 77, 79, 0.1);
    display: none !important;
    text-align: left !important;
    z-index: 9;

    li {
      padding-bottom: 10px;
      color: #000 !important;
      padding: 9px 15px;
      cursor: pointer;

      &:hover {
        background: rgba(8, 77, 79, 0.1);
      }

      i {
        vertical-align: middle;
      }
    }
  }

  .drop-open {
    position: relative;
    cursor: default;

    &:hover {
      .open {
        display: block !important;
      }

      i.drawer-tab {
        color: $primary-pink;
      }
    }
  }

  .calcSticky {
    top: 77px !important;
    padding: 18px 20px 0;
    background-color: #fff;
    z-index: 991;
    box-shadow: -4px 20px 22px -14px rgba(0, 0, 0, .2);
  }
}

.scope-table {
  .add-hours-btn {
    margin-bottom: 13px;
  }
}

.summary-details {
  &.template-modify {
    border: 3px solid #2ab7ca;
    border: 0;
    position: relative;
    background-color: rgba(8, 77, 79, 0.001);

    &:before {
      content: "";
      position: absolute;
      background-image: url("../../assets/images/background-new.svg");
      background-size: contain;
      background-repeat: no-repeat;
      bottom: 210px;
      right: -85%;
      height: 100%;
      max-height: 280px;
      width: 100%;
      z-index: -1;
    }

    &:after {
      content: "";
      position: absolute;
      background-image: url("../../assets/images/background-new-small.svg");
      background-size: contain;
      background-repeat: no-repeat;
      bottom: 110px;
      right: -85%;
      height: 100%;
      max-height: 100px;
      width: 100%;
      z-index: -1;
    }

    .scope-wrap,
    .summary-wrap,
    .notes-wrap {
      display: none;

      &.active {
        display: block;
      }
    }
  }

  .chart-wrap {
    .empty {
      .apexcharts-datalabels {
        text {
          fill: #000000;
        }
      }
    }
  }

  [data-rbd-drag-handle-context-id="0"],
  .phase-parent {
    cursor: default;
    scroll-margin: 77px;
  }

  .sidenavsSticky {
    top: 227px !important;

    .small-col {
      margin-left: 0;
    }
  }

  .violator-bar {
    width: 100%;
    padding: 10px 0;
    background-color: #2ab7ca;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;

    // display: none; //just for Review code
    h5 {
      font-size: 20px;
      font-weight: normal;
      line-height: 1.35;
      letter-spacing: normal;
      color: $white;
    }

    .btn-wrap {
      margin-left: 20px;

      button {
        min-width: 144px;
        padding: 5px 15px 6px;
        border-radius: 5px;
        background-color: $white;

        .MuiButton-label {
          font-size: 14px;
          //font-weight: bold;
          font-weight: 500;
          line-height: 1.36;
          letter-spacing: 0.14px;
          text-align: center;
          text-transform: capitalize;
          color: #2ab7ca;
        }
      }
    }
  }

  .container {
    //padding: 0 30px;
    transition: all 0.2s linear;
    padding: 0 35px;
    max-width: 1920px !important;

    @media screen and (min-width: 1501px) {
      max-width: 100% !important;
      padding: 0 35px;
    }

    @media screen and (min-width: 1850px) {
      max-width: 100% !important;
      //padding: 0 10px;
      padding: 0 35px;
    }

    position: relative;

    &:before {
      content: "";
      position: absolute;
      background-image: url("../../assets/images/background-new.svg");
      background-size: contain;
      background-repeat: no-repeat;
      bottom: 310px;
      left: -80px;
      height: 100%;
      max-height: 180px;
      width: 100%;
      z-index: -1;
    }

    .calendar-header {
      .MuiFormControl-root {
        margin-left: 4px;
      }
    }
  }

  .editorClassName {
    background-color: $white;
  }

  .viewModeStyle ul li {
    list-style-type: disc;
  }

  // .col-inner{
  //   z-index: 5;
  // }
  .tabcontainer {
    width: 100%;
  }

  .canvasjs-chart-credit {
    display: none !important;
  }

  .tabs-cover {
    padding: 0;
    margin-left: 0 !important;
    width: 100% !important;
  }

  .tabs {
    width: 100%;
  }

  .tab {
    width: auto;
  }

  tr {
    display: table-row;
  }

  .table {
    display: none;
    z-index: 1;
    position: relative;

    &:first-child {
      display: block;
    }
  }

  .scope-time {
    margin-left: 0;
    border-top: 3px solid #2ab7ca;
    background-color: rgba(8, 77, 79, 0.001);

    .week {
      max-width: 150px;
    }

    .row {
      .col {

        // width: calc(75% - 20px);

      }

      .cost-estimates {
        background: #f2f2f2;
        padding: 25px;

        .col {
          width: auto;

          span {
            font-size: 20px;
            //font-weight: 500;
            font-weight: 300;
          }
        }
      }
    }
  }

  .MuiTabs-scroller {
    .MuiButtonBase-root {
      span {
        //  font-weight: 600;
        font-weight: 500;
        text-transform: capitalize;
        font-size: 14px;
      }
    }
  }

  .scope-inner {
    display: block;

    &.openTask {
      .task-outer {
        display: none;
      }

      .col-inner {
        border-bottom: 0;
      }
    }

    // .col-inner{
    //   .input-field{
    //     .MuiInputBase-root{
    //       padding-bottom: 10px;
    //     }
    //   }
    // }
  }

  .content-outer {
    ul {
      display: none;
      animation: scale-in-top 0.1s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;

      li {
        cursor: pointer;

        svg {
          &.mirror_filter {
            transform: scale3d(1, -1, 1);
            height: 22px;
          }
        }
      }
    }

    &.active {
      ul {
        display: inherit;
      }
    }
  }

  #simple-tab-0 {
    background-color: rgba(0, 0, 0, 0.1);

    &:hover {
      color: $primary-pink;

      span.MuiTab-wrapper {
        color: $primary-pink;
      }
    }

    &.MuiTab-textColorInherit.Mui-selected span.MuiTab-wrapper {
      color: $primary-pink;
    }
  }

  #simple-tab-1 {
    &:hover {
      color: $primary-pink;

      span.MuiTab-wrapper {
        color: $primary-pink;
      }
    }

    &.MuiTab-textColorInherit.Mui-selected span.MuiTab-wrapper {
      color: $primary-pink;
    }
  }

  #simple-tab-2 {
    &:hover {
      color: $primary-pink;

      span.MuiTab-wrapper {
        color: $primary-pink;
      }
    }

    &.MuiTab-textColorInherit.Mui-selected span.MuiTab-wrapper {
      color: $primary-pink;
    }
  }

  #simple-tabpanel-0 {
    position: relative;
    padding-bottom: 400px;

    &:after {
      content: "";
      position: absolute;
      bottom: -30px;
      left: 0;
      background-image: url("../images/WE20054_Edison_Buildings.svg");
      background-repeat: no-repeat;
      background-position: 38% 100%;
      min-height: calc(100vh - 435px);
      background-size: 1920px;
      height: 100%;
      width: 100%;
      z-index: -1;
    }
  }

  #simple-tabpanel-1 {
    padding-bottom: 40px;
  }

  #simple-tabpanel-2 {
    position: relative;
    padding-bottom: 400px;

    &:after {
      content: "";
      position: absolute;
      bottom: -30px;
      left: 0;
      background-image: url("../images/WE20054_Edison_Buildings.svg");
      background-repeat: no-repeat;
      background-position: 38% 100%;
      min-height: calc(100vh - 435px);
      background-size: 1920px;
      height: 100%;
      width: 100%;
      z-index: -1;
    }

    .rdw-editor-wrapper {
      border: solid 1px rgba(8, 77, 79, 0.1);

      .rdw-link-decorator-wrapper {
        >a {
          color: $primary-teal !important;
          text-decoration: underline !important;
        }
      }

      .rdw-link-modal {
        box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.12);
      }

      .rdw-image-modal {
        box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.12);
      }

      .rdw-link-modal-label {
        text-transform: uppercase;
        color: $primary-teal;
        font-size: 12px;
        //  font-weight: bold;
        font-weight: 500;
      }

      .rdw-image-modal-btn-section {
        margin: 10px 0;
      }

      .rdw-link-modal-btn {
        border: 1px solid #000;
        color: #000;

        &:hover {
          box-shadow: none;
        }
      }

      .rdw-link-modal-buttonsection {
        margin: 10px 0;

        button {
          &:first-child {
            background-color: $primary-pink;
            color: #fff;
            border: 1px solid $primary-pink;
            margin-left: 0;
          }
        }
      }

      .rdw-image-modal-btn-section {
        margin: 10px 0;

        button {
          &:first-child {
            background-color: $primary-pink;
            color: #fff;
            border: 1px solid $primary-pink;
            margin-left: 0;
          }
        }
      }

      .rdw-image-wrapper {
        .rdw-image-modal-header-option {
          text-transform: uppercase;
          color: $primary-teal;
          font-size: 12px;
          // font-weight: bold;
          font-weight: 500;
          width: auto;
          flex-direction: inherit;
        }

        .rdw-image-modal-header-label-highlighted {
          border: none;
          color: $primary-teal;
          font-size: 12px;
          // font-weight: bold;
          font-weight: 500;
        }

        .rdw-image-modal-size {
          align-items: baseline;
        }

        .rdw-image-modal-btn-section {
          margin: 10px 0;
        }

        .rdw-image-modal-btn {
          border: 1px solid #000;
          color: #000;

          &:hover {
            box-shadow: none;
          }
        }
      }

      .rdw-link-modal-target-option {
        span {
          margin-left: 0;
          color: #000;
        }

        [type="checkbox"]:not(:checked),
        [type="checkbox"]:checked {
          opacity: 1;
          height: 25px;
          width: 22px;
        }

        [type="checkbox"]+span:not(.lever):before,
        [type="checkbox"]:not(.filled-in)+span:not(.lever):after {
          opacity: 0;
        }

        [type="checkbox"]:not(.filled-in)+span:not(.lever):after {
          opacity: 0;
        }
      }

      .rdw-link-decorator-icon {
        position: relative;
        left: 3px;
        top: 2px;
      }
    }
  }

  .phase-parent.new-item {
    animation: scale-up-ver-top 0.25s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  }
}

.discount {
  position: fixed;
  display: none;
  background: #3131317d;
  width: 100%;
  height: 100%;
  z-index: 3;
  top: 0;

  .container {
    height: inherit;
  }

  .box {
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%);
    max-width: 600px;
    background: #fff;
    padding: 20px;
  }

  h3,
  h5 {
    margin-bottom: 20px;
    border: none;
  }

  h5 {
    display: inline-block;
  }

  ul {
    display: inline-block;

    li {
      display: inline-block;
      border: 1px solid gray;
      height: 26px;
      width: 28px;
      text-align: center;
      margin: 0 0 0 16px;
      border-radius: 4px;

      &.active {
        background: #000;
        color: #fff;
      }
    }
  }

  input {
    border: 1px solid #cecece !important;
    padding: 20px !important;
    box-sizing: border-box !important;
  }

  .cross {
    position: absolute;
    right: 20px;
    top: 20px;
    cursor: pointer;
  }

  button {
    background: #000;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    border-radius: 6px;
    border: 0;
    color: #fff;
    padding: 10px 15px;
    margin-top: 20px;
  }
}

.form-wrap {
  .img-wrap {
    text-align: center;
  }

  #email,
  #password {
    width: 100%;
    margin: 0;
    padding: 5px 15px;
  }

  .form {
    max-width: 450px;
    margin-top: 25px;

    div {
      width: 100%;
      margin: 0 0 7px;
    }

    span {
      float: right;
    }

    fieldset {
      border: solid 1px rgba(8, 77, 79, 0.1);
    }
  }
}

@-webkit-keyframes scale-in-top {
  0% {
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
    -webkit-transform-origin: 100% 0%;
    transform-origin: 100% 0%;
    opacity: 1;
  }

  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: 100% 0%;
    transform-origin: 100% 0%;
    opacity: 1;
  }
}

@keyframes scale-in-top {
  0% {
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
    -webkit-transform-origin: 100% 0%;
    transform-origin: 100% 0%;
    opacity: 1;
  }

  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: 100% 0%;
    transform-origin: 100% 0%;
    opacity: 1;
  }
}

.drawer-open {
  .summary-details {
    .container {
      padding: 0 35px;
    }
  }

  .summary .countings {
    flex-wrap: wrap;
  }

  .estimated-list .scope-tab .small-grid {
    display: none;
  }

  .row .col.s10 {
    width: 100%;
  }

  .summary .tag-row .col.l4 {
    @media screen and (max-width: 1200px) {
      max-width: 274px;
    }
  }

  .summary .row-wrap-counting .tag-name {
    @media screen and (min-width: 1180px) and (max-width: 1360px) {
      max-width: calc(100% - 570px);
    }
  }
}

.estimated-list {
  padding-bottom: 0;

  .scope-tab {
    .large-grid {
      transition: width 0.2s ease-in-out;

      @media screen and (max-width: 1300px) {
        width: 100%;
      }
    }

    .small-grid {
      transition: width 0.2s ease-in-out;

      .close-icon {
        display: inline-block;
      }

      .open-icon {
        display: none;
        width: 18px;
        height: 12px;
      }
    }
  }

  .scope-tab.hide-content {
    .large-grid {
      width: 93%;
    }

    .small-grid {
      width: 7%;

      .close-icon {
        display: none;
      }

      .open-icon {
        display: inline-block;
        text-align: center;
        margin-right: 0;
      }

      .inner-title {
        border-bottom: 0;
        text-align: center;
      }
    }
  }
}


.estimated-list.share-estimate {
  margin-left: 0;

  .summary {
    .top-content {
      margin-bottom: 6px;
    }

    .main-title-wrap,
    .company-name,
    .client-name {
      pointer-events: none;
    }
  }

  .summary-details {



    .scope-tab {
      .card {
        .task-outer {
          .row {
            &::after {
              content: none;
            }
          }
        }
      }
    }

    .content-wrapper {
      .expenses-wrap {
        .expenses-card {
          .col-outer {
            .input-cost {
              pointer-events: none;

              .counting-expense-qty {
                .expense-qty {
                  left: -4%;

                  input {
                    background-color: transparent;
                    text-align: right;
                  }
                }
              }
            }

          }
        }
      }

    }

    .MuiAutocomplete-root {
      pointer-events: none;
    }

    .phase-parent {
      pointer-events: none;
    }

    .counting-phase {
      .task_qty {
        background: transparent !important;
        margin-right: 41px !important;

        input {
          text-align: right !important;
        }
      }
    }


    .resource {
      .one-line-elment {
        .resource-wrap {
          .list {
            pointer-events: none;
          }

          .qty {
            input {
              background-color: transparent;
              margin-left: 0 !important;
              position: relative;
              text-align: right;
              left: 8%;
              top: 2px;
            }
          }

          .qty.hideFinance {
            input {
              left: 18%;
            }
          }

          .hrs {
            input {
              border: none !important;
              // margin-left: 0 !important;
              margin-right: 0 !important;
              right: 0px !important;
            }

            &::before {
              top: 20px;
              left: 18%;
            }
          }

          .subtotal {
            input {
              margin-left: 0 !important;
            }
          }
        }
      }
    }


  }

}

@-webkit-keyframes scale-up-ver-top {
  0% {
    -webkit-transform: scaleY(0.6);
    transform: scaleY(0.6);
    -webkit-transform-origin: 100% 0%;
    transform-origin: 100% 0%;
  }

  80% {
    -webkit-transform: scaleY(0.9);
    transform: scaleY(0.9);
    -webkit-transform-origin: 100% 0%;
    transform-origin: 100% 0%;
  }

  100% {
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
    -webkit-transform-origin: 100% 0%;
    transform-origin: 100% 0%;
  }
}

@keyframes scale-up-ver-top {
  0% {
    -webkit-transform: scaleY(0.6);
    transform: scaleY(0.6);
    -webkit-transform-origin: 100% 0%;
    transform-origin: 100% 0%;
  }

  80% {
    -webkit-transform: scaleY(0.9);
    transform: scaleY(0.9);
    -webkit-transform-origin: 100% 0%;
    transform-origin: 100% 0%;
  }

  100% {
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
    -webkit-transform-origin: 100% 0%;
    transform-origin: 100% 0%;
  }
}