.page-content {
    padding: 80px;

    ul {
        padding-left: 40px;

        li {
            list-style: disc;

            a {
                display: inline-block;
            }
        }
    }

}