@import "../../../assets/scss/variables.scss";

#signup-form {
  padding: 20px;
  max-width: 600px;
  margin: 0 auto;

  .btn-row {
    text-align: center;
  }
}

.sign-up-wrap {
  width: auto;

  .form-wrap {
    height: calc(100vh - 60px);
    display: flex;
    padding: 50px 0;
    align-items: center;
  }

  .inner-wrap {
    width: 100%;

    .large-block {
      border: solid 1px rgba(8, 77, 79, 0.2);
      padding: 59px 50px !important;
      border-radius: 5px;
      background-color: #fff;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;

      .img-wrap {
        width: 100%;
        max-width: 200px;
      }
    }

    .small_block {
      border: solid 1px rgba(8, 77, 79, 0.2);
      display: flex;
      padding: 55px 50px 30px !important;
      flex-wrap: wrap;
      max-width: 430px px;
      align-items: stretch;
      border-left: 0;
      border-radius: 5px;
      background-color: #fff;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;

      .btn_wrap {
        color: #fff;
        width: 100%;
        background: #e70a89 !important;
        box-shadow: none;
        min-height: 40px;
        font-weight: bold;
        text-transform: inherit;
        margin-top: 4px;
      }
    }
  }
}