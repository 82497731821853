@import "variables";

/*
 * Buttons.
 */
// Edit default button style here
%btn-common {
  padding: 10px 28px 10px 29px;
  margin-top: 8px;
  font-weight: 300;
  font-size: 16px;
  font-family: $font-family;
  text-align: center;
  display: inline-block;
  position: relative;
  outline: none;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  letter-spacing: 0.001em;

  @include tablet-down {
    padding: 10px 20px;
  }
}

// Extend all buttons with default button styles here
.btn-default,
.btn-transparent,
.btn-white {
  @extend %btn-common;
}

.btn-default {
  background-color: $crusoe;
  border: 2px solid $crusoe;
  color: $white;

  &:hover {
    background-color: transparent;
    color: $crusoe;
  }
}

.btn-transparent {
  background-color: transparent;
  border: 2px solid $crusoe;
  color: $crusoe;

  &:hover {
    background-color: $crusoe;
    color: #001d2e;
  }
}

.btn-white {
  background-color: transparent;
  border: 2px solid $white;
  color: $white;

  &:hover {
    background-color: $white;
    color: $crusoe;
  }
}



.link-blue {
  position: relative;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.88;
  letter-spacing: normal;
  text-align: left;
  color: $crusoe;
  padding-right: 15px;
  display: inline-block;
  font-size: 16px;
  margin-top: 20px;
  transition: all 0.3s ease;

  &:after {
    content: "";
    position: absolute;
    right: 0;
    width: 2px;
    height: 2px;
    color: $crusoe;
    top: 0;
    bottom: 0;
    margin: auto;
    border: solid $crusoe;
    border-width: 0px 2px 2px 0px;
    padding: 3px;
    transform: rotate(-45deg);
    transition: all 0.3s ease;
  }

  &:hover {
    text-decoration: none;
    color: $crusoe;

    &:after {
      right: -5px;
    }
  }
}

.link-green {
  color: $crusoe;
  position: relative;
  display: inline-block;
  font-size: 16px;
  line-height: 1.56;
  letter-spacing: 2.7px;
  text-transform: uppercase;
  margin-top: 14px;
  padding-right: 31px;

  @include container-down {
    margin: 0 0 15px 0;
  }

  &:before {
    display: none;
  }

  &:after {
    content: "";
    position: absolute;
    right: 18px;
    width: 9px;
    height: 9px;
    color: $crusoe;
    top: 0;
    bottom: 0;
    margin: auto;
    border: solid $crusoe;
    border-width: 0px 2px 2px 0px;
    padding: 2px;
    transform: rotate(-45deg);
    transition: right 0.3s ease;
  }

  span {
    color: $crusoe  !important;
  }

  &:hover {
    text-decoration: none;
    color: $crusoe;

    &:after {
      right: 13px;
    }
  }
}

.link-back {
  color: $crusoe;
  font-size: 16px;
  position: relative;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.88;
  letter-spacing: normal;
  text-align: left;
  display: inline-block;

  &::before {
    content: "";
    position: absolute;
    color: $crusoe;
    background: none;
    border: solid $crusoe;
    border-width: 0px 2px 2px 0px;
    transform: rotate(135deg);
    padding: 3px;
    width: 2px;
    height: 2px;
    top: 11px;
    left: 0px;
    margin: auto;
    transition: left 0.4s ease-in-out;
  }

  &:hover {
    text-decoration: none;
    color: $crusoe;

    &::before {
      left: -4px;
    }
  }
}