@import "variables";

.overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  margin-left: 0;
  background-color: rgba(0, 0, 0, 0.6);
  visibility: hidden;
  opacity: 0;
  z-index: -1;
  transition: all 0.3s ease-in-out;
  transition-delay: 0.3s;

  &.activeOverlay {
    visibility: visible;
    opacity: 1;
    z-index: 99999;
    transition-delay: 0s;

    .outerWrapper {
      min-height: 604px;
      animation: scale-in-center 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
    }
  }

  .outerWrapper {
    width: 100%;
    height: 100%;
    background-color: $white;
    border-radius: 5px;
    animation: scale-out-center 0.3s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
  }

  .container {
    position: relative;
    top: 50%;
    transform: translateY(-50%);

    @media screen and (max-width: 1240px) {
      width: calc(100% - 80px) !important;
    }

    @include desktop-down {
      width: calc(100% - 40px) !important;
    }

    @include tablet-down {
      height: calc(100vh - 80px);
      overflow: scroll;
    }
  }

  input {
    border-bottom: 0 !important;
    box-shadow: none !important;
    box-sizing: border-box !important;
    padding: 18.5px 14px !important;
    outline: 0;

    &.MuiFilledInput-input {
      padding: 27px 12px 10px !important;
    }
  }

  .MuiStepper-root {
    max-width: calc(85% - 10px);
    margin: 0 auto;
    padding: 70px 0;

    @include tablet-down {
      max-width: 100%;
      padding-bottom: 30px;
    }

    .MuiStepLabel-alternativeLabel {
      font-weight: 400;
      font-size: 14px;
      letter-spacing: 0.29px;
      color: rgba(61, 122, 123, 0.57);

      @include tablet-down {
        font-size: 11px;
      }

      &.MuiStepLabel-completed {
        color: #000;
        opacity: 1;
      }

      &.MuiStepLabel-active {
        color: #000;
        opacity: 1;
      }
    }
  }

  .MuiStepConnector-line {
    border-color: $primary-teal;
    opacity: 0.5;
  }

  .MuiFormControl-root {
    padding: 0;
    width: 100%;
  }

  .MuiButton-contained.Mui-disabled {
    color: $white;
  }

  svg {
    text {
      opacity: 0;
    }
  }
}

@-webkit-keyframes scale-in-center {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
    opacity: 1;
  }

  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes scale-in-center {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
    opacity: 1;
  }

  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
}

@-webkit-keyframes scale-out-center {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }

  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
    opacity: 1;
  }
}

@keyframes scale-out-center {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }

  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
    opacity: 1;
  }
}

.createNew {
  padding-bottom: 80px;
  max-width: 1060px;

  @include tablet-down {
    width: 100% !important;
    padding-bottom: 20px;
  }

  .col.deactivated {
    opacity: 0.6;
    pointer-events: none;
  }

  .create-estimates {
    &.disable {
      pointer-events: none;
      cursor: not-allowed;
      text-decoration: none;
      background-color: rgba(0, 0, 0, 0.12) !important;
    }
  }

  .cross {
    position: absolute;
    right: 30px;
    top: 30px;
    cursor: pointer;

    @include tablet-down {
      right: 12px;
      top: 10px;
    }

    svg {
      height: 36px;
      width: 36px;
      opacity: 0.45;
      transition: all 0.2s linear;

      @include tablet-down {
        height: 28px;
        width: 28px;
      }

      &:hover {
        opacity: 0.8;
      }
    }
  }

  .bg-img {
    position: relative;
    height: 100%;
    width: 100%;
    margin: 0 auto;
    max-width: 300px;

    @include tablet {
      max-width: 150px;
    }

    @include tablet-down {
      max-width: 120px;
    }
  }

  h5 {
    margin: 20px 0;
  }

  h1 {
    letter-spacing: normal;

    @include desktop-up {
      font-size: 40px;
      font-weight: 400;
    }
  }

  p {
    margin-bottom: 25px;
    font-weight: 300;
    opacity: 0.7;
  }

  a {
    color: #000;

    &.linkstyle {
      min-height: 40px;
      // font-weight: 600;
      font-weight: 500;
      line-height: 1.5;

      &:hover {
        box-shadow: inset 0 -40px 10px -2px rgba(0, 0, 0, 0.1);
        background-color: $primary-pink;

        h6 {
          color: $primary-pink;
        }
      }
    }

    .card {
      height: 134px;
      margin-top: 0;
      padding: 20px 24px 17px 20px;
      border-radius: 5px;
      border: solid 1px rgba(8, 77, 79, 0.1);
      background-color: $white;
      transition: background-color 0.5s ease;
      box-shadow: none;

      @include desktop-mid {
        height: 150px;
      }

      @include tablet {
        height: 150px;
        padding: 10px;
      }

      @include tablet-down {
        height: 150px;
        padding: 10px;
      }

      @include phablet-down {
        height: 110px;
      }

      p {
        text-align: left;
        font-size: 12px;
        color: $primary-teal;
      }

      h6 {
        font-size: 20px;
        //  font-weight: bold;
        font-weight: 500;

        @include desktop-mid {
          font-size: 16px;
        }

        @include desktop-down {
          font-size: 16px;
        }
      }

      &:hover {
        background-color: rgba(8, 77, 79, 0.05);

        h6 {
          color: $primary-pink;
        }
      }
    }
  }

  .MuiButtonBase-root {
    span {
      text-transform: initial;
      //font-weight: 600;
      font-weight: 500;
    }
  }

  .MuiFormControl-root {
    label {
      font-size: 25px;
      text-transform: capitalize;
      // font-weight: 500;
      font-weight: 300;
      line-height: 0.8;

      &.MuiInputLabel-shrink {
        font-size: 15px;
        color: #000;
        opacity: 1;
        font-weight: 500;
        // font-weight: 600;
        font-family: "Nota" !important;
        line-height: 1;
      }

      &.MuiFormLabel-filled {
        ~div {
          fieldset {
            border-color: #000 !important;
            border-width: 2px !important;
          }
        }
      }
    }

    input {
      font-size: 25px;
      font-weight: 300;
      color: #000;
      border: 0;
      outline: 0;
      min-height: 63px;
      font-weight: 300;
      margin-bottom: 0;
      padding-top: 15px !important;

      &:focus {
        outline: 0 !important;
        border: 0 !important;
      }
    }

    fieldset {
      outline: 0;
      border-width: 2px;
      transition: all 0.2s ease-in;
    }

    .Mui-focused {
      fieldset {
        border-color: #000 !important;
        border-width: 2px !important;
      }
    }
  }

  .MuiGrid-grid-xs-8 {
    padding-left: 40px !important;

    @include tablet-down {
      padding-left: 0 !important;
    }
  }

  .col {
    &.l5 {
      margin-left: 0;
    }
  }

  .createproject {
    padding-left: 0 !important;

    .MuiGrid-root {
      padding-left: 0;
    }

    svg {
      margin-top: 8px;
      font-size: 28px;
    }

    label {
      font-size: 18px;
      // font-weight: 500;
      padding-left: 35px;
      font-family: "nota" !important;

      &.MuiInputLabel-shrink {
        padding-left: 0;
      }

      &.MuiFormLabel-filled.Mui-focused {
        display: block;
      }

      &.MuiFormLabel-filled {
        display: none;
      }
    }

    input {
      font-size: 18px;
      //font-weight: 500;
      font-weight: 300;
      border: 0;
      padding: 7px 34px 18px !important;
      margin-bottom: 0;
      font-family: "nota" !important;
      min-height: auto;
    }

    fieldset {
      border: 0;
    }

    .MuiInput-underline:before {
      display: none;
    }

    .MuiAutocomplete-clearIndicator,
    .MuiAutocomplete-popupIndicator {
      display: none;
    }
  }

  .MuiGrid-root.MuiGrid-item {
    &.MuiGrid-grid-xs-4 {
      @include desktop-down {
        max-width: 100%;
        flex-basis: 100%;
      }
    }

    &.MuiGrid-grid-xs-8 {
      @include desktop-down {
        max-width: 100%;
        flex-basis: 100%;
      }
    }
  }

  .subtitle {
    //font-weight: 500;
    font-weight: 300;
    // font-family: "Roboto", "Helvetica", "Arial", sans-serif !important;
  }

  .MuiGrid-grid-xs-6 {
    @include phablet-down {
      max-width: 100%;
      flex-basis: 100%;
    }
  }

  .back_btn {
    &:hover {
      background-color: #000 !important;
      color: $white !important;
    }
  }

  .errorState {
    label {
      &.MuiFormLabel-filled {
        color: #db263b;

        ~div {
          fieldset {
            border-color: #db263b !important;
            border-width: 1px !important;
          }
        }
      }
    }

    .MuiFormControl-root .Mui-focused fieldset {
      border: solid 1px #db263b !important;
    }

    .MuiOutlinedInput-notchedOutline {
      border: solid 1px #db263b !important;
    }

    .MuiFormControl-root label.MuiInputLabel-shrink {
      color: #db263b !important;
    }
  }
}

.create-two {
  a {
    color: #000;
  }

  .back-btn {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  i {
    vertical-align: bottom;
    padding-left: 10px;
  }

  h5 {
    text-align: center;
    margin: 20px 0;
  }

  p {
    text-align: center;
    margin-bottom: 20px;
    font-size: 12px;
  }

  select {
    display: block;
    border: 0;
    border-bottom: 1px solid #9e9e9e;
    color: #9e9e9e;
    font-size: 1rem;
  }

  .cross {
    position: absolute;
    right: 30px;
    top: 30px;
    cursor: pointer;
  }

  input[type="text"],
  input[type="search"] {
    padding-left: 28px;
    box-sizing: border-box;

    &:hover~label,
    &:focus~label {
      top: 0 !important;
    }
  }

  input[type="text"]:focus~ul {
    display: block;
  }

  ul:visited,
  ul:active,
  ul:focus-within,
  ul:focus-visible {
    display: block;
  }

  .input-field {
    &.active {
      ul {
        display: block;
      }

      span.right-arrow {
        display: none;
      }
    }

    span.right-arrow {
      position: absolute;
      right: 20px;
      top: 10px;
    }
  }

  #estimates-nmae {
    padding-left: 0;
    border: 1px solid lightgray;
    border-radius: 5px;
    padding: 7px 15px;

    &:focus {
      border: 1px solid lightgray;
      box-shadow: 0 2px 3px rgba(0, 0, 0, 0.2);
      background: $white;
    }
  }

  #client,
  #clientname {
    border: 1px solid lightgray;
    border-radius: 5px;
    padding: 6px 40px;
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.2);

    &:focus {
      border: 1px solid lightgray;
      box-shadow: 0 2px 3px rgba(0, 0, 0, 0.2);
      background: $white;
    }
  }

  ul {
    background: $white;
    border-radius: 5px;
    padding: 8px 10px 0;
    position: absolute;
    width: calc(100% - 22px);
    top: 41px;
    border-top: 0;
    border: 1px solid lightgray;
    border-top: 0;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.2);

    span {
      font-size: 12px;
      //  font-weight: 600;
      font-weight: 500;
    }

    li {
      cursor: pointer;
      margin: 7px 0;
    }
  }

  .button {
    text-align: center;
    margin-top: 20px;

    a {
      background: #ababb6;
      border-radius: 4px;
      border: 0;
      padding: 12px 16px;
      color: $white;
      cursor: pointer;
      font-size: 16px;
    }
  }

  .input-field>input[type]:-webkit-autofill:not(.browser-default)+label,
  .input-field>input[type="date"]:not(.browser-default)+label,
  .input-field>input[type="time"]:not(.browser-default)+label {
    transform: translateY(9px) scale(1);
  }
}

.estimates-settings {
  position: fixed;
  top: 0;
  height: 100vh;
  background: rgba(0, 0, 0, 0.3);
  width: 100%;
  display: none;
  overflow: hidden;
  z-index: 999999;

  .container {
    max-width: 996px !important;
    height: inherit;

    >div {
      display: none;
      position: relative;
      background: $white;
      padding: 20px 20px 25px;
      top: 50%;
      left: 50%;
      width: 350px;
      transform: translate(-50%, -50%);
    }
  }

  .ml70 {
    position: relative;
    top: 9px;
    margin-left: 19px;
  }

  .card {
    padding: 10px 15px;

    h6 {
      font-size: 14px;
    }

    .row {
      display: flex;
      align-items: center;

      .col {
        padding: 0 10px;
      }
    }

    .input-field {
      margin-bottom: 5px;

      input {
        // border: 1px solid lightgray;
        padding: 0 5px;
      }
    }

    p {
      font-size: 10px;
    }
  }

  .btn {
    margin-top: 20px;
  }

  &#delete,
  &#duplicate,
  &#setting,
  &#SaveTemplate,
  &#share {
    max-width: 500px;
    margin: auto;
    padding: 0;

    .title {
      padding: 15px 22px;
    }

    .input-wrap {
      padding: 15px 25px;

      button.apply {
        border: 1px solid $primary-pink;
        margin-right: 10px;
        margin-left: 0;
      }
    }

    .content {
      padding: 15px;

      p {
        padding: 15px 10px 0;
        margin-top: 0;
      }
    }
  }
}

@-webkit-keyframes fadeInRight {
  0% {
    opacity: 0;
    -webkit-transform: translateX(200px);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
  }
}

@keyframes fadeInRight {
  0% {
    opacity: 0;
    transform: translateX(200px);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}