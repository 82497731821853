.MuiAutocomplete-root {
  padding: 0;

  .MuiInput-underline {
    &:after {
      border-color: #000 !important;
    }
  }

  .MuiFormControl-root {
    padding: 0;

    &:hover {
      .MuiInput-underline {
        &:after {
          transform: scaleX(1);
        }
      }
    }
  }

  &.Mui-focused {
    .MuiInput-underline {
      &:hover {
        box-shadow: none !important;
      }
    }
  }
}

.MuiAutocomplete-paper {
  margin: 4px 0 0 0 !important;

  .MuiAutocomplete-option[data-focus="true"] {
    background-color: transparent !important;

    &:hover {
      background-color: rgba(0, 0, 0, 0.06) !important;
    }
  }
}

.MuiAutocomplete-listbox {
  padding: 0 !important;
  border: solid 1px rgba(8, 77, 79, 0.1);

  li {
    text-align: left;
    display: block;
    border: 0;
    position: relative;

    .MuiButton-root {
      border: 0 !important;

      &:hover {
        border: 0;
        background-color: transparent !important;
      }
    }

    .add-button {
      border: 0 !important;
      display: block;
      position: relative;
      padding: 5px 15px;

      svg {
        display: inline-block;
        vertical-align: middle;
        height: 20px;
        width: 20px;
        position: absolute;
        left: -9px;
        top: 50%;
        transform: translateY(-50%);
      }

      color: #3066be;
      font-size: 14px;
      //font-weight: 600;
      font-weight: 500;
      line-height: 1.36;
    }

    .add-people {
      padding: 5px 15px 5px 0;
    }

    // &:last-child {
    //   &:after {
    //     content: '';
    //     position: absolute;
    //     background: lightgray;
    //     height: 1px;
    //     width: 100%;
    //     top: 0;
    //     left: 0;
    //   }
    // }

    &:first-child {
      &:after {
        display: none;
      }
    }
  }

  .MuiAutocomplete-option[aria-selected="true"] {
    background-color: transparent;
  }

}