@import "variables";

.side-item {
    padding-top: 0;
    z-index: 9999999;

    .title {
        //padding: 25px 20px 25px 29px;
        padding: 24px 22px 26px 30px;
        border-bottom: 1px solid lightgray;

        .cross {
            float: right;
            cursor: pointer;
            margin-top: -2px;
            opacity: 0.45;
            transition: all 0.2s linear;

            svg {
                height: 33px;
                width: 33px;
                color: $primary-teal;
            }

            &:hover {
                opacity: 1;
            }
        }

        h5 {
            font-size: 20px;
        }
    }

    .content {
        text-align: center;

        p {
            text-align: left;
            max-width: 400px;
            padding: 15px 30px 15px;
            font-size: 14px;
        }

        img {
            max-width: 235px;
        }
    }

    input[type=text] {
        margin: 0 !important;
        padding: 2px 10px !important;
        border-bottom: 0 !important;
        box-shadow: none !important;

        &:focus {
            outline: 0 !important;
            border: 0 !important;
        }
    }

    input[type=number] {
        margin: 0 !important;
        padding: 2px 10px !important;
        border-bottom: 0 !important;
        box-shadow: none !important;

        &:focus {
            outline: 0 !important;
            border: 0 !important;
        }
    }

    input[type=number]::-webkit-inner-spin-button,
    input[type=number]::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    fieldset {
        outline: 0;
        border-color: #e5eded !important;
        border-width: 1px !important;
    }

    .Mui-focused {
        fieldset {
            border-color: $black !important;
            border-width: 1px !important;
        }
    }

    .input-wrap {
        //padding: 0 20px;
        padding: 0 32px;

        .discitem {
            label {
                transform: translate(26px, 20px) scale(1);

                &.MuiInputLabel-shrink {
                    transform: translate(14px, -6px) scale(0.75);
                }
            }

            input[type=text] {
                padding-left: 25px !important;
            }
        }

        label {
            line-height: 0.5;

            &.Mui-focused {
                line-height: 1;
                color: $black;
                opacity: 1;
            }

            &.MuiFormLabel-filled {
                line-height: 1;
                color: $black;
                opacity: 1;
            }

            &.MuiFormLabel-filled {
                ~div {
                    fieldset {
                        border-color: $black !important;
                    }
                }
            }
        }

        .d_value {
            width: 63%;

            p {
                font-size: 12px;
                margin-bottom: 10px;
                text-transform: uppercase;
                //  font-weight: bold;
                font-weight: 500;
                color: #084d4f;
                font-family: "Noto Sa-Bold" !important;
            }

            >div {
                width: 100%;
            }

            input#discount {
                border: 1px solid #e5eded !important;

                width: 91%;
                height: 42px;
                border-radius: 5px;

                &:hover {
                    border-color: $black !important;
                }

                &:focus {
                    border-color: $black !important;

                    ~label {
                        margin-top: -51px;
                        background-color: $white;
                        font-size: 13px;
                        opacity: 1;
                        color: $black;
                    }

                    &::placeholder {
                        opacity: 0;
                    }
                }

                &:not(:placeholder-shown) {
                    border-color: #e5eded !important;

                    ~label {
                        margin-top: -51px;
                        background-color: $white;
                        font-size: 13px;
                        opacity: 1;
                        color: rgba(8, 77, 79, 0.5) !important;
                    }
                }
            }

            label {
                position: absolute;
                margin-top: -30px;
                font-size: 16px;
                margin-left: 10px;
                opacity: 0;
                transition: all 0.2s ease-in;
            }

            &.discount-overlay {
                input {
                    border: 1px solid #e5eded !important;
                    width: 91%;
                    height: 42px;
                    border-radius: 5px;

                    &:hover {
                        border-color: $black !important;
                    }

                    &:focus {
                        border-color: $black !important;

                        ~label {
                            margin-top: -51px;
                            background-color: $white;
                            font-size: 13px;
                            opacity: 1;
                            color: $black;
                        }

                        &::placeholder {
                            opacity: 0;
                        }
                    }

                    &:not(:placeholder-shown) {
                        border-color: #e5eded !important;

                        ~label {
                            margin-top: -51px;
                            background-color: $white;
                            font-size: 13px;
                            opacity: 1;
                            color: rgba(8, 77, 79, 0.5) !important;

                            &.amount_error {
                                color: #db263b !important
                            }
                        }
                    }

                    &.amount_error {
                        border: 1px solid #db263b !important;
                    }
                }
            }
        }

        .d_type {
            p {
                font-size: 12px;
                margin-bottom: 10px;
                text-transform: uppercase;
                font-weight: bold;

                color: $primary-teal;
                font-family: "Noto Sa-Bold" !important;
            }

            button {
                margin: 0 3px;
                padding: 6px 2px;
                font-size: 20px;
                min-width: 50px;
                text-transform: capitalize;
                box-shadow: none;

                &.type_percentage,
                &.type_dollar {
                    background: #084d4f1a;
                    color: $primary-teal;

                    &.active {
                        background-color: $primary-teal;
                        color: $white;
                    }

                    &.Mui-disabled {
                        background: #084d4f1a;
                        color: $primary-teal;
                    }
                }

                &.type_dollar {
                    padding: 13px;
                }

                &:hover {
                    svg {
                        color: #e70a89;
                    }

                    &.active {
                        color: $white;

                        svg {
                            color: $white;
                        }

                        span {
                            color: $white;
                        }
                    }

                    span {
                        color: #e70a89;
                    }

                }
            }
        }

        .d_input {
            width: 100%;
            position: relative;
            margin-bottom: 15px;

            >div {
                width: 100%;
            }

            .Mui-disabled {

                .side_percentage,
                .side_dollar {
                    color: rgba(0, 0, 0, 0.2);
                }
            }

            .side_percentage {
                position: absolute;
                right: 12px;
                font-size: 17px;
                top: 13px;
                color: rgba(0, 0, 0, 0.54);
            }

            .side_dollar {
                position: absolute;
                left: 8px;
                font-size: 17px;
                top: 15px;
                color: rgba(0, 0, 0, 0.54);
            }

            input {
                &:focus {
                    ~.side_percentage {
                        color: rgba(0, 0, 0, 1);
                    }
                }
            }

            input {
                &.field-value {
                    ~.side_percentage {
                        color: rgba(0, 0, 0, 1);
                    }

                    ~.side_dollar {
                        color: rgba(0, 0, 0, 1);
                    }
                }
            }

            .Mui-focused,
            .MuiFormLabel-filled {
                >.side_percentage {
                    color: rgba(0, 0, 0, 1);
                }

                >.side_dollar {
                    color: rgba(0, 0, 0, 1);
                }
            }
        }

        button {
            box-shadow: none;

            &.apply {
                background: $primary-pink;
                color: $white;
                padding: 8px 20px;
                margin: 8px 9px 8px 0;
                min-width: 86px;
                border: 2px solid $primary-pink;

                &:hover {
                    box-shadow: inset 0 -40px 10px -2px rgba(0, 0, 0, 0.1);
                }

                &.Mui-disabled {
                    background: #084d4f1a;
                    color: $primary-teal;
                    border-color: transparent;
                    margin: 8px 9px 8px 5px;
                }
            }

            &.duplicate {
                margin-left: 0;
                padding: 8px 19px;
                margin: 8px 5px 8px 0px;
            }

            &.save {
                background: transparent;
                color: $black;
                border: 2px solid $black;
                padding: 7px 19px;
                margin: 15px 5px 8px 5px;
                box-shadow: none;
            }

            .MuiButton-label {
                text-transform: capitalize;
                //  font-weight: bold;
                font-weight: 500;
            }

            &.saveplus {
                padding: 8px 19px;
                margin: 8px 5px 8px 7px;
                border: solid 2px $black;
            }

            &.no_delete {
                padding: 8px 19px;
                border: solid 2px $black;
            }
        }

        h6 {
            font-size: 12px;
            color: $primary-teal;
            // font-weight: bold;
            font-weight: 500;
            margin: 30px 0 12px;
        }

        p {
            font-size: 14px;
            max-width: 250px;
        }
    }

    &#setting {
        min-width: 400px;
    }

    .bill_switch {
        //padding: 20px 20px 0;
        padding: 20px 32px 0;

        .MuiSwitch-thumb {
            padding: 0;
            height: 20px;

            &:after,
            &:before {
                display: none;
            }
        }

        .MuiSwitch-colorSecondary.Mui-checked {
            color: $primary-teal;
        }

        .MuiSwitch-colorSecondary.Mui-checked+.MuiSwitch-track {
            background-color: rgba(8, 77, 79, 0.2);
        }

        .MuiTypography-body1 {
            color: $black;
        }
    }
}

.MuiDrawer-paperAnchorRight {
    max-width: 400px;
}

.MuiDrawer-modal {
    .MuiBackdrop-root {
        background-color: rgba(0, 0, 0, 0.6);
    }
}