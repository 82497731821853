@import "variables";
.new-estimatic{
  select{
    max-width: 111px;
    display: block;
  }
  .button{
    text-align: end;
  }
  .hover-list{
    position: absolute;
    width: 100%;
    right: 0;
    text-align: left;
    background: $white;
    box-shadow: 1px 0px 6px;
    z-index: 12;
    padding: 20px;
    display: none;
  }
  h1{
   // font-weight: 500;
    font-weight: 300;
    font-size: 16px;
    line-height: 18px;
    letter-spacing: 0.15px;
    margin-bottom: 20px;
    span{
      display: inline-block;
      width: 20px;
      margin-right: 10px;
      vertical-align: middle;
    }
  }
  
  button{
    background: #000000;
    border-radius: 50px;
    color: $white;
    padding: 10px 20px;
    cursor: pointer;
    img{
      display: inline-block;
    vertical-align: middle;
    margin-right: 10px;
    }
  }
  .col-wrapper{
    margin-top: 20px;
    .col{
      background: $white;
      box-shadow: 0px 2px 4px 2px rgba(0, 0, 0, 0.1);
      padding: 10px;
      margin-bottom: 10px;
      &:first-child{
        box-shadow: none;
        margin-bottom: 0;
      }
    }
  }
  .project-name{
    display: inline-block;
    margin-right: 20px;
    width: calc(20% - 20px);
    &.stars{
      text-align: right;
      position: relative;
      cursor: pointer;
      > i:hover ~ ul{
        display: block;
      }
    }
    &.tags{
      h2{
        color: #6200EE;
      }
    }
    h2{
      font-size: 14px;
      line-height: 18px;
      letter-spacing: 0.15px;
      img{
        display: inline-block;
        vertical-align: middle;
        &:first-child{
          margin-left: 5px;
        }
      }
    }
    span{
      display: inline-block;
      width: auto;
      background: #6200EE;
      border-radius: 50px;
      margin-right: 10px;
      color: $white;
      font-size: 12px;
      line-height: 18px;
      padding: 4px 11px 6px;
      cursor: pointer;
      &:first-child{
        background: #C4C4C4;
        color: #000;
      }
    }
  }
}
