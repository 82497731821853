#icon-dropdown {
    .MuiMenu-paper {
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
    }

    ul {
        li {
            padding: 8px 15px 5px;

            .MuiListItemIcon-root {
                min-width: 45px;
            }
        }
    }
}