@import "../../../assets/scss/variables.scss";

.testimonial-slider {
  background-color: #fff;
  padding: 22px 0 10px;
  border-radius: 5px;
  min-height: 410px;

  @media screen and (min-width: 1601px) {
    min-height: 420px;
  }

  h5 {
    color: #084d4f;
    font-size: 12px;
    font-weight: 500;
    //  font-weight: bold;
    text-transform: uppercase;
    padding-left: 14px;
    letter-spacing: 0.6px;
  }

  .slick-slider {
    position: relative;
    max-width: 800px;
    width: 100%;
    margin: 0 auto;
    margin-top: 10px;

    &:after {
      content: "";
      display: block;
      clear: both;
    }

    .slick-list {
      overflow: hidden;

      &:after {
        content: "";
        display: block;
        clear: both;
      }
    }

    .slick-slide {
      float: left;
      padding: 12px;
      border-radius: 5px;

      &:hover {
        background-color: rgba(8, 77, 79, 0.05);
      }
    }
  }

  .description {
    @include flexbox;
    flex-wrap: wrap;
    align-items: center;
    text-align: center;
    max-width: 700px;
    width: 100%;
    margin: 0 auto;

    h4 {
      margin-bottom: 30px;
      margin-top: 15px;
      font-size: 18px;
      text-align: left;
      line-height: 1.4;

      @media screen and (max-width: 1201px) {
        font-size: 16px;
      }
    }

    .author-info {
      @include flexbox;
      flex-wrap: wrap;
      margin: 0 auto;

      .icons {
        width: 100%;
      }

      .detail {
        @include flexbox;
        flex-wrap: wrap;
        align-items: center;
        @include column(calc(100% - 60px));
        padding-left: 15px;
        text-align: left;

        h6 {
          margin: 0px;
          //   font-weight: 500;
          font-weight: 300;
        }

        p {
          margin: 0;
        }
      }
    }
  }

  .col-1 {
    outline: none;
  }

  .slick-prev {
    position: absolute;
    left: -100px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 0;
    border: 0;
    cursor: pointer;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: $dark-cyan;
    cursor: pointer;
    z-index: 1;

    &::before {
      content: "";
      position: absolute;
      top: 12px;
      right: 10px;
      width: 16px;
      height: 16px;
      border-left: 3px solid $white;
      border-bottom: 3px solid $white;
      border-top: 0px;
      border-right: 0px;
      transform: rotate(45deg);
    }
  }

  .slick-next {
    position: absolute;
    right: -100px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 0;
    border: 0;
    cursor: pointer;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: $dark-cyan;
    z-index: 1;

    &::before {
      content: "";
      position: absolute;
      top: 12px;
      left: 10px;
      width: 16px;
      height: 16px;
      border-top: 3px solid $white;
      border-right: 3px solid $white;
      border-bottom: 0px;
      border-left: 0px;
      transform: rotate(45deg);
    }
  }

  .slick-dots {
    text-align: center;
    margin-top: 20px;
    position: absolute;
    right: 7px;
    top: -50px;

    li {
      display: inline-block;
      padding: 0 5px;

      button {
        font-size: 0;
        border-radius: 50%;
        width: 11px;
        height: 11px;
        padding: 0;
        cursor: pointer;
        background: rgba(8, 77, 79, 0.1);
        border: 0;
      }

      &.slick-active {
        button {
          background-color: #e70a89;
        }
      }
    }
  }

  .btn-wrap {
    text-align: center;
    margin-top: 20px;

    a {
      display: inline-block;
    }
  }
}