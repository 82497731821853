@import "variables";

header {
    padding: 6.5px 0;
    box-shadow: none !important;
    z-index: 999 !important;

    @include tablet-down {
        padding: 6.5px 0 13px;
    }

    #lottie {
        background-color: $white;
        width: 100%;
        height: 100%;
        display: block;
        overflow: hidden;
        transform: translate3d(0, 0, 0);
        text-align: center;
        opacity: 1;
    }

    .MuiTypography-body1 {
        background-color: transparent;
    }

    .link-wrap {
        display: inline-block;
        vertical-align: middle;
        margin: 0 10px 0 0;

        @include tablet-down {
            z-index: 0;
        }

        .MuiTypography-body1 {
            background-color: transparent;
        }

        span {
            font-family: $font-family;
            // font-weight: 500;
            font-weight: 300;
            font-size: 20px;
            line-height: 1.36;
            color: $white;
            vertical-align: middle;
            padding-left: 10px;
            margin: 0;
        }

        .MuiBreadcrumbs-separator {
            color: rgba(255, 255, 255, 0.7);
        }

        .MuiBreadcrumbs-ol {
            position: relative;
            top: 50%;
            transform: translateY(-50%);
        }

        a {
            cursor: pointer;
            text-transform: capitalize;
            margin: 0;
            font-family: $font-family;
            font-weight: normal;
            font-size: 14px;
            line-height: 1.36;
            letter-spacing: 0.06em;
            color: rgba(255, 255, 255, 0.7);
            vertical-align: middle;
            position: relative;
            display: inline-block;
            pointer-events: none;
        }
    }

    .link-wrap {
        @include tablet-down {
            display: none;
        }
    }

    .closeLogo {
        position: relative;
        margin-right: 20px;
        opacity: 0;

        &:after {
            content: "";
            position: absolute;
            top: -13px;
            right: -7px;
            width: 1px;
            height: calc(100% + 25px);
            background: rgba(255, 255, 255, 0.5);
        }
    }

    .nav-search {
        position: relative;
        z-index: 1;
        padding: 5px 0 0 25px;

        .searchIcon {
            display: block;
            cursor: pointer;
            padding-right: 4px;

            .material-icons {
                color: $white;
            }

            svg {
                height: 33px;
                width: 33px;
            }

            &:hover {
                svg {
                    transition: all 0.2s linear;
                    fill: $primary-pink;
                }
            }
        }

        .close-icon {
            color: $white !important;
            position: fixed !important;
            top: 29px !important;
            right: 41px !important;
            font-size: 20px !important;
            // font-weight: 500 !important;
            font-weight: 300 !important;
            opacity: 0;
            transition: opacity 0.3s ease-in !important;
            visibility: hidden;
        }

        .clear-btn {
            color: rgba(255, 255, 255, 1);
        }

        .clear-btn {
            position: fixed;
            top: 32px;
            right: 90px;
            font-size: 12px;
            line-height: 1.42;
            padding-right: 16px !important;
            border-right: 1px solid $white;
            transition: opacity 0.3s ease-in !important;
            display: none;

            &:hover {
                background-color: transparent;
            }
        }

        .search-icon {
            position: absolute;
            top: 11px;
            font-size: 27px;
            color: rgba(255, 255, 255, 0.5);
        }

        .input-field {
            margin: 0 !important;
        }

        .search-input {
            min-width: 560px;
            background: #333333 !important;
            border-bottom: 0 !important;
            position: relative;
            margin: 0;
            border-radius: 23px !important;
            color: $white !important;
            font-size: 18px;
            box-sizing: border-box;

            @include desktop-down {
                min-width: 465px;
            }

            ~.clear-btn {
                color: #333333;
                border-right-color: #333333;
                display: none;
            }

            &:not(:placeholder-shown) {
                ~.clear-btn {
                    color: $white !important;
                    border-right-color: $white !important;
                    display: block;
                }
            }
        }

        .placeholder {
            position: relative;
            left: 40px;
            top: -8px;
        }
    }

    &.mui-fixed {
        .MuiToolbar-root {
            .img-wrap {
                padding-left: 12px;

                img.close {
                    display: none;
                }

                img.open {
                    display: block;
                }

                &.activeToggle {
                    padding-left: 22px;

                    img {
                        display: block;
                    }

                    img.open {
                        display: none;
                    }
                }
            }
        }
    }

    #navbarsearch {
        border-radius: 50px;

        @include tablet-down {
            position: absolute;
            right: -3px;
            top: -19px;
        }

        .input-field input[type="search"]+.label-icon {
            transform: translateY(11px);
        }

        #searchnavbar {
            padding: 0px 100px 1px 58px;

            @include tablet-down {
                min-width: 293px;
                font-size: 14px;
            }
        }

        #expandtrigger {
            label {
                svg {
                    fill: $white;
                }
            }
        }
    }

    &.admin-active {
        .admin-logo {
            &:after {
                content: "";
                position: absolute;
                background-color: $secondary-teal;
                width: 30px;
                height: 3px;
                bottom: -27px;
                left: -3px;
            }
        }
    }
}

.sub-nav-header {
    position: relative;
    background-color: $black;
    padding: 15px 10px;
    display: flex;
    align-items: center;

    &:before {
        content: "";
        position: absolute;
        top: 0;
        width: 20px;
        left: 0;
        height: 5px;
    }

    span {
        height: 70px;
        width: 70px;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        font-size: 40px;
        margin: 0 10px 0px;
        font-family: "nota";
        color: $white;
        text-transform: uppercase;
        position: relative;
        z-index: 1;

        &:before {
            content: '';
            position: absolute;
            background: #2ab7ca;
            border-radius: 50%;
            height: 70px;
            width: 70px;
            top: 0;
            left: 0;
            z-index: -1;

        }
    }

    .inner-content {
        display: inline-grid;

        a {
            color: $white;
            font-size: 14px;
            margin: 0;
            transition: all 0.1s linear;

            &:hover {
                color: $primary-pink;
            }
        }
    }

    h6 {
        display: inline-block;
        color: $white;
        font-size: 20px;
        line-height: 1.35;
        font-family: $font-family;
        position: relative;
    }

    p {
        color: $white;
        font-size: 14px;
        margin: 0;
    }

    h5 {
        margin: 30px 19px 0;
        font-size: 12px;
        line-height: 1.42;
        // font-weight: bold;
        font-weight: 500;
    }
}

.active-drawer-link {
    &:before {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        margin: auto;
        right: 0;
        width: 3px;
        height: 60%;
        background-color: $primary-pink;
    }
}

.MuiTooltip-tooltip {
    font-size: 12px !important;
    background: $black !important;
    padding: 7px !important;
}

.MuiListItem-root {
    &:hover {
        .MuiSvgIcon-root {
            color: $primary-pink;
        }

        svg {
            path {
                fill: $primary-pink;
            }
        }
    }

    span {
        font-family: $font-family;
        font-size: 14px;
        // font-weight: 600;
        font-weight: 500;
    }

    .small-text {
        span {
            font-size: 12px;
            font-weight: 900;
        }
    }
}

.MuiList-root {
    .title-wrap {
        .title {
            padding: 20px 18px 5px;
        }
    }

    &.MuiMenu-list {
        li.list-item {
            padding: 5px 20px !important;

            &:last-child {
                padding: 17px 20px 20px !important;
            }
        }
    }

    li {
        .custom-icon {
            img {
                &:first-child {
                    display: block;
                }

                &:last-child {
                    display: none;
                }
            }
        }

        &:hover {
            .custom-icon {
                img {
                    &:first-child {
                        display: none;
                    }

                    &:last-child {
                        display: block;
                    }
                }
            }
        }

        .nav-links {
            color: $black;
        }
    }
}

.active-drawer {
    .MuiSvgIcon-root {
        color: $primary-pink;
    }

    svg {
        path {
            fill: $primary-pink;
        }
    }
}

.MuiIconButton-root {
    background-color: transparent !important;
}

.MuiMenu-paper {
    .MuiListItemText-root {
        &.title {
            span {
                font-size: 12px;
                //font-weight: 600;
                font-weight: 500;
                background-color: transparent;
            }
        }

        span {
            font-weight: 400;
            font-size: 14px;
        }
    }

    ul {
        li {
            svg {
                opacity: 1;
                color: $black;
            }

            &:last-child {
                padding: 15px 20px 18px;
            }
        }
    }

    hr {
        width: calc(100% - 38px);
        margin: 18px auto 0;
    }
}

.MuiListItem-button {
    &:hover {
        background-color: rgba(8, 77, 79, 0.05) !important;
    }
}

.Component-paper-34,
.jss34 {
    top: 192px !important;
}

.MuiDrawer-paper {
    .logo {
        padding: 19px 0;
    }

    .MuiCollapse-container {
        .MuiCollapse-wrapper {
            .MuiListItem-root {
                .MuiListItemText-root {
                    span {
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        max-width: 140px;
                    }
                }
            }
        }
    }
}

.sideNavDrawer {
    .MuiDrawer-paperAnchorDockedLeft {
        border: 0;
    }

    .logo-wrap {
        position: relative;
        min-height: 77px;
        z-index: 9999999;

        &:after {
            content: "";
            position: absolute;
            top: 0px;
            right: 0px;
            width: 1px;
            height: 77px;
            background: rgba(255, 255, 255, 0.5);
            z-index: 1;
        }
    }

    .drawer-wrapper {
        height: 100%;
        border-right: 1px solid rgba(0, 0, 0, 0.12);
    }

    .MuiListItemText-primary {
        transition: margin 0.3s ease-in-out;
    }

    .lottie {
        svg {
            width: auto !important;
            height: 35px !important;
        }
    }
}

.drawer-open {
    .MuiTooltip-popper {
        display: none;
    }

    .sideNavDrawer {
        .MuiListItemText-primary {
            margin-left: -10px;
        }
    }

    .header-lootie {
        background-color: $black;

        >div {
            padding: 15px 0 8px 16px !important;
        }
    }
}