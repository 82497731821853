@import "../../../assets/scss/variables.scss";

.drawer-value-type {
    &.active {
        span:first-child {
            background-color: #084d4f;
        }
    }
}

.drawer-active {
    .calender-drawer {
        right: 0;
    }
}

.calender-drawer {

    max-width: 400px;
    width: 100%;
    height: 100%;
    right: 0;
    top: 0;

    background-color: $white;
    padding: 20px 28px 20px 30px;
    border-bottom: 1px solid lightgray;
    .selectbox {
        position: relative;
        margin-top: 9px;
    }
    .MuiSelect-select.MuiSelect-select {
        padding: 0;
        background-color: white !important;
        font-weight: 500;
    }
    .MuiSelect-icon {
        opacity: 0;
    }
    .calender-date {
        .MuiFilledInput-underline:before {
            display: none;
        }
        input.Mui-disabled {
            color: #000000 !important;
        }

    }
    h2 {
        font-size: 20px;
        color: #000000;
        display: inline-block;
        vertical-align: top;
        width: calc(100% - 29px);
        padding: 6px 0 28px;
        position: relative;
        letter-spacing: 0.0075em;
        &:after {
            content: '';
            position: absolute;
            width: calc(100% + 83px);
            bottom: 0;
            height: 1px;
            left: -31px;
            background-color: rgba(8, 77, 79, 0.2);
        }
    }
    .intro-header {
        font-size: 0;
        margin-bottom: 31px;
        span {
            display: inline-block;
            vertical-align: top;
            width: 29px;
            padding-left: 2px;
            padding-top: 2px;
            opacity: 0.5;
            &:hover {
                cursor: pointer;

            }
        }
    }
    .option-outer {
        .MuiFormControlLabel-root {
            margin-top: 2px;
            margin-bottom: 8px;
        }
    }
    .select-outer {
        padding-left: 8px;
    }

    .hour-box {
        display: flex;
        margin-bottom: 10px;
        .MuiGrid-grid-xs-2 {
            max-width: 24.666667%;
            flex-basis: 24.666667%;
        }
        &:focus {
            outline: none;
        }
        .error {
            top: 0;
            input {
                border: solid 2px #ff0028 !important;
                &:focus {
                    /// border: solid 2px #ff0028 !important;
                }
            }
            .MuiOutlinedInput-root {
                &:hover {
                    .MuiOutlinedInput-notchedOutline {
                        border-color: transparent;
                    }
                }
            }
        }
        .MuiTextField-root {
            width: 100%;
        }
        p {
            font-size: 14px;
            margin: 0;
            padding-left: 9px;
            padding-top: 9px;
        }

        input {
            margin: 0 !important;
            font-size: 14px !important;
            width: 100% !important;
            height: 40px !important;
            border-radius: 5px !important;
            text-align: center;
            border: solid 1px rgba(8, 77, 79, 0.1);
            box-sizing: border-box !important;

            &:hover {
                border: solid 1px rgba(8, 77, 79, 0.1);
            }
            &:focus {
                box-shadow: none !important;
                border-bottom: solid 1px rgba(8, 77, 79, 0.1) !important;
            }
            &.error {
                border: solid 2px #ff0028 !important;
                input {
                    border-bottom: solid 2px #ff0028 !important;
                }
            }
        }
        .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
            border: 0;
        }
        .MuiOutlinedInput-notchedOutline {
            outline: none;
        }
        .MuiInput-underline {
            &:before {
                opacity: 0;
            }
            &:hover:not(.Mui-disabled):before {
                border-bottom: 1px solid #000000;
            }
        }
        .MuiInput-underline.Mui-focused:after {
            display: none;
        }

        select {
            color: #000;
            font-size: 14px;
            font-weight: 500;
            //   font-weight: bold;
            padding: 9px 0 0 0;
        }
        .MuiNativeSelect-icon {
            opacity: 0;
        }
        .MuiNativeSelect-select:focus {
            background: transparent;
        }
        .MuiInput-underline:before {
        }
        .MuiFormControl-root {
            padding-left: 2px;
        }
    }
    .calender-box {
        margin: 20px 0;
    }
    .content-outer {
        margin-top: 10px;
        p {
            font-size: 12px;
            color: #084d4f;
            font-weight: 500;
            // font-weight: bold;
            margin-bottom: 5px;
        }
        .MuiTextField-root {
            width: 100%;
        }
        input {
            font-size: 20px !important;
            height: auto !important;
            padding: 2px 43px 8px 0px;
            margin-bottom: 0 !important;
            border-bottom: 0px !important;
            box-shadow: none !important;
            -webkit-appearance: none;
        }
        .MuiInput-underline:after {
            border-bottom: 0px;
            opacity: 0;
        }
        .MuiInput-underline:before {
            opacity: 0;
            width: 30px;
        }
        .MuiFilledInput-root {
            background-color: white;
        }
        .MuiInput-underline:hover:not(.Mui-disabled):before {
            opacity: 0;
        }
        .MuiInputLabel-filled {
            opacity: 0;
        }
    }
    .MuiFilledInput-underline:before {
        width: 20px !important;
        border-bottom: 1px solid #000000 !important;
    }

    .MuiInputLabel-shrink + div:before {
        opacity: 0;
    }
    .MuiFilledInput-underline:after {
        display: none;
    }

    input:-webkit-autofill {
        background-color: white !important;
        -webkit-box-shadow: 0 0 0px 1000px white inset !important;
    }
    .button-wrap {
        margin-top: 40px;
        display: flex;
        align-items: center;
        .delete-box {
            font-size: 14px;
            //font-weight: bold;
            font-weight: 500;
            cursor: pointer;
        }
    }
    .newestimate {
        border-radius: 5px;
        background-color: #e70a89;
        color: #fff;
        min-width: 100px;
        min-height: 40px;
        margin-right: 20px;
        box-shadow: none;
        &:hover {
            background-color: #cddbdc;
            box-shadow: none;
        }
        .MuiButton-label {
            font-size: 14px;
            // font-weight: bold;
            font-weight: 500;
        }
        &.disabled {
            background-color: rgba(0, 0, 0, 0.12);
            color: #fff;
            pointer-events: none;
        }
    }
    .calender-outer {
        abbr[title] {
            text-decoration: none !important;
        }
        .react-calendar {
            border: 0;
        }

        .react-calendar__tile.react-calendar__year-view__months__month:before {
            top: 15px;
            height: 28px;
        }

        .react-calendar__tile {
            position: relative;
            background-color: transparent;
            height: 40px;
        }
        .react-calendar__tile.react-calendar__century-view__decades__decade, .react-calendar__tile.react-calendar__decade-view__years__year {
            position: relative;
            z-index: 2;

        }
        .react-calendar__tile.react-calendar__century-view__decades__decade:before, .react-calendar__tile.react-calendar__decade-view__years__year:before {
            top: 15px;
            height: 28px;
            z-index: -1;

        }
        .react-calendar__tile:before {
            opacity: 0;
            content: "";
            position: absolute;
            left: 0;
            bottom: 0;
            right: 0;
            margin: auto;
            top: 0;
            width: 100%;
            height: 25px;
            background-color: #e9f8fa;
        }
        .react-calendar__tile.react-calendar__tile--range {
            color: #000000;
        }
        .react-calendar__tile.react-calendar__tile--range:before {
            opacity: 1;
        }
        .react-calendar__tile:before {
        }
        .react-calendar__tile--active.react-calendar__tile--rangeStart:before,
        .react-calendar__tile--active.react-calendar__tile--rangeEnd:before {
            width: 38px;
            height: 38px;
            border-radius: 50%;
            background-color: #2ab7ca;
            z-index: 1;
            opacity: 1;
        }
        .react-calendar__tile.react-calendar__tile--rangeStart:hover:before,
        .react-calendar__tile.react-calendar__tile--rangeStart:focus:before,
        .react-calendar__tile.react-calendar__tile--rangeEnd:hover:before,
        .react-calendar__tile.react-calendar__tile--rangeEnd:focus:before {
            width: 38px;
            height: 38px;
            border-radius: 50%;
            background-color: #2ab7ca !important;
            z-index: 1;
            opacity: 1;
        }
        .react-calendar__tile--rangeStart {
            abbr {
                color: #ffffff;
            }
        }
        .react-calendar__tile--rangeEnd {
            abbr {
                color: #ffffff;
            }
        }
        .react-calendar__tile--active.react-calendar__tile--rangeStart:after,
        .react-calendar__tile--active.react-calendar__tile--rangeEnd:after {
            content: "";
            position: absolute;
            left: 0px;
            right: 0;
            top: 0;
            bottom: 0;
            margin: auto;
            border-radius: 50%;
            background-color: #e9f8fa;
            width: calc(100% - 7px);
            height: 26px;
        }
        .react-calendar__tile--active.react-calendar__tile--rangeEnd:after {
            right: 22px;
            left: auto;
        }
        .react-calendar__tile--active.react-calendar__tile--rangeStart:after {
            left: 22px;
            right: auto;
        }
        .react-calendar__tile:hover:before,
        .react-calendar__tile--now:enabled:focus:before,
        .react-calendar__tile--now:enabled.react-calendar__tile--range:hover:before,
        .react-calendar__tile--now.react-calendar__tile--range:enabled:focus:before {
            background-color: #e9f8fa;
            opacity: 1;
        }
        .react-calendar__tile--now:enabled:hover,
        .react-calendar__tile--now:enabled:focus {
            background-color: transparent;
        }
        .react-calendar__tile abbr {
            position: relative;
            z-index: 1;
        }
        .react-calendar__tile.react-calendar__month-view__days__day--weekend:before, .react-calendar__tile.react-calendar__month-view__days__day--weekend:focus:before {
            opacity: 0;
        }
        .react-calendar__tile.react-calendar__month-view__days__day--weekend:after {
            opacity: 0 !important;
        }
        .react-calendar__month-view__days__day--weekend + .react-calendar__tile--rangeEnd:after {
            right: 0;
        }
        .react-calendar__month-view__days__day--weekend {
            color: #000000 !important;
            abbr {
                opacity: 0.3 !important;
            }
        }
        .react-calendar__month-view__days__day--weekend.react-calendar__tile--active,
        .react-calendar__month-view__days__day--weekend.react-calendar__tile--active:hover,
        .react-calendar__month-view__days__day--weekend.react-calendar__tile--active:enabled:focus {
            color: #ffffff;
            abbr {
                opacity: 1;
            }
        }
        .react-calendar__navigation__prev-button {
            text-align: left;
            padding-left: 0;
            padding-bottom: 7px;
        }
        .react-calendar__navigation__next-button,
        .react-calendar__navigation__prev-button {
            font-size: 28px;
            min-width: 20px;
        }
        .react-calendar__navigation__next-button:hover,
        .react-calendar__navigation__prev-button:hover {
            background-color: transparent;
        }
        .react-calendar__navigation__next-button {
            text-align: right;
            padding-right: 3px;
            padding-bottom: 7px;
        }
        .react-calendar__navigation__prev2-button,
        .react-calendar__navigation__next2-button {
            display: none;
        }

        .react-calendar__month-view__weekdays__weekday {
            abbr {
                opacity: 0.5 !important;
                // font-weight: 600;
                font-weight: 500;
                color: #000000;
            }
        }
        .react-calendar__tile.react-calendar__month-view__days__day.react-calendar__month-view__days__day--weekend {
            pointer-events: none;
        }

    }
}

//global select styling

ul.MuiList-root.MuiMenu-list {
    padding: 0;
    li {
        text-transform: capitalize;
        padding: 12px 20px 12px;
        font-size: 14px;
    }
    li:last-child {
        padding: 12px 20px 12px;
    }
}

.resource-name,
.resource-cost,
.resource-amount {
    &.error {
        > div {
            border: solid 1px #ff0028 !important;
        }
    }
}

.resource-drawer .resource-btn-disabled {
    background-color: rgba(0, 0, 0, 0.12) !important;
    color: #fff !important;
    pointer-events: none;
}

.calender-drawer .hour-box .error input:focus {
    border-bottom: solid 2px #ff0028 !important;
}