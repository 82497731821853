.gantt_task_line.gantt_project {
	.gantt_task_content {
		color: white;

		.project-line_resources {
			padding: 0 5px;
		}
	}
}

.gantt_row_project {
	&.gantt_row {
		width: 100%;

		&:hover {
			.phase_info::before {
				visibility: visible;
				opacity: 1;
			}

			.task-operation {
				.dots-option {

					opacity: 1;
				}
			}
		}
	}

	.gantt_cell {
		display: none;

		&:first-child {
			//position: absolute;
			display: block;
			width: 100% !important;
		}
	}

	.phase-container {
		display: flex;
		width: 100%;
		line-height: 1;

		.exclude {
			opacity: 0.5;
		}

		.include {
			opacity: 1;
		}

		.phase_col {
			display: flex;
			flex-direction: column;

			.task-menu {
				top: 85px;
			}




		}

		.phase_info {
			position: relative;
			//flex-shrink: 0;
			min-width: 175px;
			margin-right: 13px;

			&:before {
				content: "";
				display: block;
				visibility: hidden;
				position: absolute;
				top: 42px;
				left: -32px;
				height: 8px;
				width: 13px;
				opacity: 0;
				background-image: url("../../img/icons/icon_drag_phase.png");
				background-position: center;
				background-repeat: no-repeat;
				background-size: cover;
				transition: all 0.2s;
				cursor: pointer;
			}
		}

		.phase_title {
			font-family: NotoSans;
			font-size: 12px;
			font-weight: 500;
			font-style: normal;
			letter-spacing: 0.6px;
			text-transform: uppercase;
			color: #084d4f;
		}

		.phase_name {
			display: flex;
			align-items: center;
			justify-content: flex-end;
			min-width: 65px;
			margin-top: 15px;
			height: 35px;
			font-family: NotoSans;
			font-size: 25px;
			font-weight: 500;
			font-stretch: normal;
			font-style: normal;
			letter-spacing: normal;
			color: #000000;
		}

		.phase_resources {
			margin-top: 5px;
			font-family: NotoSans;
			font-size: 12px;
			color: #000000;
		}

		.phase_hours {
			margin-left: auto;
		}

		.phase_cost {
			margin-left: 70px;
			margin-right: 70px;
		}

		.phase_actions {
			align-items: center;
			margin-left: 6px;
			width: 38px;

			.task-operation {
				margin-top: 15px;
			}
		}

		.phase_toggle {
			display: flex;
			height: 20px;
			width: 20px;
			cursor: pointer;

			&:after {
				content: "";
				height: 7px;
				width: 12px;
				background-image: url(../../img/icons/toggle_collapse.png);
				background-position: center;
				background-repeat: no-repeat;
				background-size: cover;
				margin: 0 auto;
			}

			&.closed {
				&:after {
					transform: rotate(180deg);
				}
			}
		}

		.phase_hours,
		.phase_cost,
		.phase_price,
		.phase_toggle {
			text-align: right;
		}
	}

	//
	//.phase-container {
	//	//position: absolute;
	//	display: flex;
	//	flex-direction: column;
	//	margin-left: 32px;
	//	//z-index: 5000;
	//
	//}
}

.gantt_data_area {
	.gantt_bar_project.gantt_project {
		//margin-top: 55px;
	}
}


//.gantt_row_project .gantt_cell .gantt_tree_content {
//	display: none;
//	&:not(:first-child) {
//		position: absolute;
//		display: block;
//	}
//}

.phase-space {
	position: absolute;
	width: 100%;
	height: 118px;
	background-color: rgb(255, 255, 255);
	//border-bottom: 2px solid rgba(8, 77, 79, 0.2);
}

.gantt_task_line.gantt_project {
	background-color: #65a838;
	border: none;
}

.gantt_row.gantt_row_project {
	//border-bottom: 2px solid rgba(8, 77, 79, 0.2);
}

.phase-space,
.gantt_row.gantt_row_project {
	//border-top: 10px solid rgba(8, 77, 79, 0.2);
}