.expense-drawer {

    .expenseNotes {
        display: flex;
        flex-direction: column;

        span {
            color: #084d4f;
            font-size: 12px;
            margin-top: 30px;
            font-weight: 600;
            line-height: 1.42;
            letter-spacing: 0.6px;
            text-transform: uppercase;
        }

        .MuiInputBase-multiline {
            &::before {
                content: none;
            }

            &::after {
                content: none;
            }
        }
    }
}