.gantt_grid_data {
	.gantt_cell {
		padding-left: 0;
		padding-right: 0;

	}

	.exclude {
		padding-left: 0;
		padding-right: 0;
		opacity: 0.3;
	}

	.include {
		padding-left: 0;
		padding-right: 0;
		opacity: 1;
	}

	.gantt_tree_content {
		box-sizing: border-box;
		padding-left: 6px;
		padding-right: 6px;
	}

	.gantt_cell:first-child .gantt_tree_content {
		margin-left: 44px; // +6px on prev gantt_cell = 30px
	}

	.gantt_row.gantt_row_task {

		// &:hover {
		// 	.dots-option {
		// 		opacity: 1;
		// 	}
		// }
		&:hover {
			.task-operation {

				.dots-option {
					opacity: 1;
				}


			}
		}
	}

	.gantt_row.gantt_row_project {
		// &:hover {
		// 	.dots-option {
		// 		opacity: 1;
		// 	}
		// }

		border-top: 0px solid rgba(8, 77, 79, 0.2);
		border-bottom: 1px solid rgba(8, 77, 79, 0.2) !important;

		// &:first-child {
		// 	border-top: 1px solid rgba(8, 77, 79, 0.2)
		// }

		&.closed {
			border-bottom: 2px solid rgba(8, 77, 79, 0.2) !important;
		}
	}

	.button-with-border {
		border-bottom: 2px solid rgba(8, 77, 79, 0.2) !important;

	}
}

.gantt_layout_cell_border_right {
	border-right: 1px solid #cddbdc;

}

.gantt_grid_scale,
.gantt_task_scale {
	border-bottom: 1px solid #cddbdc;

}



.grid-row-with-border .gantt_tree_content {
	border-bottom: 1px solid rgba(8, 77, 79, 0.2);

}

.grid-row-with-border:nth-last-of-type(2) .gantt_tree_content {
	border-bottom: none !important;
}


.gantt_grid_scale .gantt_grid_head_cell {
	display: none;

	&:first-child {
		display: block;
		width: 100% !important;

	}

	;
}

.gantt_task .gantt_task_scale .gantt_scale_cell {
	color: #000000;
}

.gantt_grid_scale .gantt_grid_head_cell,
.gantt_task .gantt_task_scale .gantt_scale_cell {
	font-family: NotoSans;
	font-size: 12px;
	font-weight: bolder;
	color: #000000;
	opacity: 1;
}

.gantt_task .gantt_task_scale .scale {
	&:first-child {
		.gantt_scale_cell {
			bottom: -8px;
		}
	}

	&:last-child {
		.gantt_scale_cell {
			top: -5px;
		}
	}
}

.header {
	display: flex;
	justify-content: center;
	width: 100%;
	align-items: center;
	font-size: 12px;
	font-weight: bold;
	text-transform: uppercase;
	letter-spacing: 1px;
	color: #084d4f;
}

.header.text--right {
	justify-content: flex-start;
}

.qty-container {
	display: flex;
	position: relative;
	justify-content: center;
	align-items: center;
	width: 0px;
	margin: 0 auto;
	height: 30px;
	border-radius: 15px;
	transition: all .2s ease-in-out;

	span {
		display: flex;
		justify-content: center;
		align-items: center;
		border-radius: 50%;
		width: 30px;
		height: 30px;
		transition: all .2s ease-in-out;

		i {
			pointer-events: none;
		}

	}

	.counter {
		position: absolute;
		cursor: pointer;
		background-color: rgb(242, 246, 246);
		z-index: 5;

	}

	.moving {
		position: absolute;
		background-color: white;
		pointer-events: none;
		border: 1px solid rgba(0, 0, 0, 0.267);
		opacity: 0;
	}

	.minus {
		right: -15px;

		&.btn_circle_minus {
			&::after {
				background-color: #000000;
			}
		}

		&.delete-task {
			&:after {
				content: "";
				height: 15px;
				width: 15px;
				background: url(../../img/icons/Delete.svg) center no-repeat;
				background-size: contain;
			}
		}
	}

	.add {
		left: -15px;

		&.btn_circle_plus {

			&::after,
			&::before {
				background-color: #000000;
			}
		}
	}

	&.focus {
		width: 100%;
		background-color: rgb(242, 246, 246);

		.moving {
			opacity: 1;
			pointer-events: auto;
			cursor: pointer;
		}

		.minus {
			right: 75px;
		}

		.add {
			left: 75px;
		}
	}
}

.qty-delete {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	margin-top: 5px;
	width: 100%;
	height: 25px;

	&:after {
		content: "";
		height: 12px;
		width: 12px;
		background-image: url("../../img/icons/icon_cross_col_tr.png");
		background-position: center;
		background-repeat: no-repeat;
		background-size: cover;
	}
}

.openingLightboxBtn {
	margin: 0 auto;
	height: 30px;
	width: 30px;
	background-image: url("../../img/icons/btn_add_newTime.svg");
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	cursor: pointer;

	&.tooltip {
		&::after {
			content: "Resource Duration";
			right: 10px;
			top: -28px;
		}
	}
}

.resource-icon {
	display: flex;
	align-items: center;
	position: relative;

	.role-icon {
		margin-left: 10px;
		width: 20px;
		height: 20px;
		background-size: contain;
		background-repeat: no-repeat;
		background-position: center;

		&.internal {
			background-image: url("../../img/icons/internal.png");
		}

		&.external {
			background-image: url("../../img/icons/external.png");
		}
	}
}

.abbreviation {
	box-sizing: border-box;
	display: flex;
	position: relative;
	align-items: center;
	height: 30px;
	width: 160px;
	border-top-right-radius: 30%;
	border-bottom-right-radius: 30%;

	&::after {
		content: "";
		display: block;
		position: absolute;
		right: 0;
		width: 25px;
		height: 100%;
		background-color: rgba(255, 255, 255, 0.685);
	}

	.abbreviation-position {
		width: 160px;
		margin-left: 13px;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
	}
}

.task-operation {
	display: flex;
	justify-content: center;
	width: 100%;
	height: 20px;
	display: flex;
	right: 0;
	cursor: pointer;

	.dots-option {
		pointer-events: none;
		// background-image: url("../../img/icons/dotsblack.svg"); // esref
		// background-size: contain;
		// background-repeat: no-repeat;
		// background-position: center;
		// opacity: 0.5;
		display: flex;
		opacity: 0;
		transition: opacity 0.2s linear;
	}

	&:hover {
		svg {
			fill: #e70989;
		}
	}

	.task-menu {
		display: flex;
		visibility: hidden;
		opacity: 0;
		flex-direction: column;
		position: absolute;
		top: 40px;
		right: 10px;
		z-index: 9000;
		border-radius: 5px;
		box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
		border: solid 1px rgba(8, 77, 79, 0.1);
		background-color: #ffffff;
		transition: all 0.2s linear;

		.new-action {
			display: flex;
			width: 135px;
			height: 36px;
			cursor: pointer;

			&:first-child {
				.icon {
					background-image: url(../../img/icons/dublicate.svg);
				}

				&:hover {
					.icon {
						background-image: url(../../img/icons/dublicate-pink.svg);
					}
				}
			}

			&:nth-child(2) {
				.iconInclude {
					background-position-y: 13px;
					background-image: url(../../img/icons/include.svg);
				}

				.iconExclude {
					background-position-y: 13px;
					background-image: url(../../img/icons/exclude.svg);
				}


				&:hover {
					.iconInclude {
						background-image: url(../../img/icons/include-hover.svg);
					}

					.iconExclude {
						background-image: url(../../img/icons/exclude-hover.svg);
					}
				}
			}

			&:last-child {
				.icon {
					background-image: url(../../img/icons/Delete.svg);
				}

				&:hover {
					.icon {
						background-image: url(../../img/icons/Delete-pink.svg);
					}
				}
			}

			&:hover {
				background-color: rgba(8, 77, 79, 0.05);
			}

			span {
				display: flex;
				align-items: center;
				pointer-events: none;
			}

			.icon {
				width: 20px;
				margin-right: 14px;
				margin-left: 20px;
				background-size: contain;
				background-repeat: no-repeat;
				background-position-x: center;
				background-position-y: 11px;
			}
		}
	}

	&.active {
		.task-menu {
			visibility: visible;
			opacity: 1;
		}
	}

}

.hours {
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	width: 100%;
	text-align: center;
}

.btn_circle_minus,
.btn_circle_plus {
	position: relative;
	box-sizing: border-box;
	height: 30px;
	width: 30px;
	border-radius: 50%;
	background-color: #000000;
	cursor: pointer;

	&::after {
		content: " ";
		position: absolute;
		z-index: 1;
		display: block;
		height: 2px;
		margin-top: -1px;
		top: 50%;
		left: 8px;
		right: 8px;
		background-color: #ffffff;
	}
}

.btn_circle_plus {
	&::before {
		content: " ";
		position: absolute;
		z-index: 1;
		display: block;
		width: 2px;
		margin-left: -1px;
		left: 50%;
		top: 8px;
		bottom: 8px;
		background-color: #ffffff;
	}
}