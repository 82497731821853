.side-item {
  padding-top: 12px;

  .title {
    padding: 10px 20px 20px;
    border-bottom: 1px solid lightgray;

    .cross {
      float: right;
      cursor: pointer;
      margin-top: -5px;
      opacity: 0.45;
      transition: all 0.2s linear;

      svg {
        height: 35px;
        width: 35px;
        color: #084d4f;
      }

      &:hover {
        opacity: 1;
      }
    }
  }

  label {
    font-weight: 300;
  }

  input[type=text] {
    margin: 0 !important;
    padding: 2px 10px !important;
    border-bottom: 0 !important;
    box-shadow: none !important;

    &:focus {
      outline: 0 !important;
      border: 0 !important;
    }
  }

  input[type=number]::-webkit-inner-spin-button,
  input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  fieldset {
    outline: 0;
    border-color: #e5eded !important;
    border-width: 1px !important;
  }

  .Mui-focused {
    fieldset {
      border-color: #000 !important;
      border-width: 1px !important;
    }
  }

  .input-wrap {
    padding: 0 20px;

    .discitem {
      label {
        transform: translate(26px, 20px) scale(1);

        &.MuiInputLabel-shrink {
          transform: translate(14px, -6px) scale(0.75);
        }
      }

      input[type=text] {
        padding-left: 25px !important;
      }

    }

    label {
      line-height: 0.5;

      &.Mui-focused {
        line-height: 1;
        color: #000;
        opacity: 1;
      }

      &.MuiFormLabel-filled {
        line-height: 1;
        color: #000;
        opacity: 1;
      }

      &.MuiFormLabel-filled {
        ~div {
          fieldset {
            border-color: #000 !important;
          }
        }
      }
    }

    button {
      box-shadow: none;

      &.duplicate {
        margin-left: 0;
        padding: 8px 19px;
        margin: 8px 5px 8px 0px;
      }

    }

    h6 {
      font-size: 12px;
      color: #084d4f;
      font-weight: bold;
      margin: 30px 0 12px;
    }

    p {
      font-size: 14px;
      max-width: 250px;
    }
  }

}

.new_drawer_item {

  .modifier_name_date {
    width: 260px;
    height: 19px;
    margin-left: 30px;
    font-family: NotoSans;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.36;
    letter-spacing: normal;
    text-align: left;
    color: #000;
    display: flex;
    justify-content: flex-start;
  }

  h5 {
    font-size: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .cross {
      display: inline-block;
      opacity: .45;
      cursor: pointer;

      &:hover {
        opacity: 1;
      }

      svg {
        height: 35px;
        width: 35px;
        color: #084d4f;
      }
    }
  }

  .side-item {
    .content {
      p {
        padding-left: 0;
      }
    }

    .input-wrap {
      padding-left: 0;

      .d_input {
        input {
          margin-left: 0 !important;
          border: 1px solid rgba(8, 77, 79, .1) !important;
          padding: 0px 15px 0 10px !important;
          width: 75px !important;
          top: 0 !important;
          text-align: left !important;
          border-radius: 5px;

          &:focus {
            box-shadow: none;
          }
        }
      }
    }
  }
}

.menudrop-round {
  .MuiSelect-selectMenu {
    min-height: 27px;
    display: flex;
    align-items: center;
    border-color: #e5eded;
  }
}