.resourceGrid_cell {
	.gantt_grid_scale {
		border-top: 1px solid rgba(8, 77, 79, 0.2);
		border-bottom: 2px solid rgba(8, 77, 79, 0.2) !important;
	}

	.summary_header {
		display: flex;
		justify-content: space-between;
		margin-left: 50px;
		color: #000000;

		.summary_title {
			font-family: NotoSans;
			font-size: 14px;
			font-weight: bold;
			font-style: normal;
			text-transform: capitalize;
		}

		.summary_toggle {
			display: flex;
			justify-content: center;
			align-items: center;
			width: 38px;
			margin-right: 6px;
			cursor: pointer;

			&:after {
				content: "";
				height: 7px;
				width: 12px;
				background-image: url(../../img/icons/toggle_collapse.png);
				background-position: center;
				background-repeat: no-repeat;
				background-size: cover;
			}
		}
	}

	.summary-type-name {
		font-size: 12px;
		font-weight: bold;
		color: #084d4f;
		text-transform: uppercase;
	}

	.summary_toggle--closed .summary_toggle:after {
		transform: rotate(180deg);
	}
}

.resourceTimeline_cell {
	.gantt_task_scale {
		border-top: 1px solid rgba(8, 77, 79, 0.2);
		border-bottom: 2px solid rgba(8, 77, 79, 0.2);
	}

	.gantt_scale_line {
		display: none;
	}
}