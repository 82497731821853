@import "../../../assets/scss/variables.scss";
#navbarsearch{
  transition: width 0.3s ease-in-out;
  overflow: hidden;
}
.search {
  position: absolute;
  right: 0;
  top: 55px;
  z-index: 2;
  padding-top: 0;
  display: none;
  .container {
    width: 565px !important;
    //margin-top: 28px;
    @include tablet-down{
      width: 300px!important;
      //margin-top: 10px;
    }
  }
  .no-search{
    height: 612px;
    .row{
      position: relative;
      top: 50%;
      transform: translateY(-50%);
      text-align: center;
    }
    h6{
      max-width: 234px;
      margin: 0 auto;
      font-size: 14px;
     // font-weight: 500;
      font-weight: 300;
      line-height: 1.36;
      text-align: center;
      color: #000000;
      margin-bottom: 45px;
    }
  }
  .card{
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    .recent-searches{
      padding: 0;
      h6{
        padding: 15px 30px 10px 30px;
        font-size: 12px;
        letter-spacing: 0.6px;
       // font-weight: bold;
        font-weight: 500;
        text-transform: uppercase;
        @include tablet-down{
          padding: 10px 20px 5px 18px;
          font-size: 10px;
        }
      }
      .result-wrap{
        max-height: 620px;
        overflow-y: scroll;
        min-height: 620px;
        @include tablet-down{
          max-height: 400px;
          min-height: 400px;
        }
      }
    }
  }
  .tab-outer {
    width: 100%;
    overflow-y: scroll;
    padding: 0;
    @include tablet-down{
      max-height: 395px;
    }
    .col {
      display: none;
      &:first-child {
        display: block;
      }
    }
  }
  tr {
    border: none;
    margin: 0 auto;
    padding: 7px 30px;
    display: flex;
    align-items: center;
    @include tablet-down{
      padding: 5px 12px;
    }
    &:last-child{
      padding-bottom: 30px;
    }
    .img-wrap{
      img,
      svg{
        &:first-child{
          display: block;
        }
        &:last-child{
          display: none;
        }
      }
    }
    &:hover {
      background-color: rgba(8, 77, 79, 0.05);
      &:hover {
        .img-wrap {
          img,
          svg {
            &:first-child {
              display: none;
            }
            &:last-child{
              display: block;
            }
          }
        }
      }
    }
  }
  .last-modfied-date {
    margin-left: auto;
    width: 40%;
    p {
      font-size: 12px;
      text-align: right;
      padding: 3px 0;
      color: #000;
      line-height: 1;
      margin: 4px 0;
      @include tablet-down{
       font-size: 10px;
      }
    }
  }
  td {
    padding: 0;
    line-height: 0;
    position: relative;
    cursor: pointer;
    &:first-child {
      width: 60%;
      @include tablet-down{
        width: calc(60% - 10px);
      }
    }
    >span {
      display: inline-block;
      vertical-align: bottom;
      margin: 0 12px;
      font-size: 14px;
      line-height: normal;
    }
    font-size: 15px;
    color: #000;
  }
  b {
    font-size: 15px;
    color: rgba(0, 0, 0, 0.87);
  }
  .icon-fields {
    border-bottom: 1px solid rgba(8, 77, 79, 0.2) !important;
    padding-top: 15px;
    a {
      &:hover {
        .img-wrap {
          img,
          svg {
            &:first-child {
              display: none;
            }
          }
        }
      }
    }
    .img-wrap{
      img,
      svg{
        display: block;
      }
    }

  }

  .tabs,
  .tab {
    height: auto;
  }

  .tab {
    height: 41px;
    text-transform: capitalize;
    line-height: inherit;
    border-bottom: 3px solid transparent;
    .active {
      color: #000;
    }
    .searchTabs{
      padding-top: 3px;
      width: 100%;
      color: transparent;
    }
    svg{
      &:hover{
        fill: #e70a89;
      }
    }
    a,
    .searchAll {
      font-size: 14px;
      width: 100%;
      color: #000;
      height: 38px;
      line-height: 2;
      cursor: pointer;
      &:hover {
        color: #e70a89;
        //border-bottom: 3px solid #e70a89;
        //max-width: 114px;
        margin: 0 auto;
      }
      p{
        margin: 0;
        top: -3px;
        position: relative;
      }
    }
    &.active{
      border-bottom: 3px solid #e70a89;
      svg{
        fill: #e70a89;
      }
      a,
      .searchAll {
        color: rgba(0,0,0,0.5);
       // max-width: 114px;
        margin: 0 auto;
        p{
          color: #e70a89;
          margin-top: 4px;
        //  font-weight: 500;
          font-weight:300;
          margin: 0;
          top: -3px;
          position: relative;
        }
        .img-wrap {
          img,
          svg {
            &:first-child {
              display: none;
            }
          }
        }
      }
    }
    &.deactivate{
      pointer-events: none;
      button{
        svg{
          opacity: 0.5;
        }
      }
    }
    .tab-touch-wrap{
      width: 100%;
      color: rgba(0,0,0,0.5);
    }
  }


  .table-heading {
    >span {
      vertical-align: middle;
      white-space: nowrap;
      overflow: hidden;
      max-width: 230px;
      text-overflow: ellipsis;
      @include tablet-down{
        font-size: 10px;
        margin: 0 0 0 10px;
        max-width: 80px;
        vertical-align: top;
      }
      strong{
        text-shadow: 0.6px 0 #000;
      }
    }
    p {
      font-size: 12px;
      margin-left: 30px;
      color: #000;
      line-height: 1;
      padding-left: 8px;
      &.search-type{
        margin-left: 0px;
        padding-left: 0px;
      }
    }
  }
  .img-wrap {
    display: inline-block;
    max-width: 20px;
    height: 23px;
    position: relative;
    margin-right: 1px;
    vertical-align: middle;
    @include tablet-down{
      height: 20px;
    }
    img,
    svg {
      &:first-child {
        position: absolute;
        left: 0;
        right: 0;
        margin: auto;
        z-index: 1;

        &:hover {
          display: none;
        }
      }
      &:last-child {
        position: absolute;
        left: 0;
        right: 0;
        margin: auto;
        z-index: 0;
      }
    }
  }
  .icon-category {
    max-height: 616px;
    padding: 0;

    .row {
      margin: 0 auto;
      margin-bottom: 0!important;
    }
    &#contacts{
      p{
        font-size: 14px;
        font-style: normal;
        line-height: 1.36;
        color: #000000;
        margin: 0;
        vertical-align: top;
        @include tablet-down{
          margin-top: 0;
          font-size: 10px;
        }
      }
      .type{
        font-size: 11px;
        line-height: 1.36;
        color: #000000;
        @include tablet-down{
          font-size: 10px;
        }
      }
      .row{
         .result{
           span{
             margin: 14px 0 0;
           }
         }
      }
    }
  }
  .result {
    &:hover {
      background: none;
    }
    span {
      color: rgba(0, 0, 0, 0.5);
      margin: 14px 0px 0;
    }
    
  }
  
}

