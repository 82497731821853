@import "variables";

.profile-container {
  &:first-of-type {
    display: none;
  }
}

footer {
  display: none; //added for meeting only, will remove later(7/1). 
  background: $dark-cyan;
  padding: 70px 0;

  .row {
    margin-bottom: 0;
  }

  .brand-logo {
    display: block;
    width: 180px;
    height: 36px;

    @media only screen and (max-width: 992px) and (min-width: 768px) {
      max-width: 115px;
    }

    @media only screen and (max-width: 595px) {
      max-width: 100px;
    }

    img {
      width: 100%;
      height: 100%;
    }
  }

  .footer-info {
    max-width: 300px;
    width: 100%;

    p {
      margin: 20px 0;
    }
  }

  h5 {
    margin-top: 0;
  }

  .col {
    @media only screen and (max-width: 992px) {
      text-align: center;
    }
  }

  .footer-links {
    h5 {
      margin-bottom: 20px;
    }

    ul {
      li {
        margin-bottom: 10px;

        &:last-child {
          margin-bottom: 0px;
        }
      }
    }
  }

  .social-link {
    ul {
      font-size: 0;

      li {
        display: inline-block;
        margin-right: 20px;

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
}

.content-wrap {
  background-color: #f2f6f6;
  border-top: 1px solid rgba(8, 77, 79, 0.2);
  color: $primary-teal;
  display: flex;
  justify-content: space-between;
  padding: 20px 45px 20px 135px !important;
  align-items: center;

  .linkwrap {
    a {
      color: $primary-teal;
      font-size: 12px;
      //font-weight: 500;
      font-weight: 300;
      padding-right: 15px;
      position: relative;

      &:after {
        content: '';
        position: absolute;
        right: 7px;
        top: 4px;
        height: calc(100% - 7px);
        width: 1px;
        background-color: $primary-teal;
      }

      &:last-child {
        &:after {
          display: none;
        }
      }
    }
  }
}