.resource_addNewPhaseBtn {
	border-top: 1px solid #cddbdc;


	&.closed_addNewPhaseBtn {
		.btn_add-phase {
			// margin-left: 800px;

			&.tooltip::after {
				content: "Add Phase";
				top: -30px;
				left: 80px;
			}
		}
	}

	.btn_add-phase {
		display: flex;
		width: 40px;
		height: 40px;
		margin-top: 10px;
		margin-left: 50%;
		border-radius: 50%;
		cursor: pointer;

		.ripple {
			position: absolute;
			top: -120px;
		}

		&.tooltip::after {
			content: "Add Phase";
			top: -30px;
			left: 49%;

			@media screen and (max-width: 1301px) {
				left: 48%;
			}
		}

		&::before {
			content: "";
			height: 40px;
			width: 40px;
			background-image: url("../../img/icons/btn_add_phase.svg");
			background-position: center;
			background-repeat: no-repeat;
			background-size: cover;

			&:hover {
				opacity: .8;
			}

			&:active {
				opacity: .6;
			}
		}
	}
}