.side-item {
  padding-top: 12px;

  .title {
    padding: 10px 20px 20px;
    border-bottom: 1px solid lightgray;

    .cross {
      float: right;
      cursor: pointer;
      margin-top: -5px;
      opacity: 0.45;
      transition: all 0.2s linear;

      svg {
        height: 35px;
        width: 35px;
        color: #084d4f;
      }

      &:hover {
        opacity: 1;
      }
    }
  }

  //.content{
  //  text-align: center;
  //  p{
  //    text-align:left;
  //    max-width: 400px;
  //    padding: 15px 20px 0;
  //    font-size: 14px;
  //  }
  //  img{
  //    max-width: 235px;
  //  }
  //}
  input[type=text] {
    margin: 0 !important;
    padding: 2px 10px !important;
    border-bottom: 0 !important;
    box-shadow: none !important;

    &:focus {
      outline: 0 !important;
      border: 0 !important;
    }
  }

  //input[type=number]{
  //  margin: 0 !important;
  //  padding: 2px 10px!important;
  //  border-bottom: 0 !important;
  //  box-shadow: none !important;
  //  &:focus{
  //    outline: 0 !important;
  //    border: 0 !important;
  //  }
  //}
  //input[type=number]::-webkit-inner-spin-button,
  //input[type=number]::-webkit-outer-spin-button {
  //  -webkit-appearance: none;
  //  margin: 0;
  //}
  fieldset {
    outline: 0;
    border-color: #e5eded !important;
    border-width: 1px !important;
  }

  .Mui-focused {
    fieldset {
      border-color: #000 !important;
      border-width: 1px !important;
    }
  }

  .input-wrap {
    padding: 0 20px;

    .discitem {
      label {
        transform: translate(26px, 20px) scale(1);

        &.MuiInputLabel-shrink {
          transform: translate(14px, -6px) scale(0.75);
        }
      }

      input[type=text] {
        padding-left: 25px !important;
      }
    }

    label {
      line-height: 0.5;

      &.Mui-focused {
        line-height: 1;
        color: #000;
        opacity: 1;
      }

      &.MuiFormLabel-filled {
        line-height: 1;
        color: #000;
        opacity: 1;
      }

      &.MuiFormLabel-filled {
        ~div {
          fieldset {
            border-color: #000 !important;
          }
        }
      }
    }

    //.d_value{
    //  width: 63%;
    //  p{
    //    font-size: 12px;
    //    margin-bottom: 10px;
    //    text-transform: uppercase;
    //    font-weight: bold;
    //    color: #084d4f;
    //    font-family: "Noto Sa-Bold" !important;
    //  }
    //  >div{
    //    width: 100%;
    //  }
    //  input#discount{
    //    border: 1px solid #e5eded !important;
    //    width: 91%;
    //    height: 42px;
    //    border-radius: 5px;
    //    &:hover{
    //      border-color: #000 !important;
    //    }
    //    &:focus{
    //      border-color: #000 !important;
    //      ~label{
    //        margin-top: -51px;
    //        background-color: #fff;
    //        font-size: 13px;
    //        opacity: 1;
    //        color: #000;
    //      }
    //      &::placeholder{
    //        opacity: 0;
    //      }
    //    }
    //    &:not(:placeholder-shown) {
    //      border-color: #000 !important;
    //      ~label{
    //        margin-top: -51px;
    //        background-color: #fff;
    //        font-size: 13px;
    //        opacity: 1;
    //        color: #000;
    //      }
    //    }
    //  }
    //
    //  label{
    //    position: absolute;
    //    margin-top: -30px;
    //    font-size: 16px;
    //    margin-left: 10px;
    //    opacity: 0;
    //    transition: all 0.2s ease-in;
    //  }
    //}
    //.d_type{
    //  p{
    //    font-size: 12px;
    //    margin-bottom: 10px;
    //    text-transform: uppercase;
    //    font-weight: bold;
    //    color: #084d4f;
    //    font-family: "Noto Sa-Bold" !important;
    //  }
    //  button{
    //    margin: 0 3px;
    //    padding: 6px 2px;
    //    font-size: 20px;
    //    min-width: 50px;
    //    text-transform: capitalize;
    //    box-shadow: none;
    //    &.type_percentage, &.type_dollar{
    //      background: #084d4f1a;
    //      color: #084d4f;
    //      &.active{
    //        background-color: #084d4f;
    //        color: #fff;
    //      }
    //      &.Mui-disabled{
    //        background: #084d4f1a;
    //        color: #084d4f;
    //      }
    //    }
    //  }
    //}
    //.d_input{
    //  width: 100%;
    //  position: relative;
    //  margin-bottom: 15px;
    //  >div{
    //    width: 100%;
    //  }
    //  .Mui-disabled{
    //    .side_percentage,.side_dollar{
    //      color: rgba(0, 0, 0, 0.2);
    //    }
    //  }
    //  .side_percentage{
    //    position: absolute;
    //    right: 12px;
    //    font-size: 17px;
    //    top: 13px;
    //    color: rgba(0, 0, 0, 0.54);
    //  }
    //  .side_dollar{
    //    position: absolute;
    //    left: 8px;
    //    font-size: 17px;
    //    top: 15px;
    //    color: rgba(0, 0, 0, 0.54);
    //  }
    //  input{
    //    &:focus{
    //      ~.side_percentage{
    //        color: rgba(0, 0, 0, 1);
    //      }
    //    }
    //  }
    //  input{
    //    &.field-value{
    //      ~.side_percentage{
    //        color: rgba(0, 0, 0, 1);
    //      }
    //      ~.side_dollar{
    //        color: rgba(0, 0, 0, 1);
    //      }
    //    }
    //  }
    //  .Mui-focused , .MuiFormLabel-filled{
    //    > .side_percentage{
    //      color: rgba(0, 0, 0, 1);
    //    }
    //    > .side_dollar{
    //      color: rgba(0, 0, 0, 1);
    //    }
    //  }
    //}
    button {
      box-shadow: none;

      &.apply {
        background: #e70a89;
        color: #fff;
        padding: 8px 20px;
        margin: 8px 9px 8px 0;
        min-width: 86px;
        border: 2px solid #e70a89;

        &:hover {
          box-shadow: inset 0 -40px 10px -2px rgba(0, 0, 0, 0.1);
        }

        &.Mui-disabled {
          background: #084d4f1a;
          color: #084d4f;
          border-color: transparent;
        }
      }

      &.duplicate {
        margin-left: 0;
        padding: 8px 19px;
        margin: 8px 5px 8px 0px;
      }

      &.save {
        background: transparent;
        color: #000;
        border: 2px solid #000;
        padding: 7px 19px;
        margin: 15px 5px 8px 5px;
        box-shadow: none;
      }

      .MuiButton-label {
        text-transform: capitalize;
        font-weight: bold;
      }

      &.saveplus {
        padding: 8px 19px;
        margin: 8px 5px 8px 7px;
        border: solid 2px #000000;
      }

      &.no_delete {
        padding: 8px 19px;
        border: solid 2px #000000;
      }
    }

    h6 {
      font-size: 12px;
      color: #084d4f;
      font-weight: bold;
      margin: 30px 0 12px;
    }

    p {
      font-size: 14px;
      max-width: 250px;
    }
  }

  &#setting {
    min-width: 400px;
  }

  .bill_switch {
    padding: 20px 20px 0;

    .MuiSwitch-thumb {
      padding: 0;
      height: 20px;

      &:after,
      &:before {
        display: none;
      }
    }

    .MuiSwitch-track {
      background-color: rgba(8, 77, 79, 0.2);
      opacity: 1
    }

    .MuiSwitch-colorSecondary.Mui-checked {
      color: #084d4f;

    }

    .MuiSwitch-colorSecondary.Mui-checked+.MuiSwitch-track {
      background-color: rgba(8, 77, 79, 0.2);
      opacity: 1
    }

    .MuiTypography-body1 {
      color: #000;
    }
  }
}

.MuiDrawer-paperAnchorRight {
  max-width: 400px;
}

.MuiDrawer-modal {
  .MuiBackdrop-root {
    background-color: rgba(0, 0, 0, 0.6);
  }
}